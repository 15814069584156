// LICENSE_CODE YC
import React, {useCallback, useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Route, useLocation, Redirect} from 'react-router-dom';
import Heap from 'react-heap';
import {ToastProvider} from 'react-toast-notifications';
import Crypto from 'crypto-js';
import {Elements, CardElement, useStripe,
  useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {MuiThemeProvider} from '@material-ui/core/styles';
import Css_baseline from '@material-ui/core/CssBaseline';
import auth from './auth.js';
import {textCapitalize} from './helpers/TextHelper';
import {is_plan_active} from './helpers/user';
import Bottom_bar from './components/BottomBar';
import Popup from './popup2.js';
import material_theme from './theme';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import config_ext from './config_ext.js';
import './assets/site.scss';
import {gift_popup_set, inactive_user_popup_set} from './actions/mainMenu';
import xurl from '../../../util/xurl.js';
import credit_card from './assets/images/credit-card.svg';
import visa_card from './assets/images/visa-card.png';
import envelope_img from './assets/images/envelope.png';
import yc_web_popup from './assets/images/yc-web-popup.jpg';
import {Col, ConfigProvider, Modal, Row, theme, Typography, Button, Form,
  Input, Image, Tag, Divider, Carousel, message, Space} from 'antd';
import {diff} from 'deep-object-diff';
import Home from './home.js';
import Class from './class.js';
import Lesson from './lesson.js';
import Profile from './profile.js';
import Dbg from './dbg.js';
import Privacy_policy from './privacy.js';
import Tos from './terms.js';
import Account from './account.js';
import Account_activate from './account_activate.js';
import Refund from './refund.js';
import Refund_renewal from './refund_renewal.js';
import Renewal from './renewal.js';
import Signin from './signin.js';
import Signin2 from './signin2.js';
import Signup from './signup2.js';
import Logout from './pages/ForceLogout';
import Typeform from './typeform.js';

let {Title, Paragraph, Text} = Typography;
let default_msg = 'I’m really enjoying YesChef and thought you would to!';
const card_confirm = (stripe, secret, card, customer_name,
  metadata = {})=>eserf(function*
_card_confirm(){
  this.continue(stripe.confirmCardSetup(secret, {
    payment_method: {card, billing_details: {name: customer_name}, metadata},
  }));
  return yield this.wait();
});
let stripe_promise = loadStripe(config_ext.stripe.pub_key);
let Gift_popup_content = ({title, text, button_text, on_click})=>{
  return (
    <Row justify="center" style={{padding: 20}}>
      <Col span={24}>
        <Row justify="center">
          <Col><img style={{marginTop: -20, marginBottom: 20}}
            src={envelope_img}/></Col>
          <Col span={24}>
            <Row>
              <Col flex={1}>
                <Title style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}
                  level={4}>
                  {title}
                </Title>
              </Col>
              <Col flex={1}>
                <Paragraph style={{textAlign: 'center', paddingRight: 20,
                  paddingLeft: 20, whiteSpace: 'pre-wrap', fontWeight: 300}}>
                  {text}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button style={{textTransform: 'uppercase', height: 'auto',
          whiteSpace: 'normal'}} size="large" type="primary"
        block onClick={on_click}>
          {button_text}
        </Button>
      </Col>
    </Row>
  );
};
let Inactive_popup_content = ({title, text, button_text, on_click})=>{
  return (
    <Row justify="center">
      <img style={{marginBottom: 20, width: '100%', overflow: 'hidden'}}
        src={yc_web_popup}/>
      <Col span={24} style={{padding: 20}}>
        <Row justify="center">
          <Col></Col>
          <Col span={24}>
            <Row>
              <Col flex={1}>
                <Title style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}
                  level={4}>
                  {title}
                </Title>
              </Col>
              <Col flex={1}>
                <Paragraph style={{textAlign: 'center', paddingRight: 20,
                  paddingLeft: 20, whiteSpace: 'pre-wrap', fontWeight: 300}}>
                  {text}
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{padding: 20}}>
        <Button style={{textTransform: 'uppercase', height: 'auto',
          whiteSpace: 'normal'}} size="large" type="primary"
        block onClick={on_click}>
          {button_text}
        </Button>
      </Col>
    </Row>
  );
};
let Confirm_account_content = ({submit_handle})=>{
  let [explanation_visible, explanation_visible_set] = useState(false);
  let [error, error_set] = useState(true);
  let [show_error, show_error_set] = useState(false);
  let click_handle = ()=>explanation_visible_set(true);
  let continue_click_handle = ()=>{
    if (error)
    {
      show_error_set(true);
      return;
    }
    show_error_set(false);
    submit_handle();
  };
  return (
    <Row gutter={[0, 12]} justify="center" style={{padding: 20}}>
      <Col>
        <Title style={{textAlign: 'center', marginBottom: 0,
          marginTop: 30}} level={4}>
        Confirm your account
        </Title>
      </Col>
      <Col>
        <Paragraph style={{textAlign: 'center', fontWeight: 300}}>
        Ensure a seamless experience by confirming your payment details.
        You can cancel auto-renewal at any time in your profile.
        </Paragraph>
      </Col>
      <Col span={24}>
        <form onSubmit={submit_handle}>
          <div style={{borderWidth: 1, borderColor: '#CCCCCC',
            borderStyle: 'solid', borderRadius: 4, padding: 10,
            marginTop: 4, marginBottom: 4}}>
            <CardElement onChange=
              {e=>error_set(e.empty || e.error || !e.complete)} />
          </div>
          {show_error && <Text type="danger">
                  Please enter a valid credit card number</Text>}
        </form>
      </Col>
      <Col span={24}>
        <Button size="large" type="primary" block
          onClick={continue_click_handle}>
        CONTINUE
        </Button>
      </Col>
      <Col>
        <Paragraph style={{fontSize: 10}}>
        By clicking &quot;continue&quot; you confirm your subscription
        credentials and payment details for your annual plan.
        </Paragraph>
        <Divider style={{margin: 15}} />
        <Button style={{fontWeight: 400, fontSize: 11,
          color: '#505050'}} type="text" block
        onClick={click_handle} size="small">
         Why do we ask for a credit card?
        </Button>
        {explanation_visible && <Paragraph
          style={{textAlign: 'center', fontSize: 11}}>
          We ask to confirm your credit card details to ensure no interruption
          in service, so you can continue your plan after the first year of
          your annual membership ends. You may cancel your account at any time
          in your user Profile before your membership expires.
        </Paragraph >}
      </Col>
    </Row>
  );
};
let Secure_account_content = ({submit_handle, card,
  skip_click_handle})=>{
  let [error, error_set] = useState(true);
  let [show_error, show_error_set] = useState(false);
  let continue_click_handle = ()=>{
    if (error)
    {
      show_error_set(true);
      return;
    }
    show_error_set(false);
    submit_handle();
  };
  return (
    <Row justify="center" style={{padding: 20}}>
      <Col span={24} style={{height: 320}}>
        <Row justify="center">
          <Col>
            <Title style={{textAlign: 'center', marginTop: 30}} level={4}>
        Secure your account
            </Title>
          </Col>
          <Col>
            <Paragraph style={{textAlign: 'center', fontWeight: 300}}>
        Ensure a seamless experience by adding a backup card. You can cancel
        auto-renewal at any time in your profile.
            </Paragraph>
          </Col>
          {card && <Col span={24} style={{marginTop: 15, marginBottom: 20}}>
            <Row align="middle" justify="space-between">
              <Col flex={1}>
                <Image height={35} src={card.brand === 'visa' ?
                  visa_card : credit_card} preview={false} />
              </Col>
              <Col flex={3}>
                <Space size={10} >
                  <span style={{color: '#8A8A8A', fontSize: 20}}>•••• </span>
                  <span style={{color: '#8A8A8A', fontSize: 20}}>•••• </span>
                  <span style={{color: '#8A8A8A', fontSize: 20}}>•••• </span>
                  <span>{card.last4}</span>
                </Space>
              </Col>
              <Col>
                <Tag style={{fontWeight: 600}} color="#D9D9D9">
                  Primary card
                </Tag>
              </Col>
            </Row>
          </Col>
          }
          <Col span={24}>
            <form onSubmit={submit_handle}>
              <Text style={{fontWeight: 300, color: '#444444'}}>
                Add backup card</Text>
              <div style={{borderWidth: 1, borderColor: '#CCCCCC',
                borderStyle: 'solid', borderRadius: 4, padding: 10,
                marginTop: 4, marginBottom: 4}}>
                <CardElement onChange=
                  {e=>error_set(e.empty || e.error || !e.complete)} />
              </div>
              {show_error && <Text type="danger">
                  Please enter a valid credit card number</Text>}
            </form>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button size="large" type="primary" block
          onClick={continue_click_handle}>
        CONTINUE
        </Button>
      </Col>
      <Col span={24}>
        <Button style={{marginTop: 10, textTransform: 'lowercase',
          fontWeight: 400}} size="medium" type="text"
        block onClick={skip_click_handle}>
        SKIP
        </Button>
      </Col>
    </Row>
  );
};
let Gift_start_state = ({next_state_set})=>{
  return (
    <Gift_popup_content
      title={'Give YesChef.\nGet YesChef.'}
      text="Give someone 14-day free access,
      and get an extra 14 days added to your own subscription for free!"
      button_text="CONTINUE"
      on_click={next_state_set} />
  );
};
let Gift_info_state = ({
  next_state_set,
  name_set,
  msg_set,
  msg,
  name
})=>{
  const submit_handle = event=>{
    event.preventDefault();
    next_state_set();
  };
  const name_change_handle = event=>name_set(event.target.value);
  const msg_change_handle = event=>msg_set(event.target.value);
  return (
    <Row justify="center" style={{padding: 20}} >
      <Col span={24}>
        <Row justify="center">
          <Col style={{marginTop: 20}}>
            <Title style={{textAlign: 'center'}} level={4}>
        Give someone free access for 14 days to all of YesChef
            </Title>
          </Col>
          <Col>
            <Paragraph style={{textAlign: 'center', fontWeight: 300,
              paddingRight: 20, paddingLeft: 20}}>
        For every friend that joins you will get an extra 14 days added
        to your subscription.
            </Paragraph>
            <Form onSubmit={submit_handle} autoComplete="on"
              layout="vertical">
              <Form.Item label="Name" style={{fontWeight: 300,
                marginBottom: 16}}>
                <Input autoComplete="on" type="text"
                  onChange={name_change_handle}
                  placeholder="Name of recipient" value={name}/>
              </Form.Item>
              <Form.Item label="Message" style={{fontWeight: 300}}>
                <Input.TextArea value={msg} onChange={msg_change_handle}
                  autoComplete="on" type="text"/>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Button size="large" type="primary" block onClick={next_state_set}>
      CONTINUE
        </Button>
      </Col>
    </Row>
  );
};
let Gift_secure_state =
({next_state_set, user_full, stripe_secret, payment_methods})=>{
  let stripe = useStripe();
  let elements = useElements();
  const submit_handle = ()=>eserf(function* _submit_handle(){
    if (!stripe || !elements || !user_full || !stripe_secret)
      return;
    let card = elements.getElement('card');
    yield card_confirm(stripe, stripe_secret, card, user_full.name);
    next_state_set();
  });
  let primary_pm = payment_methods[0];
  let card = primary_pm?.card;

  return <Secure_account_content submit_handle={submit_handle}
    card={card} skip_click_handle={next_state_set} />;
};
let Gift_confirm_state = ({next_state_set, user_full, stripe_secret})=>{
  let stripe = useStripe();
  let elements = useElements();
  const submit_handle = ()=>eserf(function* _submit_handle(){
    if (!stripe || !elements || !user_full || !stripe_secret)
      return;
    let card = elements.getElement('card');
    yield card_confirm(stripe, stripe_secret, card, user_full.name);
    next_state_set();
  });
  return <Confirm_account_content submit_handle={submit_handle} />;
};
let Gift_share_state = ({msg, coupon, finish_handle})=>{
  const coupon_share = ()=>{
    let is_mobile = /iphone|ipad|ipod|android/u.test(navigator.userAgent.toLowerCase());
    if (is_mobile)
    {
      let share_msg = `Hi ${name || 'there'},\n${msg || default_msg}`
      +'\nGet free access now for 14 days - '
      +`${config_ext.wp.url}/redeem/?yc_gift_code=${coupon}`;
      if (navigator.canShare && navigator.canShare({text: share_msg}))
        navigator.share({text: share_msg});
      else
      {
        navigator.clipboard.writeText(share_msg);
        message.success('Link Copied');
      }
    }
    else
    {
      let qs_o = {
        subject: 'I thought you’d like 14-day guest pass to YesChef',
        body: `${msg || default_msg}:\n`
        +`${config_ext.wp.url}/redeem/?yc_gift_code=${coupon}`,
      };
      let qs = Object.entries(qs_o)
        .map(([key, value])=>`${key}=${encodeURIComponent(value)}`)
        .join('&');
      window.open(`mailto:?${qs}`, '_blank');
    }
    finish_handle();
  };
  return (
    <Gift_popup_content
      title={'Share access now'}
      text={`Once ${name || 'they'} accepts,
      you’ll get an extra 14 days added to your own subscription.`}
      button_text={`Share now with ${name || 'them'}`}
      on_click={coupon_share} />
  );
};
let Gift_popup = ()=>{
  let {user_full, user_full_update} = auth.use_auth();
  let {gift_popup_open} = useSelector(state=>state.mainMenu);
  let dispatch = useDispatch();
  let [state, state_set] = useState('start');
  let [name, name_set] = useState();
  let [msg, msg_set] = useState(default_msg);
  let [coupon, coupon_set] = useState('');
  let [payment_methods, payment_methods_set] = useState([]);
  let [stripe_secret, stripe_secret_set] = useState('');
  let carousel_ref = useRef();
  useEffect(()=>{
    if (!gift_popup_open)
      return;
    state_set('start');
    name_set('');
    msg_set(default_msg);
  }, [gift_popup_open]);
  const close_handle = ()=>eserf(function* _close_handle(){
    if (!user_full)
      return;
    dispatch(gift_popup_set(false));
    let prev_events = user_full.events || {};
    let prev_gift_popup_x = prev_events.gift_popup_x || {};
    let res = yield back_app.events_set({...prev_events,
      gift_popup_x: {...prev_gift_popup_x, ts: new Date(), v: true}});
    if (res.err)
      return;
    user_full_update();
  });
  const finish_handle = ()=>eserf(function* _close_handle(){
    if (!user_full)
      return;
    let prev_events = user_full.events || {};
    let res = yield back_app.events_set({...prev_events,
      gift_popup_x: {ts: new Date(), v: true, stop: true}});
    if (res.err)
      return;
    user_full_update();
    dispatch(gift_popup_set(false));
  });
  const start_state_next_handle = ()=>state_set('friend_info');
  const coupon_get = useCallback(()=>eserf(function* _coupon_get(){
    if (!user_full)
      return;
    if (user_full.gift_code)
      return user_full.gift_code;
    let res = yield back_app.coupon_get();
    if (res.err)
      return {err: res.err};
    return res.coupon;
  }), [user_full]);
  const giftee_info_next_handle = useCallback(()=>eserf(function*
  _giftee_info_next_handle(){
    if (!user_full)
      return;
    let [pm_res, ss_res] = yield this.wait_ret([
      back_app.payment_methods_get(),
      back_app.stripe_secret_get(),
    ]);
    if (pm_res.err || ss_res.err)
      return;
    let _payment_methods = pm_res.payment_methods;
    payment_methods_set(_payment_methods);
    stripe_secret_set(ss_res.secret);
    if (!_payment_methods.length)
      return state_set('confirm');
    if (_payment_methods.length == 1)
      return state_set('secure');
    let _coupon = yield coupon_get();
    if (_coupon.err)
      return;
    coupon_set(_coupon);
    state_set('share');
  }), [coupon_get, user_full]);
  const card_state_next_handle = useCallback(()=>eserf(function*
  _card_state_next_handle(){
    let _coupon = yield coupon_get();
    if (_coupon.err)
      return;
    coupon_set(_coupon);
    state_set('share');
  }), [coupon_get]);
  useEffect(()=>{
    let slide_indexes = {
      start: 0,
      friend_info: 1,
      secure: 2,
      confirm: 3,
      share: 4,
    };
    carousel_ref?.current?.goTo(slide_indexes[state], false);
  }, [state]);

  return (
    <ConfigProvider theme={{algorithm: theme.defaultAlgorithm,
      token: {fontWeightStrong: 700}}}>
      <Modal centered footer={null} open={gift_popup_open}
        width={350} bodyStyle={{minHeight: '440px'}} onCancel={close_handle}>
        <Carousel ref={carousel_ref} dots={false} effect="fade">
          <Gift_start_state next_state_set={start_state_next_handle} />
          <Gift_info_state
            name_set={name_set}
            msg_set={msg_set}
            msg={msg}
            name={name}
            next_state_set={giftee_info_next_handle}
          />
          <Elements stripe={stripe_promise}>
            <Gift_secure_state
              user_full={user_full}
              stripe_secret={stripe_secret}
              payment_methods={payment_methods}
              next_state_set={card_state_next_handle}
            />
          </Elements>
          <Elements stripe={stripe_promise}>
            <Gift_confirm_state
              user_full={user_full}
              stripe_secret={stripe_secret}
              next_state_set={card_state_next_handle}
            />
          </Elements>
          <Gift_share_state msg={msg} coupon={coupon}
            finish_handle={finish_handle} />
        </Carousel>
      </Modal>
    </ConfigProvider>
  );
};
let Inactive_start_state = ({next_state_set})=>{
  return (
    <Inactive_popup_content
      title={'Congratulations!\n1 year with YesChef'}
      text=
        {'Get extra 30 days for free now.\nIt\'s our way of saying thanks!'}
      button_text="GET 30 DAYS FREE"
      on_click={next_state_set} />
  );
};
let Inactive_confirm_state = ({next_state_set, user_full, stripe_secret})=>{
  let stripe = useStripe();
  let elements = useElements();
  const submit_handle = ()=>eserf(function* _submit_handle(){
    if (!stripe || !elements || !user_full || !stripe_secret)
      return;
    let card = elements.getElement('card');
    yield card_confirm(stripe, stripe_secret, card, user_full.name,
      {is_extend: true});
    next_state_set();
  });
  return <Confirm_account_content submit_handle={submit_handle} />;
};
let Inactive_secure_state =
({next_state_set, user_full, stripe_secret, payment_methods})=>{
  let stripe = useStripe();
  let elements = useElements();
  const submit_handle = ()=>eserf(function* _submit_handle(){
    if (!stripe || !elements || !user_full || !stripe_secret)
      return;
    let card = elements.getElement('card');
    yield card_confirm(stripe, stripe_secret, card, user_full.name,
      {is_extend: true});
    next_state_set();
  });
  let primary_pm = payment_methods[0];
  let card = primary_pm?.card;

  return <Secure_account_content submit_handle={submit_handle}
    card={card} skip_click_handle={next_state_set} />;
};
let Inactive_final_state = ({dialog_close})=>{
  return (
    <Inactive_popup_content
      title={'Account Confirmed!'}
      text="Your membership has been extended by an extra 30 days"
      button_text="FINISH"
      on_click={dialog_close} />
  );
};
let Inactive_users_popup = ()=>{
  let {user_full, user_full_update} = auth.use_auth();
  let {inactive_user_popup_open} = useSelector(state=>state.mainMenu);
  let dispatch = useDispatch();
  let [state, state_set] = useState('start');
  let [is_inactive_popup_closable, is_inactive_popup_closable_set] =
    useState(true);
  let [stripe_secret, stripe_secret_set] = useState('');
  let [payment_methods, payment_methods_set] = useState([]);
  let carousel_ref = useRef();
  let should_close_inactive_dialog= ()=>{
    let ts = user_full?.events?.plan_expire_x?.ts;
    if (!ts)
      return true;
    return false;
  };
  useEffect(()=>{
    if (!inactive_user_popup_open)
      return;
    state_set('start');
    is_inactive_popup_closable_set(should_close_inactive_dialog());
  }, [inactive_user_popup_open]);
  const dialog_close = useCallback(()=>{
    dispatch(inactive_user_popup_set(false));
  }, [dispatch]);
  const close_handle = ()=>eserf(function* _close_handle(){
    if (!user_full)
      return;
    dialog_close();
    let prev_events = user_full.events || {};
    let updated_events = {...prev_events,
      plan_expire_x: {ts: new Date()}};
    let _diff = diff(prev_events, updated_events);
    if (Object.keys(_diff).length)
    {
      let res = yield back_app.events_set(updated_events);
      if (res.err)
        return;
      user_full_update();
    }
  });
  const start_state_next_handle = useCallback(()=>eserf(function*
  _start_state_next_handle(){
    let [pm_res, ss_res] = yield this.wait_ret([
      back_app.payment_methods_get(),
      back_app.stripe_secret_get(),
    ]);
    if (pm_res.err || ss_res.err)
      return;
    let _payment_methods = pm_res.payment_methods;
    payment_methods_set(_payment_methods);
    stripe_secret_set(ss_res.secret);
    return state_set('confirm');
    // if (!_payment_methods.length)
    //   return state_set('confirm');
    // if (_payment_methods.length == 1)
    //   return state_set('secure');
    // state_set('final');
  }), []);
  const confirm_state_next_handle = ()=>eserf(
    function* _confirm_state_next_handle(){
      if (!user_full)
        return;
      let prev_events = user_full.events || {};
      let updated_events = {...prev_events,
        plan_expire_x: {}};
      let _diff = diff(prev_events, updated_events);
      if (Object.keys(_diff).length)
      {
        let res = yield back_app.events_set(updated_events);
        if (res.err)
          return;
        user_full_update();
      }
      state_set('final');
    });
  useEffect(()=>{
    let slide_indexes = {
      start: 0,
      confirm: 1,
      secure: 2,
      final: 3
    };
    carousel_ref?.current?.goTo(slide_indexes[state], false);
  }, [state]);

  return (
    <ConfigProvider theme={{algorithm: theme.defaultAlgorithm,
      token: {fontWeightStrong: 700}}}>
      <Modal adaptiveHeight centered footer={null} onCancel={close_handle}
        open={inactive_user_popup_open} destroyOnClose={true} width={350}
        maskClosable={is_inactive_popup_closable && state != 'final'}
        closable={is_inactive_popup_closable && state != 'final'}
        bodyStyle={{minHeight: '440px', overflow: 'hidden'}}
        className="inactive-modal">
        <Carousel ref={carousel_ref} dots={false} effect="fade">
          <Inactive_start_state next_state_set={start_state_next_handle} />
          <Elements stripe={stripe_promise}>
            <Inactive_confirm_state
              user_full={user_full}
              stripe_secret={stripe_secret}
              next_state_set={confirm_state_next_handle}
            />
          </Elements>
          <Elements stripe={stripe_promise}>
            <Inactive_secure_state
              payment_methods={payment_methods}
              user_full={user_full}
              stripe_secret={stripe_secret}
              next_state_set={confirm_state_next_handle}
            />
          </Elements>
          <Inactive_final_state dialog_close={dialog_close} />
        </Carousel>
      </Modal>
    </ConfigProvider>
  );
};

const E = ()=>{
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let {user_full,
    user: current_user} = auth.use_auth();
  let [payment_flow_popup_open, payment_flow_popup_open_set] = useState(false);
  let [payment_flow_popup_enable_to_close,
    payment_flow_popup_enable_to_close_set] = useState(true);
  let [payment_flow_popup_content, payment_flow_popup_content_set] = useState();
  let [error_popup_open, error_popup_open_set] = useState(false);
  let [error_popup_content, error_popup_content_set] = useState();
  let current_date = new Date();

  useEffect(()=>{
    window.intercomSettings = {app_id: config_ext.intercom.app_id,
      hide_default_launcher: true, alignment: 'right'};
    // eslint-disable-next-line max-len
    // eslint-disable-next-line func-names, vars-on-top, prefer-rest-params, space-before-blocks
    (function (){
      const w = window; const ic = w.Intercom;
      if (typeof ic === 'function')
      {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      }
      else
      {
        const d = document; const i = function (){ i.c(arguments); }; i.q = []; i.c = function (args){ i.q.push(args); }; w.Intercom = i; const l = function (){ const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${config_ext.intercom.app_id}`; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete')
          l();
        else if (w.attachEvent)
          w.attachEvent('onload', l);
        else
          w.addEventListener('load', l, false); } }());
  }, []);
  useEffect(()=>{
    if (!current_user)
      return;
    // XXX vladimir: Remove when done
    if (!qs_o.dbg_pay_popup)
      return;
    if (!user_full)
      return;
    if (!is_plan_active(user_full))
    {
      payment_flow_popup_open_set(true);
      payment_flow_popup_content_set('Please add a payment method');
      payment_flow_popup_enable_to_close_set(false);
      return;
    }
    let es = eserf(function* _pay_issue_get(){
      let res = yield back_app.pay_issue_get();
      if (!res.payment_issue)
        return;
      payment_flow_popup_open_set(true);
      payment_flow_popup_content_set(res.payment_issue);
      payment_flow_popup_enable_to_close_set(true);
    });
    return ()=>es.return;
  }, [user_full, current_user]);
  useEffect(()=>{
    if (!user_full)
      return;
    let hash = Crypto.HmacSHA256(user_full.email,
      '7bh_k2BlM6-xg-xls6oC5Z-li05PgYjQnOxuZEjn');
    let hash_in_hex = Crypto.enc.Hex.stringify(hash);
    window.Intercom('update', {
      hide_default_launcher: true,
      user_id: user_full.user_id,
      name: textCapitalize(user_full.name),
      email: user_full.email,
      user_hash: hash_in_hex,
      long_hash: hash_in_hex,
    });
    window.setTimeout(identify_load, 300);
    if (typeof window.heap !== 'undefined') {
      window.heap.identify(user_full.email);
      window.heap.addUserProperties({email: user_full.email,
      user_id: user_full.email, user_hash: user_full.hash_id,
      created_at: Math.round(new Date().getTime() / 1000)});
    }
  }, [user_full, identify_load]);
  const identify_load = useCallback(()=>{
    if (typeof window.mixpanel == 'undefined'
    || typeof window.mixpanel.identify != 'function')
    {
      return;
    }
    window.mixpanel.identify(user_full.email);
    let mixpanel_obj = {email: user_full.email, userId: user_full.email,
      referrer: document.referrer, user_hash: user_full.hash_id};
    window.mixpanel.people.set(mixpanel_obj);
  }, [user_full]);
  const payment_flow_popup_close = ()=>eserf(function*
  _payment_flow_popup_close(){
    if (!user_full)
      return;
    let prev_events = user_full.events || {};
    yield back_app.events_set({...prev_events,
      pay_add_x: {ts: new Date(), v: true}});
    payment_flow_popup_open_set(false);
  });
  const payment_flow_popup_submit = async ()=>{
    let token = await back_app.user_redirect_wp();
    if (!token)
    {
      error_popup_open_set(true);
      error_popup_content_set('To manage your account contact support');
      return;
    }
    let _url = xurl.url(`${config_ext.wp.url}/login_auto`, {token,
      redirect: '/profile/pay_add', return_to: window.location.href,
      return_to_if_regex: '/thank-you'});
    window.location.href = _url;
    payment_flow_popup_open_set(false);
  };

  function isExcludedPath() {
    const excludedPaths = ['/', '/home', '/signin'];
    return excludedPaths.includes(window.location.pathname);
  }

  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <MuiThemeProvider theme={material_theme}>
        <Css_baseline />
        <div className="yc-app">
          <Popup
            is_open={payment_flow_popup_open}
            title={payment_flow_popup_content}
            on_close_cb={payment_flow_popup_close}
            on_submit_cb={payment_flow_popup_submit}
            button_text="Got it"
            closable={payment_flow_popup_enable_to_close}
            mask_closable={payment_flow_popup_enable_to_close}
          />
          <Gift_popup />
          <Inactive_users_popup />
          <Popup
            is_open={error_popup_open}
            title={error_popup_content}
            button_text="Got it"
            on_close_cb={()=>error_popup_open_set(false)}
            on_submit_cb={()=>error_popup_open_set(false)}
          />
          {user_full  && !isExcludedPath() && <Heap
            appId="2688668859"
            userId={user_full.email}
            userData={{
              email: user_full.email,
              user_id: user_full.email,
              user_hash: user_full.hash_id,
              created_at: Math.round(current_date.getTime() / 1000),
            }}
          />}
          <div className="yc-app-main">
            <Route exact path={['/', '/signin']}
              component={qs_o.dbg_signin ? Signin2 : Signin} />
            <Route exact path={'/signup'}
              render={()=>qs_o.dbg_fbase_signup ?
                <Signup /> : <Redirect
                  to={{pathname: '/signin', search: location.search}}/>}
            />
            <Route exact path={'/dbg'} component={Dbg} />
            <Route exact path="/home" component={Home}/>
            <Route exact path="/class/:classId/lesson/:lessonNum" component={Lesson} />
            <Route exact path="/class/:id" component={Class} mode="class" />
            <Route exact path="/terms-of-service" component={Tos} />
            <Route exact path="/privacy-policy"
              component={Privacy_policy} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/myProfile" component={Profile} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/account/refund" component={Refund} />
            <Route exact path="/account/refund_renewal"
              component={Refund_renewal} />
            <Route exact path="/account/renewal" component={Renewal} />
            <Route exact path="/billing-details" component={Account} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/activate"
              component={Account_activate} />
              <Route exact path="/typeform" component={Typeform} />
          </div>
          <Bottom_bar />
        </div>
      </MuiThemeProvider>
    </ToastProvider>
  );
};

export default E;
