// LICENSE_CODE YC
import Action_types from './types';

export const gift_popup_set = open=>dispatch=>{
  dispatch({type: Action_types.GIFT_POPUP_SET, open});
};

export const inactive_user_popup_set = open=>dispatch=>{
  dispatch({type: Action_types.INACTIVE_USER_POPUP_SET, open});
};
