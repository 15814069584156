// LICENSE_CODE YC
let E = {};
E.ready = ()=>{};

E.get_last = E.get_last_lesson_num = class_id=>{
  let ret = localStorage.getItem(`${class_id}.last_lesson.num`);
  return ret;
};

E.get = E.get_last_recorded_time = lesson_id=>{
  let time_sec = localStorage.getItem(`${lesson_id}.time`);
  return +time_sec;
};

E.set = E.record_time = (time_sec, class_id, lesson_id, lesson_num)=>{
  localStorage.setItem(`${lesson_id}.time`, time_sec);
  localStorage.setItem(`${class_id}.last_lesson.time`, time_sec);
  localStorage.setItem(`${class_id}.last_lesson.num`, lesson_num);
};

export default E;
