// LICENSE_CODE YC
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Dialog_content from '@material-ui/core/DialogContent';
import Dialog_actions from '@material-ui/core/DialogActions';
import Close_icon from '@material-ui/icons/Close';
import {textCapitalize} from './helpers/TextHelper';

let _styles = theme=>({
  popupRoot: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '20px',
    color: '#000',
    fontSize: '1.6rem',
    lineHeight: '19px',
    textAlign: 'center',
    fontWeight: 400,
  },
  closeButton: {
    width: 'auto',
    padding: 0,
    minWidth: 0,
    minHeight: 0,
    height: 'auto',
    color: '#000',
    position: 'absolute',
    zIndex: 20,
    right: '3.9%',
  },
  popupTitle: {
    textTransform: 'none',
    fontWeight: 600,
    color: '#000',
    marginTop: '2.19em',
    marginBottom: '1.7em',
    fontSize: '1.6rem',
  },
  popupContent: {
    lineHeight: '22px',
    fontSize: '1.6rem',
    fontWeight: 300,
    padding: '8px 3px',
  },
  primaryButton: {
    marginTop: '12px',
    textTransform: 'none',
    color: '#ffffff',
    backgroundColor: '#ff007f',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
    height: '56px',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#ff007f',
    },
  },
  popupImages: {
    width: '100%',
    maxWidth: '100%',
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: 'rgba(255, 255, 255, 0.9)',
    margin: '2.4rem auto 0',
    position: 'relative',
  },
  singleImage: {
    width: '100%',
    maxWidth: '100%',
  },
  imagesLabels: {
    display: 'flex',
    position: 'absolute',
    zIndex: 20,
    bottom: '1rem',
    left: 0,
    right: 0,
    margin: '0 auto',
    justifyContent: 'center',
  },
  singleLabel: {
    width: '50%',
  },
  secondaryNote: {
    fontSize: '1.6rem',
    fontWeight: 600,
    margin: '2.4rem auto 0rem',
  },
});

const styles_use = makeStyles(_styles);
const Popup = ({displayName, isOpen, onClose: onCloseCb, onSubmit: onSubmitCb,
  title, message, images, buttonText, secondaryNote, desktopTitle,
  desktopMessage, hideBg, form, disableClose, popupId})=>{
  let on_close = (event, reason)=>{
    if (reason=='backdropClick' && disableClose)
      return;
    if (typeof onCloseCb === 'function')
      onCloseCb();
  };
  let on_submit = ()=>{
    if (typeof onSubmitCb === 'function')
      onSubmitCb();
  };
  let styles = styles_use();
  const popupHeading = window.innerWidth >= 768 && desktopTitle ? desktopTitle : textCapitalize(displayName) ? `${textCapitalize(displayName.split(' ')[0])}, ${title}` : title;
  const HandleMessage = useMemo(() => (
    window.innerWidth >= 768 && desktopMessage ? desktopMessage.map((item, index) => (
      <React.Fragment key={index}>{item}</React.Fragment>
    )) : message && message.map((item, index) => (
      <React.Fragment key={index}>{item}</React.Fragment>
    ))
  ), [desktopMessage, message]);
  return (
    <Dialog
      open={isOpen}
      onClose={on_close}
      id={popupId || 'popup-wrapper'}
      className={hideBg ? 'hide-bg' : ''}
    >
      <div className={styles.popupRoot}>
        {!disableClose && <Button onClick={on_close} className={styles.closeButton} color="default">
          <Close_icon />
        </Button>}
        <h3 id="alert-dialog-title" className={styles.popupTitle} dangerouslySetInnerHTML={{ __html: popupHeading }} />
        {message && <Dialog_content className={styles.popupContent}>
          {(desktopMessage || message) && HandleMessage}
          {images && images.map((image, index) => (
            <div key={index} className={styles.popupImages}>
              <img src={image.src} key={index} className={styles.singleImage} alt={'Popup'} />
              {image.labels && <div className={styles.imagesLabels}>
                {image.labels.map((label, idx) => (
                  <span className={styles.singleLabel} key={idx}>{label}</span>
                ))}
              </div>
              }
            </div>
          ))}
          {window.innerWidth < 768 && secondaryNote
              && <p className={styles.secondaryNote}>
            {secondaryNote}
          </p>}
          {form}
        </Dialog_content>}
        {buttonText && <Dialog_actions>
          <Button className={styles.primaryButton} onClick={on_submit}
            id={'popup-submit-button'}
            color="default" autoFocus>
            {buttonText}
          </Button>
        </Dialog_actions>
        }
      </div>
    </Dialog>
  );
};

export default Popup;
