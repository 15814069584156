// LICENSE_CODE YC
import React, {Fragment, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import Header from './header.js';
import Popup from './popup2.js';
import Loader from './loader';
import {textCapitalize} from './helpers/TextHelper';
import config_ext from './config_ext.js';
import xurl from '../../../util/xurl.js';
import eserf from '../../../util/eserf.js';
import back_app from './back_app.js';
import auth from './auth.js';
import {is_plan_active} from './helpers/user';
import {Col, Divider, Row, Space, Typography, Button, Avatar, Tooltip}
  from 'antd';
import Icon, {QuestionCircleOutlined} from '@ant-design/icons';
import {ReactComponent as Profile_icon_svg} from './profile_icon.svg';
import { notification } from "antd";
import { zhCN } from 'date-fns/locale';
import metric from './metric.js';

let {Paragraph, Text, Title} = Typography;
//XXX colin: remove this import date_parse from './helpers/convertDate';
let date_parse = date=>{
  // XXX colin: metric.error
  if (!date)
    return void console.error('date_parse_err', date);
  if (typeof date == 'number' || /^[0-9]+$/u.test(date))
    return new Date(parseInt(`${date}`, 10) * 1000);
  if (date && date.$numberDecimal)
    return new Date(parseInt(`${date.$numberDecimal}`, 10) * 1000);
  if (typeof date == 'string')
    return parseISO(date);
};
let Chevron_right = ()=><svg viewBox="0 0 7 12" width="7" height="12"
  fill="currentColor">
  <path d="M0.292461 10.8825C0.682461 11.2725 1.31246 11.2725 1.70246
10.8825L6.29246 6.2925C6.68246 5.9025 6.68246 5.2725 6.29246 4.8825L1.70246
0.292503C1.31246 -0.097497 0.682461 -0.097497 0.292461 0.292503C-0.0975389
0.682504 -0.0975389 1.3125 0.292461 1.7025L4.17246 5.5925L0.292461
9.4725C-0.0975389 9.8625 -0.0875392 10.5025 0.292461 10.8825Z"/>
</svg>;

const E = ()=>{
  let {user_full, user: user_profile, user_full_update} = auth.use_auth();
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  const history = useHistory();
  const [user_plan, user_plan_set] = useState({});
  const [loading, set_loading] = useState(true);
  const [is_pay_dialog_open, is_pay_dialog_open_set] = React.useState(false);
  const [is_recur, is_recur_set] = React.useState(false);
  const [is_pending_cancellation, is_pending_cancellation_set] = React.useState(false);
  const [refund_info, refund_info_set] = React.useState(false);
  const [is_err_popup_open, is_err_popup_open_set] = useState(false);
  const [err_popup_content, err_popup_content_set] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [showSubRows, setShowSubRows] = useState(false);

  const manageMyAccount = () => {
    setShowSubRows(!showSubRows);
  };

  const var_get = _user_full=>{
    let exp_date;
    if (typeof _user_full.plan.end_date != 'undefined'
      && typeof _user_full.plan.end_date == 'number'
      || +_user_full.plan.end_date+'' == _user_full.plan.end_date
      && typeof _user_full.plan.end_date == 'string')
    {
      exp_date = format(date_parse(_user_full.plan.end_date),
        'MMM dd, y');
    }
    let expiration_original_end_date;
    if (typeof _user_full.plan.original_end_date != 'undefined'
      && typeof _user_full.plan.original_end_date == 'number')
    {
      expiration_original_end_date =
        format(date_parse(_user_full.plan.original_end_date), 'MMM dd, y');
    }
    let msg_expiration;
    if (typeof _user_full.plan.message_expiration != 'undefined'
      && typeof _user_full.plan.message_expiration == 'number')
    {
      msg_expiration = format(date_parse(_user_full.plan.message_expiration),
        'MMM dd, y');
    }
    let msg;
    if (typeof _user_full.plan.message != 'undefined'
      && typeof _user_full.plan.message == 'string')
    {
      if (_user_full.plan.message.length > 0)
        msg = _user_full.plan.message;
    }
    let msg_tooltip;
    if (typeof _user_full.plan.message_tooltip != 'undefined'
      && typeof _user_full.plan.message_tooltip === 'string')
    {
      if (_user_full.plan.message_tooltip.length > 0)
        msg_tooltip = _user_full.plan.message_tooltip;
    }
    return {exp_date, expiration_original_end_date,
      msg_expiration, msg, msg_tooltip};
  };
  useEffect(()=>{
    let es = eserf(function* use_effect_refund_info(){
      if (user_full)
      {
        let plan;
        if (user_full.plan)
        {
          const {exp_date, expiration_original_end_date, msg_expiration, msg,
            msg_tooltip} = var_get(user_full);
          plan = {
            name: user_full.plan.name,
            end_date: exp_date,
            original_end_date: expiration_original_end_date,
            message_expiration: msg_expiration,
            message: msg,
            message_tooltip: msg_tooltip
          };
        }
        else
        {
          let plan_name = user_full.custom_attributes.Plan ?
            user_full.custom_attributes.Plan
            : user_full.custom_attributes.gift_plan || 'no plan';
          if (plan_name == 'no plan' || plan_name == 'freeBeta')
            plan_name = '';
          plan = {name: plan_name};
        }
        user_plan_set(plan);
      }
      let _refund_info = yield back_app.refund_info_get();

      is_recur_set(!user_full.recurring_disabled);
      if(user_full?.plan?.status == 'pending-cancel')       
        is_pending_cancellation_set(true);

      refund_info_set(_refund_info.available);
      set_loading(false);

    });
    return ()=>es.return();
  }, [user_full, location]);
  const manage_my_account = ()=>eserf(function* _manage_my_account(){
    set_loading(true);
    const token = yield back_app.user_redirect_wp();
    if (token.err)
    {
      set_loading(false);
      is_err_popup_open_set(true);
      err_popup_content_set('To manage your account contact support');
      return;
    }
    window.location.href = xurl.url(`${config_ext.wp.url}/login_auto`,
      {token, redirect: '/profile/subscriptions/'});
  });

  let cancel_membership_cb = ()=>{
    if (0 && refund_info.available
      && (1||refund_info.type=='refund_first_time'))
    {
      return void history.push(xurl.url(refund_info.type=='refund_renewal'
        ? '/account/refund_renewal' : '/account/refund', qs_o));
    }
    // XXX colin: not handling incorrect end dates
    history.push(xurl.url('/account/renewal', {end: user_full?.plan?.end_date}));
  };

  let reactivate_membership_cb = () => eserf(function* _reactivate_membership(){
    set_loading(true);
    const res = yield back_app.recur_set(true);
    if (res.err)
      return void metric.error('recur_err', str.j2s(res.err));

    set_loading(false);
    is_recur_set(true);
    is_pending_cancellation_set(false);
    api.open({
        message: <strong>Your subscription was re-activated</strong>,
        description: "Your subscription will automatically renew on your renewal date.",
        placement: "topRight"
    });
  });

  let on_pay_submit = ()=>eserf(function* _on_pay_submit(){
    let enable = qs_o.dbg_pay_add;
    let is_active = is_plan_active(user_full);
    //XXX eduardo: Remove debug flag once design is provided.
    if (!enable || !is_active)
    {
      window.location.href = xurl.url(`${config_ext.wp.url}/checkout/`,
        {product_sku: 'yc_annual-a1x'});
      return;
    }
    let token = yield back_app.user_redirect_wp();
    if (!token)
    {
      is_pay_dialog_open_set(false);
      is_err_popup_open_set(true);
      err_popup_content_set('To manage your account contact support');
      return;
    }
    window.location.href =
      xurl.url(`${config_ext.wp.url}/login_auto`, {
        token, redirect: '/profile/pay_add',
        return_to_if_regex: '/thank-you', return_to: window.location.href});
  });
  const show_original_date = ()=>{
    return(
      <Fragment>
        <Paragraph style={{ color: '#929292', fontSize: 14 }}>
          {!is_recur ? 'End Date: ' : 'Renewal Date: '}
          {user_plan?.original_end_date && <span style={{textDecoration: 'line-through'}}>{user_plan.original_end_date}</span>}
          {user_plan.end_date}
        </Paragraph>
      </Fragment>
    )
  };

  let show_msg_exp = ()=>{
    if (typeof user_plan.message_expiration == 'undefined'
      || typeof user_plan.message == 'undefined')
    {
      return '';
    }
    if (new Date(user_plan.message_expiration).getTime()
      <= new Date().getTime())
    {
      return '';
    }
    return <Space align="center">
      <Text style={{color: '#929292', fontSize: 14}}>
        * {user_plan.message}
      </Text>
      {typeof user_plan.message_tooltip !== 'undefined' ?
        <Tooltip title={user_plan.message_tooltip}>
          <QuestionCircleOutlined style={{color: '#929292',
            fontSize: 16}} />
        </Tooltip>
        : ''}
    </Space>;
  };

  if (loading)
    return <Loader />;
  return <div className="yc-main">
    <Header />
    {contextHolder}
    <Row justify="center">
      <Col flex="auto" sm={24} md={22} lg={14}>
        <Row gutter={[0, 20]} justify="center"
          style={{marginTop: 30, marginBottom: 30}}>
          <Popup
            is_open={is_pay_dialog_open}
            title={'You need to add a payment method'}
            button_text="Add"
            on_close_cb={()=>is_pay_dialog_open_set(false)}
            on_submit_cb={on_pay_submit}
          />
          <Popup
            is_open={is_err_popup_open}
            title={err_popup_content}
            closable={false}
            mask_closable={false}
            button_text="Got it"
            on_close_cb={()=>is_err_popup_open_set(false)}
            on_submit_cb={()=>is_err_popup_open_set(false)}
          />
          {user_profile &&
              <Col>
                <Space size="middle" align="center" direction="vertical">
                  <Avatar size={100} style={{backgroundColor: '#FF007F',
                    color: '#C20A65', fontSize: 30}} icon={
                    <Icon style={{fontSize: 40}}
                      component={Profile_icon_svg} />} />
                  <Space size="small" align="center" direction="vertical">
                    <Title level={4}>
                      {textCapitalize(user_profile.displayName)}
                    </Title>
                    <Text>
                      {user_profile.email}
                    </Text>
                  </Space>
                </Space>
              </Col>}
          <Col lg={24} xs={22}>
            <Title level={3}>Billing details</Title>
            {!user_plan || !Object.keys(user_plan).length ? <>
              <div style={{backgroundColor: '#1E1E1E', padding: 24,
                borderRadius: 6}}>
                <Text style={{fontSize: 16}}>
                YOUR SUBSCRIPTION
                </Text>
                <Title style={{marginTop: '0.5em'}} level={5}>
                You don&lsquo;t have a YesChef subscription yet
                </Title>
              </div>
              <a href={config_ext.wp.url} rel="noopener noreferrer"
                target="_blank">
                <Button style={{marginTop: 20}}
                  size="large" block type="primary">
                CHECK OUT OUR PLANS
                </Button>
              </a>
            </>
              :
              <div style={{backgroundColor: '#1E1E1E', padding: 24,
                borderRadius: 6}}>
                <Text style={{fontSize: 16}}>
                YOUR MEMBERSHIP
                </Text>
                <Title style={{marginTop: '0.5em'}} level={5}>
                  {user_plan.name}
                </Title>
                {user_plan.name && !user_plan.name.toLowerCase().includes('lifetime') ?
                  show_original_date() : ''}
                {user_plan.name && !user_plan.name.toLowerCase().includes('lifetime') ?
                  show_msg_exp() : ''}

              </div>}
          </Col>
          <Col span={24}>
            <Divider style={{margin: 0}} />
            {showSubRows ? (
            <>
              <Button onClick={manage_my_account}
                style={{padding: 15, height: 'auto'}} block type="text">
                <Row justify="space-between" align="middle">
                  <Col><span>Manage subscription</span></Col>
                  <Col><Icon component={Chevron_right} /></Col>
                </Row>
              </Button>
              <Divider style={{margin: 0}} />
              { !is_pending_cancellation && <>
                <Button onClick={cancel_membership_cb}
                  style={{padding: 15, height: 'auto'}} block type="text">
                  <Row justify="space-between" align="middle">
                    <Col><span>Cancel membership</span></Col>
                    <Col><Icon component={Chevron_right} /></Col>
                  </Row>
                </Button>
                <Divider style={{margin: 0}} />
              </>}
              { is_pending_cancellation && <>
                <Button onClick={reactivate_membership_cb}
                  style={{padding: 15, height: 'auto'}} block type="text">
                  <Row justify="space-between" align="middle">
                    <Col><span>Re-activate my subscription</span></Col>
                    <Col><Icon component={Chevron_right} /></Col>
                  </Row>
                </Button>
                <Divider style={{margin: 0}} />
              </>}
              </>
            ) : (
              <Button
              onClick={manageMyAccount}
              style={{ padding: 15, height: 'auto' }}
              block
              type="text"
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <span>Manage my account</span>
                  </Col>
                  <Col>
                    <Icon component={Chevron_right} />
                  </Col>
                </Row>
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  </div>;
};

export default auth.with_auth(E);
