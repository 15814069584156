// LICENSE_CODE YC
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import config_ext from './config_ext.js';
import auth from './auth.js';
import metric from './metric.js';

let E = {};
export default E;

E.remind_cancellation = (sub_id)=>eserf(function* back_app_remind_cancel(){
  let token = yield auth.token_get();
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/user/remind_cancellation/set.json', {qs: {ver: config_ext.ver,sub_id},
    no_print: true, headers: ereq.auth_hdr(token)});
    console.log("RES: ",res);
  if (res.err)
  {
    metric.error('remind_cancellation_set_err', res.err);
    return false;
  }
  if (res.data.err)
  {
    metric.error('remind_cancellation_set_err', res.data.err);
    return false;
  }
  return res.data;
});

E.tf_response_get = (form_id, email)=>eserf(function* back_app_tf_response_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/user/tf_response/get.json', {qs: {ver: config_ext.ver,form_id,email},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
  {
    metric.error('tf_response_get_err', res.err);
    return false;
  }
  if (res.data.err)
  {
    metric.error('tf_response_get_err', res.data.err);
    return false;
  }
  return res.data;
});

E.user_meta = is_refresh=>eserf(function* back_app_user_meta(){
  let token = yield auth.token_get(is_refresh);
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/user/get_meta.json', {qs: {ver: config_ext.ver},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  return res.data;
});

E.user_set = user=>eserf(function* back_app_user_set(){
  let token = yield auth.token_get();
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/user/set2.json', {qs: {ver: config_ext.ver}, data: user,
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  return res.data;
});

E.intercom_set = data=>eserf(function* back_app_user_set(){
  let token = yield auth.token_get();
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/user/intercom_set.json', {qs: {ver: config_ext.ver}, data,
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  return res.data;
});

E.refund_info_get = ()=>eserf(function* back_app_refund_info_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/refund_available/get.json', {qs: {ver: config_ext.ver},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
  {
    metric.error('refund_get_err', res.err);
    return false;
  }
  if (res.data.err)
  {
    metric.error('refund_get_err', res.data.err);
    return false;
  }
  return res.data;
});

E.refund_get = ()=>eserf(function* back_app_refund_get(){
  let token = yield auth.token_get();
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/refund.json', {qs: {ver: config_ext.ver},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  if (res.data.err)
    return {err: res.data.err};
  return {};
});

E.recur_set = enable=>eserf(function* back_app_recur(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/user/pay/recur.json', {qs: {enable, ver: config_ext.ver},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  if (res.data.err)
    return {err: res.data.err};
  return res.data.recurring_disabled;
});

E.recur_get = enable=>eserf(function* back_app_recur(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/user/pay/recur.json', {qs: {enable, ver: config_ext.ver},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return res;
  if (res.data.err)
    return {err: res.data.err};
  return res.data.recurring_disabled;
});

E.events_set = events=>eserf(function* back_app_events_set(){
  let token = yield auth.token_get();
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/user/events/set.json`,
    {headers: ereq.auth_hdr(token), qs: {events, ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.payment_methods_get = ()=>eserf(function* back_app_payment_methods_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/user/payment_methods/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.stripe_secret_get = ()=>eserf(function* back_app_stripe_secret_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/user/stripe_secret/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.coupon_get = ()=>eserf(function* back_app_coupon_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/user/coupon/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

// XXX colin: add config_ext.ver
E.history_set = data=>eserf(function* back_app_history_set(){
  let token = yield auth.token_get();
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/history/set.json`,
    {headers: ereq.auth_hdr(token), qs: data});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.history_get = ()=>eserf(function* back_app_history_set(){
  let token = yield auth.token_get();
  let res = yield ereq.get(
    `${config_ext.back.app.url}/private/user/history/get.json`,
    {headers: ereq.auth_hdr(token), qs: {ver: config_ext.ver}});
  if (res.err)
    return {err: res.err};
  if (res.data?.err == 'not_found')
  {
    return {classId: 'c00', lessonNum: 1,
      lessonId: 'c00_l00', chefName: 'THE WORLD\'S BEST CHEFS',
      lessonName: 'TEACH HOME COOKING',
    };
  }
  return res.data;
});

E.is_first_login_set = is_first_login=>eserf(function*
back_app_is_first_login(){
  let token = yield auth.token_get();
  let res = yield ereq.post(
    `${config_ext.back.app.url}/private/user/is_first_login/set.json`,
    {headers: ereq.auth_hdr(token), qs: {is_first_login}});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.user_redirect_wp = ()=>eserf(function* wp_login_token(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/user/redirect_wp.json', {qs: {ver: config_ext.ver},
    no_print: true, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data.login_token;
});

E.behind_the_scenes_images_list = class_id=>eserf(function*
back_app_behind_the_scenes(){
  let res = yield ereq.get(`${config_ext.general.mediaAssetsUrl}/${class_id}`
    +'/behindTheScenes/ImagesList.json');
  if (res.err)
    return void metric.error('behind_the_scenes_err', res.err);
  let bts_list = res.data.map(o=>{
    o.src = `${config_ext.general.mediaAssetsUrl}/${class_id}${o.src}`;
    return o;
  });
  return bts_list;
});

E.class_data_get = class_id=>eserf(function* back_app_class_data_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url+'/pub/class.json',
    {qs: {class_id, ver: config_ext.ver}, headers: ereq.auth_hdr(token)});
  if (res.err)
  {
    metric.error('class_data_get', res.err);
    return {err: res.err};
  }
  if (!res.data)
    return;
  return res.data;
});

E.class_get_list = ()=>eserf(function* back_app_class_data_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url+'/pub/classes.json',
    {qs: {ver: config_ext.ver}, headers: ereq.auth_hdr(token)});
  if (res.err)
  {
    metric.error('classes_get_list_err', res.err);
    return {err: res.err};
  }
  if (!res.data)
    return;
  return res.data;
});

E.lesson_get = (class_id, lesson_id, email)=>eserf(function*
lesson_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url+'/pub/lesson.json',
    {qs: {ver: config_ext.ver, class_id, lesson_id, email},
      no_print: true, headers: ereq.auth_hdr(token)});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('lesson_get_err', err);
    return {err};
  }
  return res.data;
});

E.subtitles_get = (class_id, lesson_id)=>eserf(function*
back_app_subtitles_get(){
  let res = yield ereq.get(config_ext.general.mediaAssetsUrl+
    `/${class_id}/${class_id}_subtitles.json`, {no_print: true});
  if (res.err)
    return {err: res.err};
  if (!res.data || !res.data[lesson_id] || !res.data[lesson_id].length)
    return {err: 'does not exist'};
  let result = res.data[lesson_id];
  result = result.map(subtitle=>({
    ...subtitle,
    src: config_ext.general.mediaAssetsUrl
      +`/${class_id}/${lesson_id}/${subtitle.src}`,
  }));
  return result;
});

E.feed_popup_set = feed_popup=>eserf(function* back_app_feed_popup_set(){
  let token = yield auth.token_get();
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/user/feed_popup/set.json',
  {qs: {feed_popup, ver: config_ext.ver}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.file_exists = url=>eserf(function* back_app_file_exists(){
  let res = yield ereq.get(url);
  return !res.err;
});

E.user_get_hash_id = hash_id=>eserf(function* user_get_hash_id(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/user/get_hash_id.json',
  {qs: {ver: config_ext.ver, hash_id}, no_print: true});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('user_get_hash_id_err', err);
    return {err};
  }
  return res.data;
});

E.user_get_email = email=>eserf(function* user_get_email(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/user/get_email.json',
  {qs: {ver: config_ext.ver, email}, no_print: true});
  let err = res.err ? res.err : res.data.err;
  if (err == 'not_found')
    return {err};
  if (err)
  {
    metric.error('user_get_email_err', err);
    return {err};
  }
  return res.data;
});

E.user_get_fbase = fbase_id=>eserf(function* user_get_fbase(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/user/get_fbase_id.json',
  {qs: {ver: config_ext.ver, fbase_id}, no_print: true});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('user_get_fbase_id_err', err);
    return {err};
  }
  return res.data;
});

E.yc_gen_pwd_set = (email, is_reset)=>eserf(function* back_app_gen_pwd_set(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/user/yc_gen_pwd/set.json',
  {qs: {ver: config_ext.ver, email, yc_gen_pwd: is_reset}, no_print: true});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('yc_gen_pwd_set', err);
    return {err};
  }
  return res.data;
});

E.user_get_token = email=>eserf(function* back_app_user_get_token(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/user/get_token.json',
  {qs: {ver: config_ext.ver, email}, no_print: true});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('user_get_token_err', err);
    return {err};
  }
  return res.data;
});

E.user_get_token_from_uid = uid=>eserf(function*
back_app_user_get_token_from_uid(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/user/get_token_from_uid.json',
  {qs: {ver: config_ext.ver, uid}, no_print: true});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('user_get_token_err', err);
    return {err};
  }
  return res.data;
});

E.feedback_set = (class_id, comment, lesson_id, lesson_title, timestamp,
  user_name, video_time)=>eserf(function* back_app_feedback_set(){
  let token = yield auth.token_get();
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/feedback/set.json',
  {qs: {ver: config_ext.ver}, data: {class_id, comment, lesson_id,
    lesson_title, timestamp, user_name, video_time}, no_print: true,
  headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.feedback_get = ()=>eserf(function* back_app_feedback_get(){
  let token = yield auth.token_get();
  let res = yield ereq.get(config_ext.back.app.url+'/private/feedback/get.json',
    {qs: {ver: config_ext.ver}, no_print: true, headers: ereq.auth_hdr(token)});
  let err = res.err ? res.err : res.data.err;
  if (err)
  {
    metric.error('feedback_get', err);
    return {err};
  }
  return res.data;
});

E.pay_issue_get = ()=>eserf(function* back_app_pay_issue_get(){
  let token = yield auth.token_get(true);
  if (token.err)
    return token;
  let res = yield ereq.get(config_ext.back.app.url
    +'/private/pay/get_issue.json',
  {qs: {ver: config_ext.ver}, no_print: true,
    headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.display_grp_get = ()=>eserf(function* back_app_display_grp_get(){
  let res = yield ereq.get(config_ext.back.app.url
    +'/pub/display_grp/get.json',
  {qs: {ver: config_ext.ver}, no_print: true});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.login_set = ()=>eserf(function* back_app_login_set(){
  let token = yield auth.token_get(true);
  if (token.err)
    return token;
  let res = yield ereq.post(config_ext.back.app.url
    +'/private/user/login.json',
  {qs: {ver: config_ext.ver}, headers: ereq.auth_hdr(token)});
  if (res.err)
    return {err: res.err};
  return res.data;
});

E.signup_no_plan = email=>eserf(function* back_app_signup_no_plan(){
  let res = yield ereq.post(config_ext.back.app.url
    +'/pub/user/signup_no_plan.json',
  {qs: {ver: config_ext.ver}, data: {email}});
  if (res.err)
    return {err: res.err};
  return res.data;
});
