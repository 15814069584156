// LICENSE_CODE MIT
import {EventEmitter} from 'events';
import xutil from './util.js';

const RECURSIVE = '.';
const E = new EventEmitter();
export default E;
E.state = {};

E._on = (path, fn, opt)=>{
  opt = Object.assign({recursive: false, init: true}, opt);
  EventEmitter.prototype.on.call(E, path, fn);
  if (opt.recursive)
    EventEmitter.prototype.on.call(E, RECURSIVE+path, fn);
  if (opt.init)
    fn(E.get(path));
  let listener = {path, fn, recursive: opt.recursive};
  return listener;
};

E.on = (_path, fn, opt)=>{
  let paths = _path;
  let listeners = [];
  if (!(_path instanceof Array))
    paths = [_path];
  for (let path of paths)
    listeners.push(E._on(path, fn, opt));
  return listeners;
};

E.once = (path, fn, opt)=>{
  let listener;
  listener = E.on(path, (...args)=>{
    E.off(listener);
    return fn(...args);
  }, Object.assign({init: false}, opt));
  return listener;
};

E._off = listener=>{
  EventEmitter.prototype.removeListener.call(E, listener.path, listener.fn);
  if (listener.recursive)
  {
    EventEmitter.prototype.removeListener.call(E, RECURSIVE+listener.path,
      listener.fn);
  }
};

E.off = listeners=>{
  if (!(listeners instanceof Array))
    return E._off(listeners);
  for (let l of listeners)
    E.off(l);
};

E.get = path=>xutil.get(E.state, path);

E.set = (path, curr, opt)=>{
  opt = Object.assign({force_emit: false}, opt);
  if (!opt.force_emit && xutil.get(E.state, path)===curr)
    return;
  // XXX colin: add typeof object compare
  xutil.set(E.state, path, curr);
  let depth = opt.recursive ? Number.POSITIVE_INFINITY : opt.depth||0;
  let _path;
  do
  {
    _path = path;
    E.emit_path(_path);
    if (depth--<=0)
      return;
    path = path.replace(/\.[^.]+$/u, '');
  } while (_path!=path);
};

E.set_inc = (path, curr, opt)=>{
  E.set(path, (+E.get(path)||0)+1);
};

E.delete = path=>E.set(path, undefined);

E.emit_path = path=>{
  E.emit(path, xutil.get(E.state, path));
  path = path.split('.');
  while (path.length>1)
  {
    path.pop();
    let p = path.join('.');
    E.emit(RECURSIVE+p, xutil.get(E.state, p));
  }
};

E.clear = function(){
  this.removeAllListeners();
  this.state = {};
};

E.debug = function(){
  window.je = E;
};
