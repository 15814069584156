// LICENSE_CODE YC
import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Row, Col, Space, Button, Modal, Image} from 'antd';
import { InlineLoader } from './loader';
import Header from './header.js';
import back_app from './back_app.js';
import config_ext from './config_ext.js';
import eserf from '../../../util/eserf.js';
import xurl from '../../../util/xurl.js';
import str from '../../../util/str.js';
import date from '../../../util/date.js';
import metric from './metric.js';
import auth from './auth.js';
import { useDispatch } from 'react-redux';

// XXX colin: add global vars for color primary
let color_primary = '#ff007f';
let E = ()=>{
  let history = useHistory();
  let location = useLocation();
  const [loading, set_loading] = useState(false);

  let class_id = 'c01'; // XXX colin: set to edward
  let bg = `${config_ext.general.mediaAssetsUrl}/${class_id}/cover.jpg`;
  let qs_o = xurl.qs_parse(location.search, true);
  let {user: user_profile,user_full, user_full_update} = auth.use_auth();
  let s_day = 'time';
  let end_date = user_full?.plan?.end_date;
  if (end_date && !isNaN(end_date)) {
    try {
      let d = new Date(0);
      d.setUTCSeconds(end_date);
      let diffDays = Math.round(date.diffdates(d, new Date(), 'days'));
      if (isNaN(diffDays)) {
        s_day = 'time';
        metric.error('renewal_invalid_date_end', str.j2s(user_full?.plan));
      } else {
        s_day = diffDays + (diffDays === 1 ? ' day' : ' days');
      }
    } catch (error) {
      metric.error('renewal_date_calculation_error', error);
    }
  }
  else{
    metric.error('renewal_invalid_date_end', str.j2s(user_full));
  }
  let [modal, modal_set]= useState({open: false});

  // Convert the timestamp to milliseconds
  const timestamp = end_date * 1000;
  // Create a new Date object using the timestamp
  const date = new Date(timestamp);
  // Define an options object to specify the date format
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  // Format the date using toLocaleDateString
  const formattedDate = date.toLocaleDateString('en-US', options);

  let view_form = ()=>{
    history.push(xurl.url('/typeform', qs_o));
  };

  let rewewal_cancel = ()=>eserf(function* _refund_get(){
    let title, line1, line2;
    let selection = qs_o.selection;

    switch (selection.toLowerCase()) {
      case "50off":
        title = "Offer applied";
        line1 = "Your next renewal will have a 50% discount";
        line2 = "Happy cooking!";
        break;
      case "monthly":
        title = "Membership was changed to Monthly";
        line1 = "Your first month will be free of charge, then $29.99 USD/month.";
        line2 = "Happy cooking!";
        break;
      case "lifetime":
        title = "Upgraded to Lifetime successfully";
        line1 = "Your membership won't be renewed again, and you'll have access to YesChef for Life!";
        line2 = "Happy cooking!";
        break;
      case "1monthfree":
        title = "1 month free applied";
        line1 = "Happy cooking!";
        break;
      case "50off-3months":
        title = "Offer applied";
        line1 = "Your next 3 months will have a 50% discount.";
        line2 = "Happy cooking!";
      break;
      case "cancel":
        title = "Auto-renewal was turned off";
        line1 = `Your membership will not renew automatically and will be cancelled on ${formattedDate}`;
        line2 = "You can always turn it back on in your profile for a seamless experience.";
        break;
      default:
        title = "Thanks for your submission";
        line1 = "The changes will be reflected in your account within 48 hours.";
        line2 = "";
        break;
    }

    modal_set({
      open: true,
      title: title,
      line1: line1,
      line2: line2,
      btn: 'Done'
    });

    if(qs_o.selection === 'cancel') {
      const res = yield back_app.recur_set(false);
      if (res.err) {
        return void metric.error('recur_err', str.j2s(res.err));
      }
      yield user_full_update();
    }
  });

  useEffect(() => {
    const handleCancel = async () => {
      if(qs_o?.selection) {
        await rewewal_cancel();
      }
    };
  
    handleCancel();
  }, [qs_o.selection]);

  let remind_cancel = ()=>eserf(function* _remind_set(){

    const res = yield back_app.remind_cancellation(user_full.plan.sub_id);
      if (res.err) {
        return void metric.error('remind_cancel', str.j2s(res.err));
      }

    setTimeout(() => {
      modal_set({
        open: true,
        title: 'Thanks for staying with us!',
        line1: 'We will send you an email 30 days before your membership renews',
        btn: 'Go back'
      });
    }, 600);
  });

  let on_click = ()=>{
    if (qs_o.selection) {
      delete qs_o.selection;
    }
    history.push(xurl.url('/account', qs_o));
  };

  return (
    <div className="yc-main"><Header go_back="/account" />
      {user_profile && <>
        <Modal className="renewalModal" open={modal?.open}
          closable={false} footer={null}>
          <Space style={{width: '100%'}} size="large" direction="vertical">
            <Row className="renewalTitle" justify="center">
              <h2>{modal?.title}</h2>
            </Row>
            <Row justify="center">
              <Col className="renewalContent">
                <Row justify="center">
                  <Col style={{textAlign: 'center'}}>
                    {modal?.line1} 
                  </Col>
                </Row>
                <Row justify="center">
                  <Col style={{textAlign: 'center'}}>
                    {modal?.line2} 
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Button className="renewal__btn_done" type="primary" block
                onClick={on_click}>
                {modal?.btn}
              </Button>
            </Row>
          </Space>
        </Modal>
        <Space size="large" direction="vertical" style={{marginBottom: '50px'}}>
          <Row justify="center">
            <Image src={bg} preview={false}/>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20} style={{textAlign: 'center', 'display': 'flex', 'justifyContent': 'center'}}>
              <h1 style={{'max-width': '700px'}}>
                You still have
                <span style={{color: color_primary}}> {s_day} </span>
                to learn from the
                world&#39;s best chefs and make the most delicious food at
                home!
              </h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={16} style={{textAlign: 'center'}}>
              Instead of cancelling now, we can remind you 30 days before
              your membership renews.
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20}>
              <Button className="renewal__btn_keep_my_membership"
                size="large" type="primary" block
                onClick={on_click}>
                KEEP MY MEMBERSHIP
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20}>
              <Button className="renewal__btn_remind_me_to_cancel"
                size="large" block onClick={remind_cancel}>
                REMIND ME TO CANCEL
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20}>
              <Button className="renewal__btn_cancel_auto_renewal"
                size="large" block onClick={view_form}>
                TURN OFF AUTO-RENEWAL { loading && <InlineLoader style={{'position': 'relative', 'left': '15px'}}/> }
              </Button>
            </Col>
          </Row>
        </Space>
      </>}
    </div>);
};

export default auth.with_auth(E);
