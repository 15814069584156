// LICENSE_CODE YC
import React, {useEffect, useRef, useState} from 'react';
import {Button, Typography, Grid} from 'antd';
import config_ext from './config_ext.js';

let {Title, Paragraph} = Typography;

const E = props=>{
  let {name, classTitle: class_title, id, clickable, bordered, is_coming_soon,
    centered, button_text, click_handle, custom_bg_img, square = false,
    coming_soon_lbl}
  = props;
  const [height, height_set] = useState(0);
  let is_bottom_position = id === 'c02';
  const div_ref = useRef(null);
  const screens = Grid.useBreakpoint();
  useEffect(()=>{
    if (div_ref.current)
    {
      height_set(div_ref.current.clientWidth /
      (screens.xs && square ? 1 : 16/9));
    }
  }, [screens]);
  return (
    <div
      ref={div_ref}
      onClick={()=>clickable && click_handle()}
      style={{
        backgroundImage: custom_bg_img ? custom_bg_img :
          `url(${config_ext.general.mediaAssetsUrl}/${id}/cover.jpg)`,
        backgroundRepeat: 'no-repeat', width: '100%',
        height,
        backgroundSize: 'cover',
        borderRadius: bordered ? 6 : 0,
        backgroundPosition: is_bottom_position ? 'bottom' : 'center',
        cursor: clickable ? 'pointer' : 'default',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end',
      }}>
      <div style={{
        borderRadius: bordered ? 6 : 0, paddingLeft: 20, paddingBottom: 20,
        paddingRight: button_text ? 20 : 0,
        width: '100%', textAlign: centered ? 'center' : 'left',
        backgroundImage:
          'linear-gradient(to top, #1f1e1e, rgba(31, 30, 30, 0))'}}>

        <Title style={{fontWeight: 600, marginBottom: 0}} level={3}>
          {name}
        </Title>
        <Paragraph style={{textTransform: 'uppercase', marginBottom: 0,
          fontSize: 16, fontWeight: 300}} level={4}>
          {class_title}
        </Paragraph>
        {is_coming_soon && 
        <Button style={{fontWeight: 600, marginBottom: 0, borderRadius: '100px',
          backgroundColor: '#FEFEFE', color: '#000'}} level={4}>
          {coming_soon_lbl||'COMING SOON'}
        </Button>}
        {button_text && click_handle && <Button style={{marginTop: '1.2rem'}}
          size="large" type="primary" block onClick={click_handle}>
          {button_text}
        </Button>}
      </div>
    </div>

  );
};

export default E;
