// LICENSE_CODE YC
import React, {useState, useCallback, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import Card_actions from '@material-ui/core/CardActions';
import Card_content from '@material-ui/core/CardContent';
import Swipeable_views from 'react-swipeable-views';

let styles = {
  root: {
    padding: '0 30px',
  },
  slideContainer: {
    padding: '0 10px',
  },
  progressbarWrapper: {
    width: '100%',
    height: '6px',
    backgroundColor: '#4d4d4d',
  },
  progressLinear: {
    backgroundColor: '#cf6679',
    height: '6px',
    transition: 'all 500ms linear',
  },
};
let card_content = {
  padding: '2.3rem',
  flex: 1,
  overflow: 'auto',
  textAlign: 'center',
  fontWeight: '600',
};
let card_title = {
  fontSize: 20,
  fontWeight: 600,
};
let card_action = {
  height: 'auto',
  backgroundColor: '#262626',
  padding: '2.3rem',
  flexDirection: 'column',
  alignItems: 'flex-start',
};
let pro_tip_style = {
  margin: 0,
  fontSize: 14,
  color: 'rgba(255,255,255)',
};
let pro_tip_content_style = {
  margin: 0,
  fontSize: 20,
  color: '#fff',
  fontWeight: 300,
};
const E = ({items, player})=>{
  let [current_step, current_step_set] = useState(0);
  let [current_time, current_time_set] = useState(0);
  const step_progress_render = useCallback((item, idx)=>{
    let {incode, outcode} = item;
    let current_progress = 0;
    if (current_step == idx)
    {
      let distance_left = outcode - current_time;
      let distance_whole = outcode - incode;
      current_progress = Math.floor(
        (distance_whole - distance_left) / distance_whole * 100);
    }
    let progress_o = {width: `${current_progress}%`};
    let merged = {...styles.progressLinear, ...progress_o};
    if (current_step == idx)
    {
      return (
        <div style={styles.progressbarWrapper}>
          <div style={merged}></div>
        </div>
      );
    }
    return false;
  }, [current_time, current_step]);
  useEffect(()=>{
    for (let index = 0; index < items.length; index++)
    {
      if (current_time < items[index].incode)
        break;
      if (current_time >= items[index].incode && (items.length <= index + 1
        || items[index + 1].incode > current_time))
      {
        current_step_set(index);
        break;
      }
    }
  }, [current_time, items]);
  const current_time_update = useCallback(()=>{
    if (!player)
      return;
    current_time_set(player.currentTime());
  }, [player]);
  useEffect(()=>{
    if (!player)
      return;
    player.on('timeupdate', current_time_update);
    return ()=>player.off('timeupdate', current_time_update);
  }, [player, current_time_update]);
  return (
    <Swipeable_views
      enableMouseEvents
      className="steps-card"
      style={styles.root}
      slideStyle={styles.slideContainer}
      index={current_step}
      onChangeIndex={idx=>current_step_set(idx)}
      disabled
    >
      {items.map((item, idx)=><Card className="steps-card-item" key={item.id}>
        <div className="steps-card-item-inner">
          {step_progress_render(item, idx)}
          <Card_content style={card_content}>
            {item.title && <h2 style={card_title}>{item.title}</h2>}
            <p style={{...pro_tip_content_style, fontWeight: 600}}>
              {item.content}
            </p>
          </Card_content>
          {item.proTip && <Card_actions style={card_action}>
            <h4 style={pro_tip_style}>{item.tip_heading || 'PRO-TIP'}</h4>
            <span style={pro_tip_content_style}>{item.proTip}</span>
          </Card_actions>}
        </div>
      </Card>)}
    </Swipeable_views>
  );
};

export default E;
