// LICENSE_CODE YC
import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  makeStyles,
} from '@material-ui/core';
import './index1.scss';
import use_embla_carousel from 'embla-carousel-react';
import {Row} from 'antd';

const styles_use = makeStyles(()=>({
  containerSlider: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 90,
    boxSizing: 'border-box',
    minWidth: 0,
    flex: '0 0 107px',
  },
  sliderImg: {
    borderRadius: 2,
    objectFit: 'cover',
    height: 60,
  },
  textSlider: {
    color: 'white',
    fontWeight: 600,
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
    lineHeight: 1.1,
    marginBottom: -18,
  },
  textFrom: {
    position: 'absolute',
    fontSize: 11,
    top: 60,
    left: 5,
    color: '#929292',
    letterSpacing: 0.3,
  },
  cardContent: {
    position: 'absolute',
    background: 
    'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%)',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: 107,
    maxWidth: 107,
    padding: '0 5px',
    top: 0,
    bottom: 30,
  },
}));

const E = ({data, player})=>{
  const drag_start_handle = e=>e.preventDefault();
  const click_handle = seconds=>{
    if (!player)
      return;
    player.currentTime(seconds);
  };
  const classes = styles_use();
  const [embla_ref] = use_embla_carousel({dragFree: true, align: 'start',
    containScroll: 'trimSnaps'});
  const items = data.map(
    ({content, start, seconds, image_url, fill}, idx)=>{
      let _start = start.replace(/^0(?:0:?)?/u, '').slice(0, -3);
      return (
        <Card
          key={idx}
          data-value={idx}
          className={classes.containerSlider}
          onClick={()=>click_handle(seconds)}
          style={{marginRight: '1rem'}}
        >
          <CardActionArea>
            <CardMedia
              image={image_url || fill}
              title={content}
              onDragStart={drag_start_handle}
              className={classes.sliderImg}
            />
          </CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography
              component="p"
              className={classes.textSlider}
              variant="inherit"
            >
              {content}
            </Typography>
            <Typography
              component="p"
              className={classes.textFrom}
              variant="inherit"
            >
              From {_start}
            </Typography>
          </CardContent>
        </Card>
      );
    });
  return (
    <div style={{padding: '0 1rem', overflow: 'hidden'}} ref={embla_ref}>
      <Row wrap={false} >
        {items}
      </Row>
    </div>
  );
};

export default E;
