// LICENSE_CODE YC
import React from 'react';
import React_dom from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ConfigProvider, theme} from 'antd';
import metric from './metric.js';
import 'antd/dist/reset.css';
import App from './app.js';
import store from './store';
import auth from './auth.js';
import './index.css';
import * as svc_worker from './serviceWorker.js';

metric.init();
// XXX colin: add global vars for color primary

React_dom.render(
  <Provider store={store}>
    <auth.auth_provider>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: '#ff007f',
            colorLink: '#ffffff',
            colorLinkActive: '#ffffff',
            colorLinkHover: 'rgba(255, 255, 255, 0.45)',
            fontFamily: 'Open Sans',
            borderRadius: 6,
          },
          components: {
            Tabs: {colorPrimaryHover: '#ffffff', colorText: '#929292'},
            Typography: {colorLink: '#929292'},
            Tag: {colorTextLightSolid: '#000', marginXS: 0},
            Input: {controlHeight: 45, colorBorder: '#9C9C9C',
              colorTextPlaceholder: '#999999'},
            Modal: {paddingContentHorizontalLG: 0, paddingMD: 0},
            Button: {controlHeightLG: 56}
          }
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </auth.auth_provider>
  </Provider>,
  document.getElementById('root'),
);

svc_worker.unregister();
