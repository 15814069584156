// LICENSE_CODE YC
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Image, Row, Col, Space, Button, Modal} from 'antd';
import Header from './header.js';
import config_ext from './config_ext.js';
import back_app from './back_app.js';
import eserf from '../../../util/eserf.js';
import xurl from '../../../util/xurl.js';
import str from '../../../util/str.js';
import metric from './metric.js';
import auth from './auth.js';

let E = ()=>{
  let history = useHistory();
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let class_id = 'c01'; // XXX colin: set to edward
  let {user: user_profile} = auth.use_auth();
  let bg = `${config_ext.general.mediaAssetsUrl}/${class_id}/cover.jpg`;
  let [open, open_set]= useState(qs_o.get_refund);
  let chat_open = ()=>window.Intercom('show'); // eslint-disable-line
  let refund_get = ()=>eserf(function* _refund_get(){
    const res = yield back_app.refund_get();
    if (res.err)
      return void metric.error('refund_err', str.j2s(res.err));
    open_set(true);
  });
  let on_click = ()=>{
    window.location.href = config_ext.wp.url;
  };
  return (
    <div className="yc-main"><Header go_back="/account" />
      {user_profile && <>
        <Modal open={open}
          closable={false} footer={null}>
          <Space style={{width: '100%'}} size="large" direction="vertical">
            <Row justify="center">
              <strong>Refund issued successfully</strong>
            </Row>
            <Row justify="center">
              <Col>
                <Row justify="center">
                  <Col>
                    Your memebership is cancelled.
                  </Col>
                </Row>
                <Row justify="center">
                  <Col>
                    You are always welcome back, to continue
                  </Col>
                </Row>
                <Row justify="center">
                  <Col>
                    learning and improving your cooking skills!
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Button className="refund__btn_done" type="primary" block
                onClick={on_click}>DONE</Button>
            </Row>
          </Space>
        </Modal>
        <Space direction="vertical">
          <Row justify="center">
            <Image src={bg} preview={false}/>
          </Row>
          <Row justify="center">
            <Col><h2>Before you go,</h2></Col></Row>
          <Row justify="center">
            <Col><h2>maybe we can help?</h2></Col>
          </Row>
          <Row justify="center">
            <Col span={20} style={{textAlign: 'center'}}>
              Did something not work out for you? Do you need technical help
              or have any concerns? Whatever makes you feel like leaving - we
              can talk about it.
            </Col>
          </Row>
          <Row justify="center">
            <Col span={20} style={{textAlign: 'center'}}>
              If you refund now, you will lose access to
              all YesChef content.
            </Col>
          </Row>
          <Row justify="center">
            <Col span={20}>
              <Button className="refund__btn_keep_my_membership"
                size="large" type="primary" block
                onClick={()=>history.push(xurl.url('/account', qs_o))}>
                KEEP MY MEMBERSHIP
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={20}>
              <Button className="refund__btn_get_help" size="large" block
                onClick={chat_open}>
                GET HELP
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={20}>
              <Button className="refund__btn_get_refund"
                size="large" block onClick={refund_get}>
                GET REFUND
              </Button>
            </Col>
          </Row>
        </Space>
      </>}
    </div>);
};

export default auth.with_auth(E);
