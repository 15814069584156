// LICENSE_CODE YC
import React, {useCallback, useEffect, useRef, useState} from
  'react';
import {useHistory, useLocation} from 'react-router-dom';
import Text_field from '@material-ui/core/TextField';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Button, Divider} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Left_icon from '@material-ui/icons/KeyboardArrowLeft';
import Icon_button from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Loader from './loader';
import auth from './auth.js';
import {getCookie} from './helpers/cookie';
import google_logo from './assets/images/googleLogo.svg';
import fb_logo from './assets/images/fbLogo.svg';
import sign_in_styles, {cssTextFieldStyles} from './signin.styles.js';
import {is_plan_active} from './helpers/user';
import back_app from './back_app.js';
import metric from './metric.js';
import xurl from '../../../util/xurl.js';
import str from '../../../util/str.js';
import eserf from '../../../util/eserf.js';
import config_ext from './config_ext.js';

let Css_txt_field = withStyles(cssTextFieldStyles)(Text_field);
let styles_use = makeStyles(sign_in_styles);
let xurl_url = qs=>{
  let url = xurl.url(`${window.location.protocol}//`
    +window.location.hostname, qs);
  return url;
};

let E = ()=>{
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let [email, email_set] = useState('');
  let [password, password_set] = useState('');
  let [email_error, email_error_set] = useState({isError: false, msg: ''});
  let [password_error, password_error_set] =
    useState({isError: false, msg: ''});
  let [magic_text, magic_text_set] =
    useState('We sent you an email that has a magic link that\'ll '
      +'sign you in.');
  let [go_to_step, go_to_step_set] = useState('');
  let [loading, loading_set] = useState(true);
  let [button_disabled, button_disabled_set] = useState(false);
  let [user_mongo, user_mongo_set] = useState(false);
  let {loading_auth, login_with, user_full,
    user: user_profile, signout, signin_with_custom_token,
    signin_with_email_link, send_password_reset_email,
    signin_with_email_and_password, send_signin_link_to_email, is_init}
    = auth.use_auth();
  let social_login_ref = useRef(false);
  let classes = styles_use();
  let history = useHistory();
  useEffect(()=>{
    sign_in_init();
  }, [sign_in_init]);
  useEffect(()=>{
    if (user_profile && user_full)
    {
      delete qs_o.hash;
      delete qs_o.mode;
      delete qs_o.apiKey;
      delete qs_o.oobCode;
      delete qs_o.lang;
      history.push(xurl.url('/home', qs_o));
      window.location.reload();
    }
    else
      data_set();
  }, [history, user_profile, user_full]);
  const email_validate = _email=>{
    return _email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/ui);
  };
  const sign_in_init = useCallback(()=>{
    let step = qs_o.step;
    let encoded_email = qs_o.ui;
    try {
      let decoded_email = atob(encoded_email);
      if (step == 'password' && email_validate(decoded_email))
      {
        metric.set_id(decoded_email.toLowerCase());
        email_set(decoded_email.toLowerCase());
        go_to_step_set('password');
      }
    } catch(err) {}
  }, []);
  const magic_link_send = async email_storage=>{
    try {
      await go_to_magic({email_storage});
      email_error_set({isError: true,
        msg: 'That link was expired but we just sent you a new one'});
    } catch(error) {
      email_error_set({isError: true,
        msg: 'Hmm, something is not right. Enter your email to continue.10'});
    }
  };
  const data_set = ()=>eserf(function* _data_set(){
    let ycfuid = getCookie('ycfuid');
    loading_set(true);
    let email_for_sign_in;
    try {
      if (ycfuid)
        yield signin_with_custom_token(ycfuid);
      else
        email_for_sign_in = yield signin_with_email_link(qs_o.hash);
    } catch(error) {
      metric.error('auth_err',
        `User ${ycfuid || email_for_sign_in} tried to log in.`
      + `Code: ${error.code} Reason: *${error.message}*`);
      if (error.code === 'auth/expired-action-code'
      || error.code === 'auth/invalid-action-code')
      {
        magic_link_send(email_for_sign_in);
      }
      else if (error.code === 'auth/argument-error')
        email_error_set({isError: true, msg: 'Please provide correct email'});
      else
      {
        email_error_set({isError: true, msg:
          'Hmm, something is not right. Enter your email to continue.'});
      }
    }
    loading_set(false);
  });
  const change_handle = event=>{
    if (event.target.id === 'password')
      password_set(event.target.value);
    else
      email_set(event.target.value.toLowerCase());
  };

  let signup_url = qs_o.dbg_fbase_signup ? '/signup?dbg_fbase_signup=true'
    : config_ext.wp.url + '/signup';

  const user_data_check = ()=>eserf(function* _user_data_check(){
    loading_set(true);
    let user_meta = yield back_app.user_meta();
    if (!user_meta || user_meta.err)
    {
      email_error_set({isError: true,
        msg: 'Sorry, we can\'t find an account with this email address. '
        + 'Please try again or '
        + '<a href="' +signup_url+ '">create a new account.</a> '});
      signout();
      loading_set(false);
      return;
    }
    let is_permitted = user_permission_check(user_meta);
    if (!is_permitted)
      signout();
    loading_set(false);
  });

  const permission_error_set = ()=>{
    email_error_set({isError: true,
      msg: 'Sorry, there seems to be a problem, please try again.'});
    signout();
  };
  const user_permission_check = user_data=>{
    if (!user_data || !user_data.email)
    {
      permission_error_set(user_data);
      return false;
    }
    if (is_plan_active(user_data) || config_ext.front.is_login_any)
      return true;
    permission_error_set(user_data);
    return false;
  };

  let password_reset = ()=>eserf(function* _password_reset(){
    let url = xurl_url({step: 'password', ui: btoa(email)});
    yield send_password_reset_email(email, url);
    yield back_app.yc_gen_pwd_set(email, true);
    email_error_set({isError: false, msg: ''});
    password_error_set({isError: false, msg: ''});
    go_to_step_set('reset-pass');
  });

  const login_submit = async event=>{
    event.preventDefault();
    if (!email_validate(email))
    {
      email_error_set({isError: true,
        msg: 'Please enter a valid email address'});
      return false;
    }
    metric.set_id(email);
    try {
      let user_data = await back_app.user_get_email(email.toLowerCase());
      if (user_data.err)
      {
        email_error_set({isError: true,
          msg: 'Sorry, we can\'t find an account with this email address. '
          + 'Please try again or '
          + '<a href="'+ signup_url
          + '">create a new account.</a> '});
        metric.error('login_no_email_err', email);
        return false;
      }
      let is_permitted = user_permission_check(user_data);
      if (!is_permitted)
      {
        email_error_set({isError: true,
          msg: 'Sorry, there seems to be a problem, please try again'});
        metric.error('login_no_perm_err', user_data.email||email);
        return false;
      }
      user_mongo_set(user_data);
      password_error_set({isError: false, msg: ''});
      if (user_data?.yc_gen_pwd && (user_data.login_with == 'password'
        || user_data.login_with == 'yeschef_signup'))
      {
        magic_text_set(
          'You will receive an email shortly to create your password.');
        await go_to_magic({});
      }
      else
      {
        go_to_step_set('password');
      }
    } catch(error) {
      metric.error('signing_err', email, str.j2s(error));
    }
  };

  let login_with_pass = event=>eserf(function* _login_with_pass(){
    event.preventDefault();
    button_disabled_set(true);
    if (!password)
    {
      password_error_set({isError: true, msg: 'Please enter your password'});
      button_disabled_set(false);
      return false;
    }
    try {
      let resp = yield signin_with_email_and_password(email, password);
      if (resp)
        user_data_check();
    } catch(err) {
      let zapier_data = {
        email,
        message: `User ${email} tried to log in via email/password. `
          +`Reason: *Error code: ${err.code}. Error message: ${err.message}*`,
      };
      metric.error('zapier_err', str.j2s(zapier_data));
      button_disabled_set(false);
      if (err.code == 'auth/wrong-password')
      {
        password_error_set({isError: true,
          msg: 'The password you entered is incorrect'});
        password_set('');
      }
      else if (err.code == 'auth/too-many-requests')
      {
        back_to_step('Too many failed attempts have been made. '
          +'Please reset your password or try again later.');
      }
      else if (err.code == 'auth/user-not-found')
        back_to_step('This email isn’t associated with a YesChef account');
    }
  });

  let go_to_magic = ({email_storage})=>eserf(function* _go_to_magic(){
    let is_email_valid;
    let new_email;
    if (email)
    {
      is_email_valid = email_validate(email);
      new_email = email.toLowerCase();
    }
    else if (email_storage)
    {
      is_email_valid = email_validate(email_storage.toLowerCase());
      new_email = email_storage.toLowerCase();
    }
    if (!is_email_valid)
    {
      email_error_set({isError: true,
        msg: 'Enter the email address you\'ve signed up with to '
        +'securely sign in'});
      return false;
    }
    metric.set_id(new_email);
    let err_n = window.localStorage.getItem('errorsCount')
      ? window.localStorage.getItem('errorsCount') : 0;
    try {
      let _user_data = yield back_app.user_get_email(new_email);
      let error = false;
      let reason = '';
      if (!_user_data || _user_data.err)
      {
        email_error_set({isError: true,
          msg: 'Sorry, we can\'t find an account with this email address. '
          + 'Please try again or '
          + '<a href="'+
          signup_url
          + '">create a new account.</a> '});
        return;
      }
      let is_permitted = user_permission_check(_user_data);
      if (!is_permitted)
      {
        error = true;
        reason = 'User is not permitted';
        permission_error_set(_user_data);
      }
      if (error)
      {
        err_n++;
        window.localStorage.errorsCount = err_n;
        if (err_n > 1)
        {
          window.localStorage.errorsCount = err_n;
          let user_email_try = _user_data?.email||new_email;
          metric.error('login_attempt_err', `User ${user_email_try} `
            +`tried to log in. Attempt ${err_n} Reason: *${reason}*`);
        }
        return false;
      }
      let action_code_setting = {
        url: xurl_url({hash: _user_data.hash_id}),
        handleCodeInApp: true,
      };
      try {
        yield send_signin_link_to_email(_user_data.email,
          action_code_setting);
        window.localStorage.emailForSignIn = _user_data.email;
        window.localStorage.userName = _user_data.email;
        go_to_step_set('magic');
      } catch(exp) {
        metric.error('fbase_send_sigin_link_to_email',
          `User ${_user_data.email} tried to log in. Reason: *Firebase can't `
          +`send magic link. Error code: ${exp.code}. `
          +`Error message: ${exp.message}*`);
        email_error_set({isError: true,
          msg: 'Hmm, something is not right. Enter your email to continue.'});
      }
    } catch(error) {
      console.log('error', error);
      let zapier_data = {email: new_email, login: 'false',
        message: `User ${email} tried to log in. Reason: *Tech problem* `
        +`${error.message}`};
      metric.error(zapier_data);
      email_error_set({isError: true,
        msg: 'Something went wrong. Enter your email to try again.'});
    }
  });

  const back_to_step = (mail_error = '', pwd_error = '')=>{
    password_error_set({isError: false, msg: ''});
    email_error_set({isError: false, msg: ''});
    if (mail_error)
      email_error_set({isError: true, msg: mail_error});
    else if (pwd_error)
      password_error_set({isError: true, msg: pwd_error});
    if (go_to_step != 'reset-pass')
      go_to_step_set('');
    else
      go_to_step_set('password');
  };

  if (loading || loading_auth || !is_init)
    return <Loader />;
  return (
    <Box className={classes.container}>
      <div className={classes.heading}>
        <h2>
            YES<span>CHEF</span>
        </h2>
        <h3>
            Learn from the World&apos;s Best Chefs
        </h3>
      </div>
      <div className={classes.wrapper}>
        {go_to_step && <div className={classes.nextStep}>
          {go_to_step != 'magic' && <div
            className={classes.backIconBox}
            onClick={()=>back_to_step()}
          >
            <Icon_button aria-label="Close">
              <Left_icon className={classes.leftIcon} />
            </Icon_button>
          </div>}
          <div
            className={clsx(
              classes.step,
              {[classes.pwd_step]: go_to_step === 'password'},
              {[classes.magicStep]: go_to_step === 'magic'},
            )}
          >
            {go_to_step === 'password' && <form
              style={{width: '100%'}}
              onSubmit={login_with_pass}
            >
              <span className={classes.emailData}>
                {email}
              </span>
              {(email.indexOf('@gmail.com') > -1
                  || email.indexOf('@googlemail.com') > -1) && <>
                <Box className={classes.loginWith}>
                  <Button
                    className={classes.googleButton}
                    size="large"
                    onClick={()=>login_with('google', user_data_check,
                      social_login_ref)}
                  >
                    <Box pr="0.7rem" display="flex">
                      <img src={google_logo} alt="Google Logo" />
                    </Box>
                    <Box fontSize="1.4rem" fontWeight="600">
                      {user_mongo && user_mongo.first_name
                        ? `Continue as ${user_mongo.first_name}`
                        : 'Continue with GOOGLE'}
                    </Box>
                  </Button>
                </Box>
                <Box
                  flexDirection="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  fontWeight="600"
                  mb="1.6rem"
                  mt="1.6rem"
                >
                  <Divider width="40%" className={classes.boldDivider} />
                  <span className={classes.dividerText}>OR</span>
                  <Divider width="40%" className={classes.boldDivider} />
                </Box>
              </>}
              <div className={classes.textFieldWrapper}>
                <Css_txt_field
                  id="password"
                  label="Password"
                  type="password"
                  className={classes.textField}
                  onChange={change_handle}
                  autoComplete="password"
                  helperText={password_error.msg}
                  error={password_error.isError}
                  InputLabelProps={{className: classes.textFieldLabel}}
                  variant="outlined"
                  fullWidth={true}
                  value={password}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.signInBtn}
                disabled={button_disabled}
              >
                <Box fontWeight="600" fontSize="1.4rem">
                    Sign In
                </Box>
              </Button>
              <Box className={classes.bottomText}>
                <span style={{cursor: 'pointer'}} onClick={password_reset}>
                    Forgot password or don&apos;t have one yet?
                </span>
              </Box>
            </form>}
            {go_to_step == 'magic' && <div style={{width: '100%'}}>
              <h2>
                  Check your email
              </h2>
              <p>{magic_text}</p>
            </div>}
            {go_to_step == 'reset-pass' && <div style={{width: '100%'}}>
              <p
                className={classes.grayText}
                style={{fontSize: 16, textAlign: 'center'}}
              >
                  You will receive an email shortly to reset your password
              </p>
            </div>}
          </div>
        </div>}
        {!go_to_step && <div>
          {!qs_o.is_not_social_login && <><Box className={classes.loginWith}>
            <Button
              className={classes.fbButton}
              size="large"
              onClick={()=>login_with('facebook', user_data_check,
                social_login_ref)}
            >
              <Box pr="0.7rem" display="flex">
                <img src={fb_logo} alt="Facebook Logo" />
              </Box>
              <Box fontSize="1.4rem" fontWeight="600">
                  Sign in with FACEBOOK
              </Box>
            </Button>
            <Button
              className={classes.googleButton}
              size="large"
              onClick={()=>login_with('google', user_data_check,
                social_login_ref)}
            >
              <Box pr="0.7rem" display="flex">
                <img src={google_logo} alt="Google Logo" />
              </Box>
              <Box fontSize="1.4rem" fontWeight="600">
                  Sign in with GOOGLE
              </Box>
            </Button>
          </Box>
          <Box flexDirection="row" display="flex"
            justifyContent="space-between" alignItems="center"
            fontWeight="600" mb="1.6rem" mt="1.6rem">
            <Divider width="40%" className={classes.boldDivider} />
            <span className={classes.dividerText}>OR</span>
            <Divider width="40%" className={classes.boldDivider} />
          </Box></>}
          <form
            className={classes.loginWith}
            autoComplete="on"
            onSubmit={login_submit}
          >
            <div className={classes.textFieldWrapper}>
              <Css_txt_field
                id="email"
                label="Email"
                type="email"
                className={classes.textField}
                onChange={change_handle}
                autoComplete="email"
                InputLabelProps={{className: classes.textFieldLabel}}
                variant="outlined"
                fullWidth
                value={email}
                helperText={email_error.isError && <>
                  <span dangerouslySetInnerHTML={{__html: email_error.msg}} />
                  <span>
                    <button type="button"
                      onClick={()=>window.Intercom('show')}>
                      Need help?
                    </button>
                  </span>
                </>}
                error={email_error.isError}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.signInBtn}
            >
              <Box fontWeight="600" fontSize="1.4rem">
                  sign in with email
              </Box>
            </Button>
          </form>
          <div className={classes.subWrapper}>
            {!qs_o.is_not_social_login &&
                <div className={classes.magicLink} onClick={go_to_magic}>
                  Sign in using Magic link
                </div>
            }
            <div className={classes.privacyText}>
                By signing up, you agree to our <span id="privacy"
                onClick={()=>history.push(xurl.url('/privacy-policy', qs_o))}>
                    Privacy Policy</span> and <span id="terms"
                onClick={()=>history.push(xurl.url('/terms-of-service', qs_o))}>
                  Terms of Service
              </span>.
            </div>
            {!qs_o.is_not_signup && <div className={classes.signUpLink}>
                Not a member yet? <a href={
                qs_o.dbg_fbase_signup ? '/signup?dbg_fbase_signup=true'
                  : config_ext.wp.url + '/signup'
              }
              rel="noreferrer">Sign up now</a>
            </div>}
          </div>
        </div>
        }
      </div>
    </Box>
  );
};

export default E;
