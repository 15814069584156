
//   const useStyles = makeStyles(theme => createStyles({
const generalStyles = theme => ({
  container: {
    '& .Mui-focused fieldset': {
      borderColor: '#ccc',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      margin: 'auto',
    },
  },
  subHeader: {
    fontSize: '1.4rem',
    color: '#333',
    margin: '-1rem 0 2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 400,
  },
  loginWith: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2.25rem',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  textFieldLabel: {
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  boldDivider: {
    height: '1px',
    backgroundColor: '#ccc',
    margin: '2rem 0 2.2rem',
  },
  fbButton: {
    backgroundColor: '#4367b2 !important',
    color: 'white',
    paddingRight: '1.2rem',
    paddingLeft: '1.2rem',
  },
  googleButton: {
    backgroundColor: 'white',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    border: '1px solid #ccc',
    marginTop: '2.4rem',
    color: '#464646',
  },
  signUpBtn: {
    marginTop: '2.4rem',
    width: '100%',
  },
  tos_ppContainer: {
    marginTop: '2.6rem',
    marginRight: '4rem',
    marginLeft: '4rem',
    fontFamily: 'Open Sans',
    fontSize: '1.4rem',
    fontWeight: '300',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  input: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: '300',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    color: '#ffffff',
  },
  grayText: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
});

export const css_text_fieldStyles = {
  root: {
    color: '#464',
    '& .MuiFormLabel-root': {
      color: '#464646',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-error fieldset': {
        borderColor: '#cf6679',
      },
      '& fieldset, &:not(.Mui-error) fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ccc',
      },
      '& input': {
        color: '#464646',
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#464646',
    },
  },
};

export default generalStyles;
