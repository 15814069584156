// LICENSE_CODE YC
import React, {useMemo} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import Announce_kit from 'announcekit-react';
import './index.scss';
import config_ext from '../../config_ext.js';
import {ConfigProvider, Grid, Space, Tabs} from 'antd';
import Icon from '@ant-design/icons';
import {ReactComponent as Profile_icon_svg} from '../../profile_icon.svg';
import {ReactComponent as Bell_icon_svg} from '../../bell_icon.svg';
import {ReactComponent as Play_icon_svg} from '../../play_icon.svg';
import xurl from '../../../../../util/xurl.js';
import util from '../../util.js';
import auth from '../../auth.js';

const DISABLED_PAGES = [
  '/lesson', '/', '/change-password', '/reset-password',
  '/change-email', '/signin',
];
const styles_announce_kit = {
  position: 'absolute',
  top: '-5px',
  right: '-3px',
  pointerEvents: 'none',
};
const Bottom_bar = props=>{
  let {user: user_profile} = auth.use_auth();
  const location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  const history = useHistory();
  const screens = Grid.useBreakpoint();
  const is_bottom_bar_visible = useMemo(
    ()=>DISABLED_PAGES.indexOf(location.pathname) < 0 &&
    !location.pathname.includes('lesson') &&
    !location.pathname.includes('signin') &&
    !location.pathname.includes('account/activate') &&
    screens.xs, [location, screens.xs]);
  const nav_value = useMemo(()=>{
    let value = location.pathname === '/' ? 'home' : location.pathname.slice(1);
    if (['account', 'billing-details'].indexOf(value) > -1)
      value = 'profile';
    if (nav_value !== 'beta')
    {
      if (window.Intercom)
        window.Intercom('hide');
    }
    if (location.pathname.includes('class')
      && !location.pathname.includes('lesson'))
    {
      value = 'home';
    }
    return value;
  }, [location]);
  const tab_click_handle = (key, event)=>{
    switch (key)
    {
    case 'profile':
      window.Intercom('hide');
      history.push(xurl.url('/profile', qs_o));
      util.gtm_push({event: 'customPageview', pagePath: '/profile'});
      break;
    case 'home':
      window.Intercom('hide');
      history.push(xurl.url('/home', qs_o));
      util.gtm_push({event: 'customPageview', pagePath: '/home'});
      window.location.reload();
      break;
    case 'new':
      event.preventDefault();
      break;
    default:
      break;
    }
  };
  const Tab = ({icon, label})=><Space size={0}
    direction="vertical" align="center">
    {icon}
    <div>{label}</div>
  </Space>;
  const items = [
    {
      key: 'home',
      label: <Tab label="Classes"
        icon={<Icon component={Play_icon_svg}
          style={{fontSize: 20, marginRight: 0}} />} />,
    },
    config_ext.front.enable_new_tab && {
      key: 'new',
      label: <Announce_kit widgetStyle={styles_announce_kit}
        user={user_profile ? {id: user_profile.uid, email: user_profile.email,
          name: user_profile.displayName} : {}}
        widget={config_ext.announcekit.url}>
        <Tab label="New" icon={<Icon component={Bell_icon_svg}
          style={{fontSize: 20, marginRight: 0}} />} />
      </Announce_kit>,
    },
    {
      key: 'profile',
      label: <Tab label="Me"
        icon={<Icon component={Profile_icon_svg}
          style={{fontSize: 20, marginRight: 0}} />} />,
    },
  ];
  return (
    is_bottom_bar_visible ?
      <ConfigProvider theme={{components: {Tabs:
      {colorText: '#fff', lineWidthBold: 0, paddingXS: 6, margin: 0,
        colorPrimary: '#fff', colorPrimaryActive: '#fff'}}}}>
        <Tabs tabBarStyle={{backgroundColor: '#171717'}} tabPosition="bottom"
          size="small" id="bottom-bar-nav" defaultActiveKey="home"
          activeKey={nav_value} onTabClick={tab_click_handle} items={items} />
      </ConfigProvider>
      : <div />
  );
};

export default Bottom_bar;
