import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

// export const openPanel = (e, expanded, name, setPanelOpen) => {
//   let tabs = [];
//   if (window.localStorage.getItem('feedTabs')) {
//     tabs = JSON.parse(window.localStorage.getItem('feedTabs'));
//   }
//   tabs = tabs.filter(item => item.id !== `${name}-feed`);
//   tabs.push({ isOpen: expanded, id: `${name}-feed`, });
//   window.localStorage.setItem('feedTabs', JSON.stringify(tabs));

//   setPanelOpen(expanded);
// };

export const AccordionDetails = withStyles(() => ({
  root: {
    padding: 16,
    backgroundColor: '#1E1E1E',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
}))(MuiAccordionDetails);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#262626',
    marginBottom: -1,
    minHeight: 56,
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'uppercase',
    '&$expanded': {
      minHeight: 56,
      borderRadius: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);


export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      marginBottom: '2.3rem',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: '2.3rem',
    },
  },
  expanded: {},
})(MuiAccordion);

export default Accordion;
