// LICENSE_CODE YC
import React from 'react';
import auth from './auth.js';
import Header from './header.js';
import {Col, Row, Divider, Button} from 'antd';

const E = props=>{
  let {user: user_profile, signout} = auth.use_auth();

  // XXX abdel: redirect to home if account is activated

  if (!user_profile)
    return null;
  return (
    <div className="yc-main">
      <Header go_back={false} />
      <Row justify="center">
        <Col flex="auto" sm={24} md={22} lg={14}>
          <Row gutter={[0, 20]} justify="center"
            style={{marginTop: 30, marginBottom: 30}}>
            <Col span={24}>
              <Divider style={{margin: 0}} />
              <Button onClick={()=>{}}
                style={{padding: 15, height: 'auto'}} block type="text">
                <Row justify="space-between" align="middle">
                  <Col><span>Pay</span></Col>
                </Row>
              </Button>
              <Divider style={{margin: 0}} />
              <Button onClick={signout}
                style={{padding: 15, height: 'auto'}} block type="text">
                <Row justify="space-between" align="middle">
                  <Col><span>Sign out</span></Col>
                </Row>
              </Button>
              <Divider style={{margin: 0}} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default auth.with_auth(E);
