// LICENSE_CODE YC
import React, {useEffect} from 'react';
import Get_app_icon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';

let _styles = {
  download_button: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    color: 'white',
    borderColor: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: 'none',
    fontWeight: '600',
    marginBottom: '1rem',
  },
};
let styles_use = makeStyles(_styles);

const E = ({href, chef_name})=>{
  let classes = styles_use();
  useEffect(()=>{
    if (document.getElementById('Recipe') !== null)
      document.getElementById('Recipe').classList.add('pdfView');
    if (document.getElementsByClassName('Overview')[0] !== null)
      document.getElementsByClassName('Overview')[0].classList.add('pdfView');
  }, []);
  const pdf_download = ()=>window.open(href);
  return (
    <Button
      color="primary"
      startIcon={<Get_app_icon />}
      className={classes.download_button}
      onClick={pdf_download}
      fontSize="large"
    >
      {chef_name && chef_name.split(' ')[0].toUpperCase()+'’S CLASS RECIPES'}
    </Button>
  );
};

export default E;
