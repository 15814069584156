// LICENSE_CODE MIT
let E = {};
export default E;

E.trim = s=>{
  return s.replace(/\s+/ug, ' ').replace(/^\s+|\s+$/ug, '');
};

E.trimh = (s, is_allow_empty_newline)=>{
  let ret = '';
  let lines = s.split('\n');
  for (let l of lines)
  {
    let _l = l.trim();
    if (!_l && !is_allow_empty_newline)
      continue;
    ret += _l+'\n';
  }
  return ret;
};
E.cap_first = s=>{
  if (!s)
    return '';
  s = s.trim();
  if (s == '')
    return '';
  let words = s.split(' ');
  for (let i in words)
  {
    let word = words[i].toLowerCase();
    if (['ai'].includes(word))
      continue;
    words[i] = word.toLowerCase();
  }
  words[0] = words[0][0].toUpperCase() + words[0].substring(1);
  return words.join(' ');
};
E.cap_title = E.cap_first;
E.s2a = s=>E.trim(s).split(' ');
E.j2s = (json, flat)=>{
  if (flat)
    return JSON.stringify(json);
  return JSON.stringify(json, null, 2);
};
E.null2s = json=>{
  for (let field in json)
  {
    let o = json[field];
    if (o instanceof Object)
      continue;
    if (o === null)
      json[field] = '';
  }
  return json;
};
E.n2lbl = num=>+num.toFixed(2)+'';
E.currency2lbl = E.n2lbl;
E.email2mongo = email=>email.replace(/\./ug, '@@');
E.mongo2email = id=>id.replace(/@@/ug, '.');
E.mongo2domain = id=>id.replace(/_/ug, '.');
E.domain2mongo = domain=>domain.replace(/\./ug, '_');
E.str2reg = (_str, flags)=>{
  flags = flags || 'uig';
  _str = _str.replace(/[.*+?^${}()|[\]\\]/ug, '\\$&');
  return new RegExp(_str, flags);
};
E.is_str = obj=>typeof obj === 'string';
E.base642s = base64=>{
  let buf = Buffer.from(base64, 'base64');
  return buf.toString('utf-8');
};

E.s2base64 = s=>{
  let buf = Buffer.from(s, 'utf-8');
  return buf.toString('base64');
};
