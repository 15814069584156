// LICENSE_CODE YC
import ActionTypes from '../actions/types';

const defaultState = {
  selected: 'home',
  visible: true,
  gift_popup_open: false,
  inactive_user_popup_open: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GIFT_POPUP_SET:
      return {...state, gift_popup_open: action.open};
    case ActionTypes.INACTIVE_USER_POPUP_SET:
      return {...state, inactive_user_popup_open: action.open};
    default:
      return state;
  }
};
