// LICENSE_CODE YC
let allowed_statuses = ['active', 'pending-cancel'];

export const is_plan_active = meta_data=>{
  if (!meta_data)
    return false;
  if (meta_data.plan)
  {
    let date_now_in_seconds = Date.now() / 1000;
    return allowed_statuses.includes(meta_data.plan.status)
      && meta_data.plan.end_date > date_now_in_seconds;
  }
  else if (meta_data.custom_attributes)
  {
    return meta_data.custom_attributes.Plan
      && meta_data.custom_attributes.Plan != 'no plan';
  }
  return false;
};
