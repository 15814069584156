const styles = (theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    '& .Mui-focused fieldset': {
      borderColor: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: '2.4rem',
      paddingRight: '2.4rem',
    },
    [theme.breakpoints.up('sm')]: {
      width: '400px',
      margin: 'auto',
    },
  },
  heading: {
    color: '#fff',
    margin: '2.4rem auto 2.4rem',
    fontFamily: 'Open Sans',
    textAlign: 'center',
    '& h2': {
      fontSize: '30px',
      fontWeight: '300',
      '& span': {
        fontWeight: '600',
      },
    },
    '& p': {
      fontSize: '14px',
      fontWeight: '300',
      margin: '0',
    },
  },
  wrapper: {
    backgroundColor: '#fff',
    padding: '36px 16px',
    borderRadius: '6px',
    maxWidth: '327px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      maxWidth: '500px',
      padding: '4rem',
    },
  },
  loginWith: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textFieldWrapper: {
    width: '100%',
  },
  textFieldLabel: {
    fontSize: '1.4rem',
    fontWeight: 300,
    color: '#444444',
  },
  boldDivider: {
    height: '1px',
    border: 'solid 0.5px #929292',
  },
  dividerText: {
    color: '#777770',
    fontSize: '14px',
    fontWeight: 600,
  },
  fbButton: {
    backgroundColor: '#4367b2',
    color: 'white',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    transition: 'opacity 0.3s linear',
    '&:hover': {
      backgroundColor: '#4367b2',
      opacity: 0.8
    }
  },
  googleButton: {
    backgroundColor: '#fbfbfb',
    border: 'solid 1px rgba(153, 153, 153, 0.6)',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    color: '#494949',
    marginTop: '1.6rem',
    transition: 'opacity 0.3s linear',
    '&:hover': {
      opacity: 0.8
    }
  },
  errorMessage: {
    color: 'white',
    textAlign: 'center',
    padding: '1rem',
    borderRadius: '5px',
    fontWeight: '700',
    backgroundColor: 'rgb(178, 0, 40)',
  },
  signInBtn: {
    marginTop: '1.6rem',
    width: '100%',
  },
  bottomText: {
    textDecoration: 'underline',
    marginTop: '1.6rem',
    fontFamily: 'Open Sans',
    fontSize: '1.4rem',
    fontWeight: '300',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#333333',
  },
  grayText: {
    color: '#333333',
  },
  subWrapper: {
    color: '#333333',
    fontSize: '1.4rem',
    fontWeight: 300,
    maxWidth: 231,
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
  },
  signUpLink: {
    marginTop: '2.6rem',
    '& a': {
      textDecoration: 'underline',
      color: '#333',
    },
  },
  magicLink: {
    fontSize: '1.6rem',
    fontWeight: 600,
    cursor: 'pointer',
    margin: '1.6rem 0',
  },
  leftIcon: {
    fontSize: '3rem',
    color: '#999999',
  },
  backIconBox: {
    width: '2.4rem',
    height: '2.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '-1.8rem',
    [theme.breakpoints.up('sm')]: {
      top: '0.2rem',
    },
    zIndex: '45',
    transition: 'top 0.6s',
  },
  nextStep: {
    position: 'relative',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '12rem',
    paddingBottom: '12rem',
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: '#ccc',
      color: '#fff',
    },
  },
  magicStep: {
    paddingTop: '17rem',
    paddingBottom: '17rem',
    textAlign: 'center',
    '& h2': {
      fontSize: '2rem',
      fontWeight: 600,
      marginBottom: '1.3rem',
      color: '#000000',
    },
    '& p': {
      color: '#000000',
      fontSize: '1.6rem',
      fontWeight: 300,
    },
  },
  pwd_step: {
    paddingTop: '0',
  },
  stepReset: {
    paddingTop: '0',
    paddingBottom: '0rem',
  },
  emailData: {
    textAlign: 'center',
    marginBottom: '1.6rem',
    color: '#333333',
    display: 'block',
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  privacyTerms: {
    width: '100%',
    position: 'absolute',
    left: '0',
    top: '0',
    margin: '0 auto',
    zIndex: '200',
    backgroundColor: '#000',
  },
  privacyText: {
    '& span': {
      cursor: 'pointer',
    },
  },
}));

export const cssTextFieldStyles = {
  root: {
    '& .MuiFormHelperText-contained': {
      color: '#b00020',
      fontSize: '1.2rem',
      marginBottom: 0,
      marginLeft: 0
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#999999 !important',
        borderWidth: '1px !important',
        color: '#444444',
        fontSize: '14px',
        fontWeight: '300',
      },
      '&.Mui-error fieldset': {
        borderColor: '#b00020 !important',
      },
      '& input': {
        color: '#444',
        fontSize: '17px',
        fontWeight: '300',
        '-webkit-box-shadow': '0 0 0 30px white inset !important',
        '-webkit-text-fill-color': '#444'
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#999999',
    },
    '& a': {
      textDecoration: 'underline',
      color: '#b00020',
    },
    '& button': {
      fontFamily: 'Open Sans',
      fontSize: '1.2rem',
      fontWeight: '300',
      textAlign: 'left',
      color: '#b00020',
      textDecoration: 'underline',
      background: 'none',
      margin: 0,
      padding: 0,
      border: 'none',
      cursor: 'pointer'
    },

  },
};

export default styles;
