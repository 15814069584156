// LICENSE_CODE YC
import React, {useState, useEffect, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Loader from './loader';
import Class_info from './class_info';
import Header from './header.js';
import Popup from './popup2.js';
import Creds_form from './components/CredsForm';
import {textCapitalize} from './helpers/TextHelper';
import config_ext from './config_ext.js';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import back_app from './back_app.js';
import util from './util.js';
import auth from './auth.js';
import xurl from '../../../util/xurl.js';
import './home.scss';
import {Col, Image, Row, Skeleton, Typography} from 'antd';
import use_embla_carousel from 'embla-carousel-react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Text_field from '@material-ui/core/TextField';
import {Button, Box} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {useToasts} from 'react-toast-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';

let name_screen_styles = theme=>({
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '3.9%',
    color: '#000',
    fontSize: '1.6rem',
    lineHeight: '19px',
    textAlign: 'center',
    fontWeight: 400,
  },
  textFieldLabel: {
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  popupTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: '#000',
    marginTop: '0',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1.8em',
    },
    fontSize: '1.6rem',
  },
  popupContent: {
    lineHeight: '22px',
    fontSize: '1.6rem',
    fontWeight: 300,
    padding: '0',
    overflow: 'visible',
  },
  errorClass: {
    color: 'red',
    padding: '3%',
    border: '1px solid red',
    marginTop: '1rem',
  },
  blackedColor: {
    backgroundColor: '#000',
    borderRadius: '0.7rem',
  },
  buttonProgress: {
    color: '#ff007f',
    position: 'absolute',
    left: '45%',
    marginTop: 0,
  },
});

let cssTextFieldStyles = {
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#464646',
    },
  },
};

const useStyles = makeStyles(name_screen_styles);

const CssTextField = withStyles(cssTextFieldStyles)(Text_field);

const regex = /^[A-Za-z ]+$/;

const Name_screen = ({ onCloseNameScreen }) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [validLast, setValidLast] = useState(false);
  const [validFirst, setValidFirst] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonText, setButtonText] = useState('CONTINUE');
  let {user_full, user: current_user,
    user_full_update} = auth.use_auth();
  const { addToast } = useToasts();
  const validateInput = input => regex.test(input) && input.length >= 2;

  const handleChange = e => {
    const { value } = e.target;
    if (e.target.id === 'first-name') {
      setValidFirst(validateInput(value));
      setButtonDisabled(!(validateInput(value) && validateInput(lastName)));
      setFirstName(value);
    } else {
      setValidLast(validateInput(value));
      setButtonDisabled(!(validateInput(value) && validateInput(firstName)));
      setLastName(value);
    }
  };

  const submitLogin = async () => {
    setLoadingButton(true);
    setValidFirst(true);
    setValidLast(true);
    setButtonDisabled(true);
    setButtonText('UPDATING…');
    let issue = false;
    if (validateInput(firstName) && validateInput(lastName)) {
      const user_data = {
        first_name: textCapitalize(firstName),
        last_name: textCapitalize(lastName),
        name: textCapitalize(`${firstName} ${lastName}`),
      };
      try {
        try {
          await current_user.updateProfile({
            displayName: textCapitalize(`${firstName} ${lastName}`),
          });
        } catch (error) {
          issue = true;
          console.log('NameScreenComponent updateProfile ERROR: ', error);
          addToast('Can not update name. Please contact with tech support', { appearance: 'error' });
        }
        try {
          await back_app.user_set(user_data);
          user_full_update();
        } catch (err) {
          issue = true;
          console.log('NameScreenComponent user getIdToken ERROR: ', err);
          addToast('Can not update name. Please contact with tech support', { appearance: 'error' });
        }

        const intercomData = {
          user_id: user_full.firebase_id || user_full.user_id,
          // TODO check what is taken
          name: textCapitalize(`${firstName} ${lastName}`),
        };
        await back_app.intercom_set(intercomData);
      } catch (err) {
        issue = true;
        console.log('NameScreenComponent update name ERROR', err);
        addToast('Can not update name. Please contact with tech support', { appearance: 'error' });
      }
      if (!issue)
      {
        setLoadingButton(false);
        setButtonDisabled(false);
        setButtonText('Continue');
        onCloseNameScreen();
      }
      else
      {
        setLoadingButton(false);
        setButtonText('Continue');
        setButtonDisabled(false);
      }
    }
    else
    {
      setLoadingButton(false);
      setButtonText('Continue');
      setValidLast(false);
      setValidFirst(false);
      setButtonDisabled(false);
      addToast('Please check your input', { appearance: 'error' });
    }
  };

  return (
    <Dialog open={true} id={'screen-name-wrap'}>
      <Box className={classes.wrapper} id={'name-screen'}>
        <DialogContent className={classes.popupContent}>
          <h3 id="alert-dialog-title" className={classes.popupTitle}>Hi, what’s your name?</h3>
          <CssTextField
            id="first-name"
            label="First Name"
            name={'first-name'}
            type="text"
            className={classes.textField}
            onChange={handleChange}
            autoComplete="email"
            margin="normal"
            InputLabelProps={{ className: classes.labelsProps }}
            variant="outlined"
            fullWidth={true}
            error={!validFirst}
            value={firstName}
            required
          />
          <CssTextField
            id="last-name"
            name={'last-name'}
            label="Last Name"
            type="text"
            className={classes.textField}
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{ className: classes.labelsProps }}
            variant="outlined"
            fullWidth={true}
            required
            error={!validLast}
            value={lastName}
          />
        </DialogContent>
        <DialogActions className={buttonDisabled ? classes.blackedColor : ''}>
          <Button
            variant="contained"
            color="primary"
            className={classes.signInBtn}
            disabled={buttonDisabled}
            onClick={submitLogin}
          >
            <Box fontWeight="600" fontSize="1.4rem">
              {buttonText}
            </Box>
          </Button>
          {loadingButton && <CircularProgress className={classes.buttonProgress} />}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

let {Title, Paragraph} = Typography;
let Preview = ()=>{
  let [continue_watching, continue_watching_set] = useState({});
  let [bg_img, bg_img_set] = useState();
  useEffect(()=>{
    let listners = je.on('continue_watching', cw=>{
      if (!cw)
        return;
      let base_url = config_ext.general.mediaAssetsUrl;
      let image_path = `${cw.classId}/${cw.lessonId}`;
      let mobile_img = 'hp_hero.jpg';
      let desktop_img = 'cover.jpg';
      let _img = window.innerWidth <= 767
        ? `url(${base_url}/${image_path}/${mobile_img})`
        : `url(${base_url}/${image_path}/${desktop_img})`;
      bg_img_set(_img);
      continue_watching_set(cw);
    });
    return ()=>je.off(listners);
  }, []);
  let history = useHistory();
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);

  let {classId: class_id, lessonNum: lesson_num,
    chefName: name, lessonName: lesson_name
  } = continue_watching;
  let click_handle = useCallback(()=>{
    let page = '';
    if (class_id === 'c00' && lesson_num === 1)
      page = '/class/c01/';
    else
      page = `/class/${class_id}/lesson/${lesson_num}`;
    util.gtm_push({event: 'customPageview', pagePath: page});
    history.push(xurl.url(page, qs_o));
  }, [history, class_id, lesson_num, qs_o]);

  let button_text = class_id == 'c00' && lesson_num == 1
    ? 'START WATCHING'
    : 'CONTINUE WATCHING';
  return <Class_info square bordered button_text={button_text} centered
    click_handle={click_handle} entered name={name} id={class_id}
    classTitle={lesson_name} clickable custom_bg_img={bg_img} />;
};

let E = ()=>{
  let enable_coming_soon_lessons = false;
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let [classes, classes_set] = useState([]);
  let [coming_soon_classes, coming_soon_classes_set] = useState([]);
  let [coming_later_classes, coming_later_classes_set] = useState([]);
  let [is_welcome_popup_open, is_welcome_popup_open_set] = useState(false);
  let [is_user_single_class, is_user_single_class_set] = useState(false);
  let [show_name_screen, show_name_screen_set] = useState(
    !!qs_o.dbg_name_screen);
  let [show_secure_account_popup, show_secure_account_popup_set] =
    useState(false);
  let [loading, loading_set] = useState(true);
  let [display_grp, set_display_grp] = useState([]);
  let [loading_display_grp, set_loading_display_grp] = useState(true);
  let {user_full, user: user_profile,
    user_full_update} = auth.use_auth();

  let history = useHistory();
  useEffect(()=>{
    let es = eserf(function* _display_grp_get(){
      let res = yield back_app.display_grp_get();
      set_loading_display_grp(false);
      if (res.err)
        return;
      set_display_grp(res);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    let es = eserf(function* _class_get_list(){
      let res = yield back_app.class_get_list();
      if (res.err)
        return;
      let {live_classes, coming_soon, coming_later} = res;
      classes_set(live_classes);
      coming_soon_classes_set(coming_soon);
      coming_later_classes_set(coming_later);
    });
    return ()=>es.return();
  }, []);
  useEffect(()=>{
    if (user_full)
    {
      if (user_full.yc_gen_pwd &&
        (user_full.login_with == 'password' ||
          user_full.login_with == 'yeschef_signup'))
      {
        show_secure_account_popup_set(true);
      }
      else
      {
        show_secure_account_popup_set(false);
      }
      if (!user_full.first_name && !user_full.last_name)
        show_name_screen_set(true);
      else if (user_full.isFirstLogin == undefined
        || user_full.isFirstLogin)
      {
        if (user_full.plan && user_full.plan.name == 'Single class' ||
          user_full.custom_attributes &&
          user_full.custom_attributes.Purchase == 'Single class')
        {
          is_user_single_class_set(true);
        }
        if (user_full.yc_gen_pwd == false ||
          user_full.login_with !== 'password' &&
          user_full.login_with !== 'yeschef_signup'
        )
        {
          is_welcome_popup_open_set(true);
        }
      }
    }
    loading_set(false);
  }, [user_full]);
  let class_render = (item, idx, is_upcoming = false)=><Col
    key={idx} span={24} style={{paddingBottom: '2.4rem'}}>
    <Class_info bordered clickable={!is_upcoming} {...item}
      click_handle={()=>history.push(xurl.url(`/class/${item.id}/`, qs_o))}
    />
  </Col>;

  let Lesson_preview = ({lesson: {classId: class_id, lessonId: lesson_id,
    title, classes: name, real_lesson_num, cover_image}})=>{
      return (
        <div style={{minWidth: 0, display: 'inline-block', flex: '0 0 270px',
          cursor: 'pointer', paddingLeft: '2.4rem'}} onClick={()=>history.push(
            xurl.url(`/class/${class_id}/lesson/${real_lesson_num}`, qs_o))} >
          <Image
            rootClassName="lesson_preview_image_container"
            className="lesson_preview_image"
            alt={title}
            preview={false}
            height={246 / (16/9)}
            fallback={`${config_ext.general.mediaAssetsUrl}/LessonFallback.jpg`}
            src={`${config_ext.general.mediaAssetsUrl}/${class_id}/${lesson_id}/cover.jpg`}
          />
          <Title style={{
            fontWeight: 600, marginBottom: 0, textTransform: 'uppercase'
          }} level={5}>{title}</Title>
          <Paragraph style={{textTransform: 'uppercase', fontWeight: 300}}>
            {name}</Paragraph>
        </div>
      );
    };
  const popup_handle = ()=>eserf(function* _popup_handle(){
    yield back_app.is_first_login_set(false);
    is_user_single_class_set(false);
    is_welcome_popup_open_set(false);
    user_full_update();
  });
  const name_screen_close_handle = ()=>{
    window.scrollTo(0, 0);
    show_name_screen_set(false);
  };
  const Display_grp_slider = ({title, slides})=>{
    const [embla_ref] = use_embla_carousel({loop: true, slidesToScroll: 1,
      align: 'start'});
    return (
      <div style={{overflow: 'hidden', marginBottom: 40}} >
        <Row style={{paddingLeft: '2.4rem'}}>
          <Title style={{textTransform: 'uppercase'}} level={4}>
            {title}
          </Title>
        </Row>
        <div
          ref={embla_ref}>
          <Row wrap={false} >
            {slides}
          </Row>
        </div>
      </div>
    );
  };
  const Display_grp_skeleton = ({size})=>{
    return Array(size).fill(null).map((_, i)=><div key={i}
      style={{paddingLeft: '2.4rem', marginBottom: 40}}>
      <Skeleton paragraph={false} title={{width: 200}} active />
      <div style={{overflow: 'hidden'}}>
        <Row wrap={false}>
          {Array(3).fill(null).map((__, j)=><Col key={j}>
            <Skeleton.Input active
              style={{width: 270, height: 150, margin: '10px 10px 10px 0'}}/>
            <Skeleton paragraph={false} active
              style={{width: 100, marginBottom: 10}} />
            <Skeleton paragraph={false} active
              style={{width: 150, marginBottom: 10}} />
          </Col>
          )}
        </Row>
      </div>
    </div>);
  };
  if (loading)
    return <Loader />;
  return (
    <div className="yc-main">
      <Popup
        display_name={textCapitalize(user_profile.displayName)}
        on_close_cb={popup_handle}
        on_submit_cb={popup_handle}
        is_open={is_user_single_class}
        title={'It\'s your lucky day!'}
        message={'In the holiday spirit, we’re giving you free access to all of the platform for 30 days'}
        button_text="Get Cooking"
      />
      <Popup
        display_name={textCapitalize(user_profile.displayName)}
        on_close_cb={popup_handle}
        on_submit_cb={popup_handle}
        is_open={is_welcome_popup_open}
        title={'Welcome to Yeschef'}
        message={'Sharpen your knives and your senses \n You\'re about to take your cooking to the next level!'}
        button_text={'Get Cooking'}
      />
      <Popup
        display_name={user_profile.displayName}
        is_open={show_secure_account_popup}
        title={'Secure your account now'}
        message={''}
        form={<Creds_form />}
        button_text={false}
        closable={false}
        mask_closable={false}
      />
      {show_name_screen ?
        <div className="overlay-bg">
          <div className={'popup-styles'} id={'name-popup'}>
            <Name_screen onCloseNameScreen={name_screen_close_handle} />
          </div>
        </div>
        : ''}
      <Header go_back={false}/>
      <Row justify="center">
        <Col sm={24} md={22} lg={18} xl={14}>
          <Preview />
          <Row style={{marginTop: '2.4rem'}}>
            {loading_display_grp ? <Display_grp_skeleton size={2} />
            : display_grp
                .map(group=><Display_grp_slider title={group.title}
                  key={group.title}
                  slides={group.lessons.map((lesson, index)=><Lesson_preview
                    key={index + lesson.lessonId} lesson={lesson} />)}
                />
                )}
          </Row>
          <Row style={{padding: '0 2.4rem'}}>
            <Col span={24}>
              <Row>
                <Title level={4}>OUR CHEFS</Title>
              </Row>
              <Row>
                {classes.map((item, idx)=>class_render(item, idx, false))}
              </Row>
              {enable_coming_soon_lessons &&
              <>
                {coming_soon_classes.length > 0 && <Row>
                  <Title level={4}>COMING NEXT</Title>
                </Row>}
                <Row>
                  {coming_soon_classes.map(
                    (item, id)=>class_render(item, id, true))}
                </Row>
                {coming_later_classes.length > 0 && <Row>
                  <Title level={4}>COMING LATER</Title>
                </Row>}
                <Row>
                  {coming_later_classes.map(
                    (item, id)=>class_render(item, id, true))}
                </Row>
              </>}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default auth.with_auth(E);
