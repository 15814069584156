// LICENSE_CODE YC
import React, {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import Swipeable_views from 'react-swipeable-views';
import {Box, Tabs, Tab, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Expand_more_icon from '@material-ui/icons/ExpandMore';
import auth from './auth.js';
import React_player_loader from '@brightcove/react-player-loader';
import Device_orientation, {Orientation} from 'react-screen-orientation';
import Header from './header.js';
import Ingredients_section from './components/IngredientsSection';
import Shorthand_desc from './shorthand_desc.js';
import Loader from './loader';
import Feedback from './feedback.js';
import Popup from './popup.js';
import Step_card from './components/StepCard';
import Display_group from './components/DisplayGroup/index1.js';
import {gift_popup_set, inactive_user_popup_set} from './actions/mainMenu';
import watch_history from './watch_history.js';
import {convertTime} from './helpers';
import {textCapitalize} from './helpers/TextHelper';
import util from './util.js';
import {Accordion, AccordionDetails,
  AccordionSummary} from './ui/Accordion';
import Pdf_button from './components/PdfButton/PdfButton';
import './lesson.scss';
import config_ext from './config_ext.js';
import default_img from './assets/images/LessonFallback.jpg';
import eserf from '../../../util/eserf.js';
import xurl from '../../../util/xurl.js';
import je from '../../../util/je.js';
import back_app from './back_app.js';
import metric from './metric.js';
import { isArray } from 'lodash';

let Overview_data = ({name, data})=>{
  if(!data || (isArray(data) && data.length == 0)) {
    return;
  }

  let text = '';

  // Check if data is of type array
  if (data.length > 0) {
    text = data.filter(Boolean).join(' | ');
  } else {
    text = String(data);
  }

  return (
    <div className="overview-section-data">
      <h2 style={{paddingRight: '1.2rem'}}>{name}</h2>
      <p className="body-text">{text}</p>
    </div>
  );  
};

let fetch_history_watching = ()=>eserf(function* _fetch_history_watching(){
  let res = yield back_app.history_get();
  if (!res.err)
    je.set('continue_watching', res);
});

const show_text_tracks = ref=>{
  let choosen_track_language =
  window.localStorage.getItem('SubtitlesLanguage') || 'English';
  let tracks = Array.from(ref.textTracks())
    .filter(track=>['subtitles', 'captions'].includes(track.kind));
  tracks.forEach(track=>{
    const track_language = track.label;
    if (track_language)
    {
      track.mode =
      track_language === choosen_track_language ? 'showing' : 'disabled';
    }
  });
};
const set_player_volume = ref=>{
  ref.volume(window.localStorage.getItem('volume') || 1);
};

const Video_player = ({
  account_id,
  chefName,
  classId,
  lesson_id,
  continue_watching,
  finished,
  next_lesson_play,
  previous_lesson_play,
  handlePlay,
  isFirstLesson,
  isLastLesson,
  lessonNum,
  lessonTitle,
  onPlay,
  pausedVideo,
  player_id,
  subtitles,
  video_id,
  on_init,
})=>{
  let {gift_popup_open} = useSelector(state=>state.mainMenu);
  let dispatch = useDispatch();
  let lastRecordedTime = 0;
  let startPosition = useRef(null);
  let [player_ready, player_ready_set] = useState(false);
  let [video_replay, video_replay_set] = useState(false);
  let [event_track, event_track_set] = useState('');
  let [event_title, event_title_set] = useState('');
  let [subtitle, subtitle_set] = useState([]);
  let video_play_ref = useRef(null);
  let gift_popup_open_ref = useRef(null);
  let [player, player_set] = useState(null);
  const areSubtitlesEquals = (a, b)=>a.src === b.src;
  const areArraysEqual = (a, b, areEqualItems)=>{
    if (a === b)
      return true;
    if (a === null || b === null)
      return false;
    if (a.length !== b.length)
      return false;
    for (let i = 0; i < a.length; i++)
    {
      if (a[i] !== b[i])
        return false;
      if (!areEqualItems(a[i], b[i]))
        return false;
    }
  };
  const prevProp = useRef({classId, lessonNum, subtitles}).current;
  useEffect(()=>{
    if ((prevProp.classId !== classId || prevProp.lessonNum !== lessonNum) &&
        !areArraysEqual(prevProp.subtitles, subtitles, areSubtitlesEquals) &&
        !areArraysEqual(subtitles, subtitle, areSubtitlesEquals))
    {
      subtitle_set(subtitles);
    }
    return ()=>{
      prevProp.classId = classId;
      prevProp.lessonNum = lessonNum;
      prevProp.subtitles = subtitles;
    };
  }, [classId, lessonNum, subtitles]);
  useEffect(()=>{
    gift_popup_open_ref.current = gift_popup_open;
    if (!player)
      return;
    if (gift_popup_open)
      player.pause();
    else
      player.play();
  }, [gift_popup_open]);
  let watch_history_load = useCallback(ref=>eserf(function*
    _watch_history_load(){
    let historyVideoTime;
    let resp = yield back_app.history_get();
    if (resp.userProgress && resp.userProgress[`${classId}_${lessonNum}`])
      historyVideoTime = resp.userProgress[`${classId}_${lessonNum}`];
    let tmp = watch_history.get_last_recorded_time(lesson_id);
    let time = tmp || 0;
    let videoDuration = Math.floor(ref.duration());
    if (time == videoDuration || historyVideoTime == videoDuration)
      ref.currentTime(0);
    else if (time && !historyVideoTime)
    {
      ref.currentTime(time);
    }
    else if (historyVideoTime)
    {
      ref.currentTime(historyVideoTime);
      time = historyVideoTime;
    }
    lastRecordedTime = time;
  }), []);
  useEffect(()=>{
    if (!player)
      return;
    let control_bar = player.$('.vjs-control-bar');
    let insert_before_node = player.$('.vjs-volume-panel');
    let insert_between_node = player.$('.vjs-play-control');
    let previous_button = document.createElement('button');
    let next_button = document.createElement('button');
    let previous_container = document.createElement('span');
    let previous_image = document.createElement('span');
    let next_container = document.createElement('span');
    let next_image = document.createElement('span');
    previous_button.id = 'PreviousButton';
    next_button.id = 'NextButton';
    previous_button.setAttribute('class',
      'controls-container vjs-control vjs-button vjs-skip-previous');
    previous_button.setAttribute('type', 'button');
    previous_button.setAttribute('aria-disabled', 'false');
    previous_button.setAttribute('title', 'Previous Playlist Item');
    previous_container.setAttribute('class', 'vjs-icon-placeholder');
    previous_container.setAttribute('aria-hidden', 'true');
    previous_image.setAttribute('class', 'vjs-control-text');
    previous_image.setAttribute('aria-live', 'polite');
    previous_image.textContent = 'Skip Previous';
    previous_button.appendChild(previous_container);
    previous_button.appendChild(previous_image);
    next_button.setAttribute('class',
      'controls-container vjs-control vjs-button vjs-skip-next');
    next_button.setAttribute('type', 'button');
    next_button.setAttribute('aria-disabled', 'false');
    next_button.setAttribute('title', 'Next Playlist Item');
    next_container.setAttribute('class', 'vjs-icon-placeholder');
    next_container.setAttribute('aria-hidden', 'true');
    next_image.setAttribute('class', 'vjs-control-text');
    next_image.setAttribute('aria-live', 'polite');
    next_image.textContent = 'Skip Next';
    next_button.appendChild(next_container);
    next_button.appendChild(next_image);
    control_bar.insertBefore(previous_button, insert_between_node);
    control_bar.insertBefore(next_button, insert_before_node);
    previous_button.addEventListener('click', ()=>{
      if (isFirstLesson())
        previous_lesson_play();
    });
    next_button.addEventListener('click', ()=>{
      if (isLastLesson())
        next_lesson_play();
    });
    return ()=>{
      previous_button.remove();
      next_button.remove();
      previous_container.remove();
      previous_image.remove();
      next_container.remove();
      next_image.remove();
    };
  }, [player, isFirstLesson, isLastLesson, next_lesson_play,
    previous_lesson_play]);
  const none_mobile_display = ({ref})=>{
    let screen_width = window.screen.width;
    let screen_height = window.screen.height;
    if (screen_width >= 960 || screen_width >= screen_height)
      return;
    if (!ref || ref.paused())
      return;
    setTimeout(()=>ref.userActive(false), 2000);
  };
  const loaded_metadata_handle = useCallback(async ()=>{
    player_ready_set(true);
    watch_history_load(player);
    video_play_ref.current = false;
    if (gift_popup_open_ref.current)
      return;
    try {
      await player.play();
    } catch(error) {
      if (error.name == 'NotAllowedError' || error.name == 'AbortError')
        return;
      metric.error(error);
    }
  }, [player, watch_history_load]);
  const loaded_data_handle = useCallback(()=>{
    show_text_tracks(player);
    set_player_volume(player);
  }, [player]);
  const play_handle = useCallback(()=>{
    window.localStorage.setItem('playing', true);
    if (onPlay)
    {
      onPlay();
      handlePlay(classId, lessonNum);
    }
    let current_date = new Date();
    if (window.dataLayer)
    {
      window.dataLayer.push({
        event: 'Video Started',
        videoTitle: lessonTitle,
        videoTime: player.currentTime(),
        date: current_date.toUTCString(),
        chefName,
      });
      if (player.currentTime() === 0)
      {
        window.dataLayer.push({
          event: 'videoStartedFrom0',
          videoTitle: lessonTitle,
          videoTime: player.currentTime(),
          date: current_date.toUTCString(),
          chefName,
        });
      }
    }
    if (video_play_ref.current === true)
      none_mobile_display({player});
  }, [player, chefName, classId, handlePlay, lessonNum, lessonTitle, onPlay]);
  const pause_handle = useCallback(()=>{
    window.localStorage.setItem('playing', false);
    let current_date = new Date();
    let secondsWatched = Math.round(startPosition.current);
    pausedVideo(player);
    util.gtm_push({
      event: 'Video paused',
      videoTitle: lessonTitle,
      videoTime: player.currentTime(),
      date: current_date.toUTCString(),
      secondsWatched,
      chefName,
    });
    video_play_ref.current = true;
  }, [player, chefName, lessonTitle, pausedVideo]);
  const time_update_handle = useCallback(()=>{
    if (!player)
      return;
    let current_time = player.currentTime();
    let time_to_record = Math.floor(current_time);
    startPosition.current += 0.2;
    if (time_to_record % 5 == 0)
    {
      if (lastRecordedTime !== time_to_record)
      {
        let lesson_id = `${classId}_l${lessonNum.toString().padStart(2, '0')}`;
        watch_history.set(time_to_record, classId, lesson_id,
          lessonNum);
        lastRecordedTime = time_to_record;
      }
    }
    let duration = player.duration();
    if (window.dataLayer && duration)
    {
      let video_progress = current_time / duration * 100;
      if (video_progress >= 24.5 && video_progress <= 25.5)
        event_title_set('Video 25% watched');
      else if (video_progress >= 49.5 && video_progress <= 50.5)
        event_title_set('Video 50% watched');
      else if (video_progress >= 74.5 && video_progress < 75.5)
        event_title_set('Video 75% watched');
    }
    let current_date = new Date();
    if (event_track !== event_title)
    {
      event_track_set(event_title);
      window.dataLayer.push({
        event: event_title,
        videoTitle: lessonTitle,
        videoTime: current_time,
        date: current_date.toUTCString(),
        chefName,
      });
    }
  }, [player]);
  const end_handle = useCallback(()=>eserf(function* _end_handle(){
    if (!player)
      return;
    player.controls(true);
    let curr_time = Math.floor(player.currentTime());
    watch_history.set(curr_time, classId, lessonNum);
    lastRecordedTime = curr_time;
    let lesson_id = `${classId}_l${lessonNum.toString().padStart(2, '0')}`;
    let prev_progress = (continue_watching && continue_watching.userProgress) || {};
    yield back_app.history_set({class_id: classId, lesson_id,
      lesson_n: Number(lessonNum), chef_name: chefName,
      lesson_name: lessonTitle, curr_video_time: curr_time,
      user_progress: {...prev_progress, [lesson_id]: curr_time}});
    video_replay_set(true);
    fetch_history_watching();
    finished();
    let current_date = new Date();
    window.dataLayer.push({
      event: 'Video 100% watched',
      videoTitle: lessonTitle,
      videoTime: curr_time,
      date: current_date.toUTCString(),
      chefName,
    });
  }), [player]);
  const seeking_handle = useCallback(()=>{
    if (!video_replay)
      return;
    player.play();
    video_replay_set(false);
  }, [video_replay, player]);
  const texttrackchange_handle = useCallback(()=>{
    if (player.readyState() != 4)
      return;
    let tracks = Array.from(player.textTracks())
      .filter(track=>['subtitles', 'captions'].includes(track.kind));
    let showing_track = tracks.find(track=>track.mode === 'showing');
    if (showing_track)
    {
      let language = showing_track.label;
      window.localStorage.setItem('SubtitlesLanguage', language);
    }
    else
      window.localStorage.removeItem('SubtitlesLanguage');
  }, [player]);
  const volumechange_handle = useCallback(()=>{
    window.localStorage.setItem('volume', player.volume());
  }, [player]);
  useEffect(()=>{
    if (!player)
      return;
    player.on('loadedmetadata', loaded_metadata_handle);
    player.on('loadeddata', loaded_data_handle);
    player.on('play', play_handle);
    player.on('pause', pause_handle);
    player.on('timeupdate', time_update_handle);
    player.on('ended', end_handle);
    player.on('seeking', seeking_handle);
    player.on('texttrackchange', texttrackchange_handle);
    player.on('volumechange', volumechange_handle);
    return ()=>{
      player.off('loadedmetadata', loaded_metadata_handle);
      player.off('loadeddata', loaded_data_handle);
      player.off('play', play_handle);
      player.off('pause', pause_handle);
      player.off('timeupdate', time_update_handle);
      player.off('ended', end_handle);
      player.off('seeking', seeking_handle);
      player.off('texttrackchange', texttrackchange_handle);
      player.off('volumechange', volumechange_handle);
    };
  }, [player, loaded_metadata_handle, loaded_data_handle, play_handle,
    pause_handle, time_update_handle, end_handle, seeking_handle]);
  const success_handle = useCallback(({ref})=>{
    ref.playsinline(true);
    on_init(ref);
    player_set(ref);
  }, [on_init]);
  return (
    <>
      <React_player_loader
        accountId={account_id}
        videoId={video_id}
        playerId={player_id}
        options={{playsinline: true, playsInline: true, allowsInlineMediaPlayback: true, autoplay: false}}
        embedOptions={{responsive: true}}
        onSuccess={success_handle}
      />
    </>
  );
};

let default_tabs_data = [
  {label: 'OVERVIEW', dataValue: 'overview', order: 0},
  {label: 'RECIPE', dataName: 'shorthand', dataValue: 'shorthand', order: 1},
  {label: 'INGREDIENTS', dataName: 'supplies', dataValue: 'supplies', order: 2},
  {label: 'STEPS', dataName: 'steps', dataValue: 'steps', order: 3},
  {label: 'FEEDBACK', dataValue: 'feedback', order: 4},
];
const _styles = _theme=>({
  iconBox: {
    position: 'fixed',
    top: '2rem',
    left: '2rem',
    zIndex: '21',
    width: '2.4rem',
    height: '2.4rem',
    backgroundColor: 'rgba(0, 0, 0, 0.17)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentCon: {
    margin: '8px',
    '& p': {
      margin: 'unset',
    },
    '& h2': {
      marginTop: '0.4rem',
      marginBottom: '1.2rem',
    },
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginRight: '1.1rem',
  },
  iconsCon: {
    margin: '8px 8px 0 8px',
    '& p': {
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& div': {
      display: 'flex',
      marginBottom: '1rem',
    },
  },
  tabsRoot: {
    borderBottom: '1px solid rgba(146, 146, 146, 0.4)',
    backgroundColor: '#000',
  },
  scrollButtons: {
    marginTop: '0.5em',
  },
  tabRoot: {
    color: '#929292',
    fontWeight: 600,
    paddingBottom: '0rem',
    minWidth: '78px',
    '&$tabSelected': {
      color: '#ffffff',
      fontSize: '1.4rem',
    },
  },
});
const styles_use = makeStyles(_styles);
const E = ({match: {params: {classId, lessonNum}}})=>{
  // Sanitize the lessonNum by removing non-numeric characters (like 'l')
  lessonNum = lessonNum.replace(/[^\d]/g, '');
  
  let [continue_watching, continue_watching_set] = useState({});
  useEffect(()=>{
    let listners = je.on('continue_watching', cw=>{
      if (cw)
        continue_watching_set(cw);
    });
    return ()=>je.off(listners);
  }, []);
  let {user_full: userMetadata, user: current_user} = auth.use_auth();
  let [available_lessons, available_lessons_set] = useState([]);
  let [lesson_data, lesson_data_set] = useState({title: '', times: {},
    skills: [], ingredients: {}, description: ''});
  let [tab_value, tab_value_set] = useState(0);
  let [hands_on_text, hands_on_text_set] = useState(null);
  let [total_text, total_text_set] = useState(null);
  let [feed_popup, feed_popup_set] = useState(false);
  let [tabs_data, tabs_data_set] = useState(default_tabs_data);
  let [player, player_set] = useState(null);
  let ref_slider = useRef(null);
  let [item_slider, item_slider_set] = useState([]);
  let [pdf_url, pdf_url_set] = useState('');
  let [loading, loading_set] = useState(true);
  let styles = styles_use();
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let tabs = useRef([]);
  let [subtitles, subtitles_set] = useState(null);
  let should_open_inactive_dialog= ()=>{
    if (qs_o.dbg_inactive_users)
      return true;
    return;
    let date_now_in_seconds = Date.now() / 1000;
    // XXX: abdelkhlek check if plan is anual
    if (userMetadata.plan.end_date < date_now_in_seconds)
    {
      let ts = userMetadata?.events?.plan_expire_x?.ts;
      if (!ts)
        return true;
      ts = new Date(ts);
      let day_ago = new Date();
      day_ago.setDate(day_ago.getDate() - 1);
      if (ts >= day_ago)
        return false;
      return true;
    }
    return false;
  };
  let inactive_user_popup_open = ()=>dispatch(inactive_user_popup_set(true));
  useEffect(()=>{
    if (player && !loading && should_open_inactive_dialog())
    {
      setTimeout(()=>{
        player.pause();
        inactive_user_popup_open();
      }, 5000);
    }
  }, [loading, player]);
  let lesson_id = `${classId}_l${lessonNum.toString().padStart(2, '0')}`;
  const history_data_update = useCallback(()=>eserf(function*
  _history_data_update(){
    if (!player)
      return;
    let time_to_save = player.currentTime();
    let prev_progress = continue_watching
      ? continue_watching.userProgress
      : {};
    yield back_app.history_set({class_id: classId, lesson_id,
      lesson_n: lessonNum, chef_name: lesson_data.chefName,
      lesson_name: lesson_data.title, curr_video_time: time_to_save,
      user_progress: {...prev_progress, [lesson_id]: time_to_save}});
    fetch_history_watching();
  }), [player, classId, continue_watching, dispatch, lessonNum,
    lesson_data.chefName, lesson_data.title, lesson_id]);
  const subtitles_get = useCallback(()=>eserf(function* _subtitles_get(){
    let res = yield back_app.subtitles_get(classId, lesson_id);
    if (res.err)
      return;
    subtitles_set(res);
  }), [classId, lesson_id]);
  const available_lessons_get = useCallback(()=>eserf(function*
  _available_lessons_get(){
    let res = yield back_app.class_data_get(classId);
    if (res.err)
      return;
    let user_perms = userMetadata.role || 'public';
    let perms_hierarchy = {public: 0, beta: 1, admin: 2};
    let lessons = res.lessons || [];
    if (user_perms == 'admin')
      return available_lessons_set(lessons);
    lessons = lessons.filter(lesson=>{
      return !lesson.isHidden
      && perms_hierarchy[lesson.permissions] <= perms_hierarchy[user_perms];
    });
    available_lessons_set(lessons);
  }), [classId, userMetadata.role]);
  useEffect(()=>{
    subtitles_get();
    available_lessons_get();
  }, [subtitles_get, available_lessons_get]);
  useEffect(()=>{
    lesson_data_load(lesson_id);
    document.body.classList.add('no-scroll-item');
    tabs.current = [];
  }, [classId, lessonNum, userMetadata, lesson_data_load, lesson_clean,
    lesson_id]);
  useEffect(()=>{
    return ()=>{
      fetch_history_watching();
      lesson_clean();
      document.body.classList.remove('no-scroll-item');
    };
  }, [lesson_data]);
  const on_switch = (...args)=>{
    let positionScroll = JSON.parse(window.localStorage.getItem('positionScroll'));
    if (args[1] && args[1] == 'end')
    {
      if (positionScroll && positionScroll[Math.round(args[0])])
      {
        setTimeout(()=>{
          tabs.current[Math.round(args[0])].parentNode.scrollTop = positionScroll[Math.round(args[0])];
        }, 100);
        tabs.current[Math.round(args[0])].parentNode.scrollTo(0, positionScroll[Math.round(args[0])]);
      }
    }
  };
  const [in_view, in_view_set] = useState(true);
  useEffect(()=>{
    if (!item_slider.legnth)
      return;
    if (!ref_slider?.current)
      return;
    let screen = window.screen;
    if (screen.width < 960 && screen.width < screen.height
      || screen.height < 480 && screen.height < screen.width)
    {
      if (in_view)
      {
        if (ref_slider.current.classList.contains('hide-Slider'))
        {
          ref_slider.current.classList.remove('hide-Slider');
          setTimeout(()=>{
            ref_slider.current.classList.remove('visually-Slider');
          }, 20);
        }
        return;
      }
      ref_slider.current.classList.add('visually-Slider');
      ref_slider.current.addEventListener('transitionend', ()=>{
        ref_slider.current.classList.add('hide-Slider');
      }, {
        capture: false,
        once: true,
        passive: false
      });
    }
  }, [in_view, item_slider.length]);
  const calcTimes = data=>{
    const {total, handsOn} = data.times;
    if (!total && !handsOn)
    {
      hands_on_text_set(null);
      total_text_set(null);
    }
    else
    {
      hands_on_text_set(convertTime(Number(handsOn) || 0));
      total_text_set(convertTime(Number(total) || 0));
    }
  };
  const lesson_clean = useCallback(()=>{
    window.localStorage.removeItem('suppliesTabs');
    window.localStorage.removeItem('stepTab');
    window.localStorage.removeItem('shortTabs');
    window.localStorage.removeItem('feedTabs');
    window.localStorage.removeItem('positionScroll');
  }, []);
  const step2seconds = step=>{
    let time = step.split(':');
    return parseInt(time[1], 10) * 60 + parseInt(time[2], 10);
  };
  const slider_data_set = (user_perms, data)=>{
    let slider_items = data
      .map(item=>({
        id: item.id,
        content: item.title,
        perm: item.permission,
        start: item.startTime,
        image_url:
          `${config_ext.general.mediaAssetsUrl}/${item.imageRelativePath}`,
        fill: default_img,
        seconds: step2seconds(item.startTime),
      })).filter(item=>{
        switch (item.perm)
        {
        case 'public':
          return true;
        case 'admin':
          return user_perms=='admin';
        case 'beta':
          return user_perms!='public'; // beta and admin dont filter
        default:
          metric.error_once('invalid_perm', item.perm, user_perms);
        }
        return false;
      });
    item_slider_set(slider_items);
  };

  let lesson_data_load = useCallback(lesson_id=>eserf(function*
  _lesson_data_load(){
    let user_perms = userMetadata.role ? userMetadata.role : 'public';
    let data = yield back_app.lesson_get(classId, lesson_id,
      current_user?.email);
    if (data.err)
      {
         history.push(xurl.url('/home', qs_o));
        window.location.reload();
        return
    }
    if (lessonNum > 0 && data.displayGroups.length)
      slider_data_set(user_perms, data.displayGroups[0].displayGroupItems);
    let new_pdf =
      `${config_ext.general.mediaAssetsUrl}/${data.pdfRelativePath}`;
    let is_pdf_exist = yield back_app.file_exists(new_pdf);
    if (is_pdf_exist)
      pdf_url_set(new_pdf);
    if (user_perms == 'beta' && data.permissions == 'admin'
      || user_perms == 'public'
      && (data.permissions == 'admin' || data.permissions == 'beta'))
    {
      history.push(xurl.url(`/class/${classId}`, qs_o));
    }
    lesson_data_set(data);
    calcTimes(data);
    watch_history.get_last_recorded_time(lesson_id);
    loading_set(false);
    lesson_clean();
    let tabsFiltered = default_tabs_data.filter(({dataValue})=>{
      if (dataValue != 'feedback')
      {
        if (typeof data.tabs != 'undefined' && data.tabs.hasOwnProperty(dataValue))
        {
          if (
            (user_perms == 'public' && data.tabs[dataValue] != 'public') ||
            (user_perms == 'beta' && ['beta', 'public'].indexOf(data.tabs[dataValue])) < 0
          ) {
            return false;
          }
        }
        if (
          (data.tabs && Object.keys(data.tabs).length <= 0) ||
          (dataValue != 'overview' && (!data[dataValue] || data[dataValue].length <= 0))
        ) {
          return false;
        }
      }
      return true;
    })
      .sort(({order: orderA}, {order: orderB})=>orderA - orderB);
    tabs_data_set(tabsFiltered);
  }), [userMetadata, classId, lesson_clean, history]);
  const change_handle = (event, value)=>{
    const position_scroll = JSON.parse(window.localStorage.getItem('positionScroll'));
    if (position_scroll && position_scroll[value])
    {
      setTimeout(()=>{
        tabs.current[value].parentNode.scrollTop = position_scroll[value];
      }, 100);
      tabs.current[value].parentNode.scrollTo(0, position_scroll[value]);
    }
    tab_value_set(value);
  };
  const change_index_handle = index=>tab_value_set(index);
  const on_play = ()=>{
    history_data_update();
  };
  const feedback_submit_handle = useCallback(()=>{
    let is_feed_popup = window.localStorage.getItem('feedPopup');
    if (
      userMetadata &&
      (typeof userMetadata.is_feed_popup == 'undefined' || userMetadata.is_feed_popup) &&
      is_feed_popup == null
    )
      feed_popup_set(true);
  }, [userMetadata]);
  const is_first_lesson = useCallback(()=>lessonNum > 0, [lessonNum]);
  const is_last_lesson = useCallback(()=>{
    return lessonNum < available_lessons.length;
  }, [available_lessons, lessonNum]);
  const next_lesson_id_get_by_order = useCallback(lesson_index=>{
    let ordered_lessons = available_lessons.sort((a, b)=>parseInt(a.order, 10) - parseInt(b.order, 10));
    let old_index = ordered_lessons.findIndex(x=>parseInt(x.id.slice(-2), 10) == lesson_index);
    if (old_index == ordered_lessons.length - 1)
      return parseInt(ordered_lessons[0].id.slice(-2), 10);
    return parseInt(ordered_lessons[old_index + 1].id.slice(-2), 10);
  }, [available_lessons]);
  const prev_lesson_id_get_by_order = useCallback(lesson_index=>{
    let ordered_lessons = available_lessons.sort((a, b)=>parseInt(a.order, 10) - parseInt(b.order, 10));
    let old_index = ordered_lessons.findIndex(x=>parseInt(x.id.slice(-2), 10) == lesson_index);
    if (old_index == 0)
      return parseInt(ordered_lessons[ordered_lessons.length - 1].id.slice(-2), 10);
    return parseInt(ordered_lessons[old_index - 1].id.slice(-2), 10);
  }, [available_lessons]);
  const handle_before = useCallback(()=>{
    let prevLessonId = prev_lesson_id_get_by_order(lessonNum);
    if (prevLessonId == lessonNum)
      return;
    history.push(xurl.url(`/class/${classId}/lesson/${prevLessonId}`, qs_o));
    tab_value_set(0);
  }, [classId, history, lessonNum, prev_lesson_id_get_by_order]);
  const handler_after = useCallback(()=>{
    if (util.should_open_gift_dialog(userMetadata, qs_o))
      return gift_popup_open();
    let nextLessonId = next_lesson_id_get_by_order(lessonNum);
    if (nextLessonId == lessonNum)
      return;
    history.push(xurl.url(`/class/${classId}/lesson/${nextLessonId}`, qs_o));
    tab_value_set(0);
  }, [userMetadata, classId, gift_popup_open, history, lessonNum,
    next_lesson_id_get_by_order]);
  const gift_popup_open = useCallback(()=>{
    dispatch(gift_popup_set(true));
  }, [dispatch]);
  const play_first_time_handle = async (classIdx, lesson_id)=>{
    let video_once = window.localStorage.getItem(`${classIdx}_${lesson_id}_video`);
    if (video_once == null)
    {
      window.localStorage.setItem(`${classId}_${lesson_id}_video`, 1);
      let activeTabIndex = tabs_data.findIndex(tab=>tab.dataValue == 'steps');
      if (activeTabIndex < 0)
        activeTabIndex = tabs_data.findIndex(tab=>tab.dataValue == 'feedback');
      activeTabIndex = tabs_data && tabs_data.length > 1 ? activeTabIndex : 0;
      tab_value_set(activeTabIndex);
    }
  };
  const finished = e=>{
    let tab_index = tabs_data.findIndex(tab=>tab.dataValue == 'feedback');
    tab_index = tabs_data && tabs_data.length > 1 ? tab_index : 0;
    tab_value_set(tab_index);
  };
  const popup_feedback_handle = ()=>eserf(function* _popup_feedback_handle(){
    yield back_app.feed_popup_set(false);
    feed_popup_set(false);
    window.localStorage.setItem('feedPopup', 1);
  });
  const tabs_component = useMemo(
    ()=>(
      <Tabs
        value={tab_value}
        onChange={change_handle}
        indicatorColor="primary"
        classes={{root: styles.tabsRoot, scrollButtons: styles.scrollButtons}}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="scrollable auto tabs example"
      >
        {tabs_data.map(({label, dataName}, index)=>{
          let isHidden = dataName ? !(lesson_data[dataName] && lesson_data[dataName].length) : false;
          if (isHidden)
            return null;
          let tabClass = '';
          if (lesson_data && lesson_data.tabs && lesson_data.tabs[dataName] &&
            ['public', 'beta'].indexOf(lesson_data.tabs[dataName]) < 0
          ) {
            let userPerms = userMetadata.role && userMetadata.role != '' ?
              userMetadata.role : 'public';
            tabClass = userPerms == 'admin' ? 'yc-admin-only-color' : '';
          }
          return (
            <Tab
              id={label}
              key={index}
              className={tabClass}
              value={index}
              classes={{root: styles.tabRoot}}
              label={label}
            />
          );
        })}
      </Tabs>
    ),
    [lesson_data, tab_value, tabs_data, styles.scrollButtons, styles.tabRoot,
      styles.tabsRoot, userMetadata.role],
  );
  let feed_popup_title_first_name = userMetadata && userMetadata.first_name ?
    `Thank you, ${textCapitalize(userMetadata.first_name)}`
    : 'Thank you';
  const Steps = useMemo(()=>{
    if (!lesson_data.steps)
      return null;
    return <Step_card
      items={lesson_data.steps}
      player={player}
    />;
  }, [lesson_data.steps, player]);
  const Supplies = useMemo(()=>{
    if (!lesson_data.supplies)
      return null;
    return lesson_data.supplies.map((seg, index)=><Ingredients_section
      key={index}
      id={`supplies-${index}`}
      supplies={seg}
      isExpanded={index == 0}
    />);
  }, [lesson_data]);
  const Short_hand = useMemo(()=>lesson_data.shorthand && <React.Fragment>
    {pdf_url && <Pdf_button href={pdf_url} chef_name={lesson_data.chefName} />}
    {lesson_data.shorthand.map((section, index)=><Shorthand_desc
      key={index}
      section={section || {}}
      isExpanded={index == 0}
    />)}
  </React.Fragment>, [lesson_data, pdf_url]);
  const need_to_show_details = useCallback(()=>{
    if (!lesson_data)
      return false;
    return lesson_data.techniques.length && lesson_data.techniques[0]
      || lesson_data.cuisine.length && lesson_data.cuisine[0]
      || lesson_data.dietary.length && lesson_data.dietary[0];
  }, [lesson_data]);
  let [is_about_open, is_about_open_set] = useState(true);
  let [is_details_open, is_details_open_set] = useState(true);
  let [is_review_open, is_review_open_set] = useState(true);
  let [scroll_overview, scroll_overview_set] = useState(false);
  let parent_Overview = document.getElementsByClassName('Overview')[0]?.parentNode;
  let position_scroll_overview = useRef(0);
  useEffect(()=>{
    if (parent_Overview)
    {
      parent_Overview.addEventListener('scroll', ()=>{
        scroll_overview_set(true);
      });
    }
  });
  useEffect(()=>{
    let interval = setInterval(()=>{
      if (scroll_overview)
      {
        if (parent_Overview?.scrollTop > 10)
        {
          in_view_set(false);
          position_scroll_overview.current = parent_Overview?.scrollTop;
        }
        if (parent_Overview?.scrollTop <= 10)
        {
          in_view_set(true);
          position_scroll_overview.current = parent_Overview?.scrollTop;
        }
        scroll_overview_set(false);
      }
    }, 500);
    return ()=>{
      clearInterval(interval);
    };
  }, [scroll_overview]);
  let [scroll_recipe, scroll_recipe_set] = useState(false);
  let parent_Recipe = document.getElementById('Recipe')?.parentNode;
  let positionScrollRecipe = useRef(0);
  useEffect(()=>{
    if (parent_Recipe)
    {
      parent_Recipe.addEventListener('scroll', ()=>{
        scroll_recipe_set(true);
      });
    }
  });
  useEffect(()=>{
    let interval = setInterval(()=>{
      if (scroll_recipe)
      {
        if (parent_Recipe?.scrollTop > 10)
        {
          in_view_set(false);
          positionScrollRecipe.current = parent_Recipe?.scrollTop;
        }
        if (parent_Recipe?.scrollTop <= 10)
        {
          in_view_set(true);
          positionScrollRecipe.current = parent_Recipe?.scrollTop;
        }
        scroll_recipe_set(false);
      }
    }, 500);
    return ()=>{
      clearInterval(interval);
    };
  }, [scroll_recipe]);
  let [scroll_ingredients, scroll_ingredients_set] = useState(false);
  let parent_Ingredients = document.getElementById('Ingredients')?.parentNode;
  let positionScrollIngredient = useRef(0);
  useEffect(()=>{
    if (parent_Ingredients)
    {
      parent_Ingredients.addEventListener('scroll', ()=>{
        scroll_ingredients_set(true);
      });
    }
  });
  useEffect(()=>{
    let interval = setInterval(()=>{
      if (scroll_ingredients)
      {
        if (parent_Ingredients?.scrollTop > 10)
        {
          in_view_set(false);
          positionScrollIngredient.current = parent_Ingredients?.scrollTop;
        }
        if (parent_Ingredients?.scrollTop <= 10)
        {
          in_view_set(true);
          positionScrollIngredient.current = parent_Ingredients?.scrollTop;
        }
        scroll_ingredients_set(false);
      }
    }, 500);
    return ()=>clearInterval(interval);
  }, [scroll_ingredients]);
  useEffect(()=>{
    const button_overview = document.getElementById('OVERVIEW');
    if (!button_overview)
      return;
    button_overview.addEventListener('click', ()=>{
      if (scroll_recipe)
        scroll_recipe_set(false);
      if (scroll_ingredients)
        scroll_ingredients_set(false);
      if (position_scroll_overview.current > 10)
        in_view_set(false);
      else if (position_scroll_overview.current <= 10)
        in_view_set(true);
    });
  });
  useEffect(()=>{
    let button_recipe = document.getElementById('RECIPE');
    if (!button_recipe)
      return;
    button_recipe.addEventListener('click', ()=>{
      if (scroll_overview)
        scroll_overview_set(false);
      if (scroll_ingredients)
        scroll_ingredients_set(false);
      if (positionScrollRecipe.current > 10)
        in_view_set(false);
      else if (positionScrollRecipe.current <= 10)
        in_view_set(true);
    });
  });
  useEffect(()=>{
    let button_ingredients = document.getElementById('INGREDIENTS');
    if (!button_ingredients)
      return;
    button_ingredients.addEventListener('click', ()=>{
      if (scroll_overview)
        scroll_overview_set(false);
      if (scroll_recipe)
        scroll_recipe_set(false);
      if (positionScrollIngredient.current > 10)
        in_view_set(false);
      else if (positionScrollIngredient.current <= 10)
        in_view_set(true);
    });
  });
  useEffect(()=>{
    let button_steps = document.getElementById('STEPS');
    if (!button_steps)
      return;
    button_steps.addEventListener('click', ()=>in_view_set(true));
  });
  useEffect(()=>{
    let button_feedback = document.getElementById('FEEDBACK');
    if (!button_feedback)
      return;
    button_feedback.addEventListener('click', ()=>in_view_set(true));
  });
  const tabs_render = useCallback(()=>{
    let swipe_views = tabs_data.map(({dataValue})=>{
      if (dataValue == 'overview')
      {
        return (
          <div
            key="overview"
            className="scrollableItems subTabsCon Overview"
          >
            {!!pdf_url && <Pdf_button
              href={pdf_url}
              chef_name={lesson_data.chefName}
            />}
            <Accordion
              expanded={is_about_open}
              onChange={(e, expanded)=>is_about_open_set(expanded)}
            >
              <AccordionSummary
                expandIcon={<Expand_more_icon />}
                aria-controls={`panel${0}--overview-header`}
                id={`panel${0}--overview-header`}
              >
                About
              </AccordionSummary>
              {lesson_data.description && <AccordionDetails>
                <div className={styles.contentCon}>
                  <h2>{lesson_data.title}</h2>
                  <p className="body-text">{lesson_data.description}</p>
                </div>
              </AccordionDetails>}
            </Accordion>
            {!!need_to_show_details() &&
              <Accordion
                expanded={is_details_open}
                onChange={(e, expanded)=>is_details_open_set(expanded)}
              >
                <AccordionSummary
                  expandIcon={<Expand_more_icon />}
                  aria-controls={`panel${0}-overview-header`}
                  id={`panel${1}-overview-header`}
                >
                  Details
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.iconsCon}>
                    <div>
                      {hands_on_text && total_text && <React.Fragment>
                        <h2 style={{paddingRight: '1.2rem'}}>time</h2>
                        <p className="body-text">
                          Hands-on: {hands_on_text} | Total: {total_text}
                        </p>
                      </React.Fragment>}
                    </div>
                    <Overview_data name="serves"
                      data={lesson_data.servingSize}
                    />
                    <Overview_data name="techniques"
                      data={lesson_data.techniques}
                    />
                    <Overview_data name="cuisine"
                      data={lesson_data.cuisine}
                    />
                    <Overview_data name="dietary"
                      data={lesson_data.dietary}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>}
            {!!lesson_data.studentsReviews?.length &&
              <Accordion
                expanded={is_review_open}
                onChange={(e, expanded)=>is_review_open_set(expanded)}>
                <AccordionSummary
                  expandIcon={<Expand_more_icon />}
                  aria-controls={`panel${0}-overview-header`}
                  id={`panel${1}-overview-header`}
                >
                  What students are saying
                </AccordionSummary>
                <AccordionDetails>
                  <div className={styles.iconsCon}>
                    {lesson_data.studentsReviews.map((student_review, index)=>(
                      <React.Fragment key={index}>
                        <div style={{fontSize: '16px'}}>
                          {`"${student_review.quote}"`}
                        </div>
                        <div style={{margin: '24px 0 0 0', display: 'flex', flexDirection: 'row', fontSize: '16px'}}>
                          <div style={{fontWeight: 600, marginRight: '3px'}}>
                            {student_review.name_short}
                          </div>
                          {student_review.location ? `| ${student_review.location}` : ''}
                        </div>
                        {index < lesson_data.studentsReviews.length - 1 && <div style={{border: 'solid 0.5px rgba(255, 255, 255, 0.3)', margin: '20px 0 24px 0', borderBottom: 'none'}}></div>}
                      </React.Fragment>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>}
          </div>
        );
      }
      if (dataValue == 'steps')
      {
        return (
          <div
            key="steps"
            className="scrollableItems subTabsCon subTabsCon-steps"
          >
            {Steps}
          </div>
        );
      }
      if (dataValue == 'supplies')
      {
        return (
          <div
            key="supplies"
            id="Ingredients"
            className="scrollableItems subTabsCon"
          >
            {Supplies}
          </div>
        );
      }
      if (dataValue == 'shorthand')
      {
        return (
          <div
            key="shorthand"
            id="Recipe"
            className="scrollableItems subTabsCon"
          >
            {Short_hand}
          </div>
        );
      }
      if (dataValue == 'feedback')
      {
        return (
          <div
            key="feedback"
            className="scrollableItems subTabsCon"
          >
            <Feedback
              class_id={classId}
              lesson_id={lesson_data.id}
              lesson_num={lesson_data.id}
              lesson_title={lesson_data.title}
              on_feedback_submit={feedback_submit_handle}
              player={player}
            />
          </div>
        );
      }
      return null;
    });
    return swipe_views;
  }, [Short_hand, Steps, Supplies, classId,
    feedback_submit_handle, hands_on_text, is_about_open,
    is_details_open, is_review_open, lesson_data, need_to_show_details,
    pdf_url, player, styles.contentCon, styles.iconsCon, tabs_data,
    total_text]);
  if (loading)
    return <Loader />;
  return (
    <React.Fragment>
      <div className="popup-styles">
        <Popup
          displayName={false}
          onClose={popup_feedback_handle}
          onSubmit={popup_feedback_handle}
          title={feed_popup_title_first_name}
          isOpen={feed_popup}
          message={[
            'We really appreciate you giving us feedback, and would love to get more.',
            <React.Fragment key="0">
              <br />
              <br />
            </React.Fragment>,
            'Thanks for helping us make YES',
            <span key="1" style={{fontWeight: 600}}>
              CHEF
            </span>,
            ' better!',
          ]}
          buttonText={'Continue Watching'}
        />
      </div>
      <div className="yc-main yc-main-lesson">
        <Header go_back={`/class/${classId}`} />
        <div className="yc-body">
          <Box className="container1">
            <Paper className="container2">
              <div id="hero-video">
                <Video_player
                  account_id={config_ext.player.account_id}
                  chefName={lesson_data.chefName}
                  classId={classId}
                  lesson_id={lesson_id}
                  continue_watching={continue_watching}
                  finished={e=>finished(e)}
                  next_lesson_play={handler_after}
                  previous_lesson_play={handle_before}
                  handlePlay={play_first_time_handle}
                  isFirstLesson={is_first_lesson}
                  isLastLesson={is_last_lesson}
                  lessonNum={lessonNum}
                  lessonTitle={lesson_data.title}
                  onPlay={on_play}
                  pausedVideo={history_data_update}
                  player_id={config_ext.player.player_id}
                  subtitles={subtitles}
                  video_id={lesson_data.brightcoveVideoId}
                  on_init={player_set}
                />
              </div>
              {!!item_slider.length && <div
                className="Display-Container-Slider"
                ref={ref_slider}
              >
                <Display_group data={item_slider} player={player} />
              </div>}
            </Paper>
            <div className="lessonContentCon">
              {tabs_component}
              <Swipeable_views
                axis="x"
                className="swipeableViews"
                index={tab_value}
                onChangeIndex={change_index_handle}
                onSwitching={on_switch}
              >
                {tabs_render()}
              </Swipeable_views>
            </div>
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
};

export default auth.with_auth(E);
