// LICENSE_CODE YC
import React, {Suspense, useCallback,
  useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import Swipeableviews from 'react-swipeable-views';
import {Element, animateScroll as scroller} from 'react-scroll';
import Lazy_load from 'react-lazy-load';
import Loader from './loader';
import instagram_icon from './assets/images/instagram-icon.svg';
import facebook_icon from './assets/images/facebook-icon.svg';
import twitter_icon from './assets/images/twitter-icon.svg';
import Class_info from './class_info';
import Header from './header.js';
import auth from './auth.js';
import watch_history from './watch_history.js';
import back_app from './back_app.js';
import eserf from '../../../util/eserf.js';
import ereq from '../../../util/ereq.js';
import xurl from '../../../util/xurl.js';
import './class.scss';
import {Col, Row, Tabs, Button, Typography, Space, Divider, Image,
  Progress} from 'antd';
import Icon, {ClockCircleFilled, PlayCircleFilled, CheckCircleFilled}
  from '@ant-design/icons';
import config_ext from './config_ext.js';
import util from './util.js';

let {Paragraph, Title} = Typography;

let Check_icon = ()=><svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.7778 16.4812L0.510626 10.114C-0.170209 9.42227
 -0.170209 8.3049 0.510626 7.6132C1.19146 6.92149 2.29127 6.92149
 2.97211 7.6132L8.01727 12.7212L20.0279 0.518779C20.7087 -0.172926
 21.8085 -0.172926 22.4894 0.518779C23.1702 1.21049 23.1702 2.32786
 22.4894 3.01956L9.23928 16.4812C8.5759 17.1729 7.45863 17.1729 6.7778
 16.4812Z" fill="white" fillOpacity="0.7"/>
</svg>;

let Behind_scene = ({section})=>{
  if (!section)
    return null;
  return (
    <Row gutter={[0, 24]}>
      {section.map(item=><Col span={24} key={item.id}>
        {item.type == 'image' && <>
          <Lazy_load>
            <Image preview={false} src={item.src} alt="pic" width="100%" />
          </Lazy_load>
          {!!item.description && <Paragraph>
            {item.description}
          </Paragraph>}
        </>}
      </Col>)}
    </Row>
  );
};

let Lesson_info = ({user_full, cur_progress, lesson_data})=>{
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let [cover_img_url, cover_img_url_set] = useState('');
  let [loading, loading_set] = useState(true);
  let match = useRouteMatch();
  let history = useHistory();
  const img_check = useCallback(img_url=>eserf(function* _img_check(){
    let res = yield ereq.get(img_url);
    let img = `${config_ext.general.mediaAssetsUrl}/LessonFallback.jpg`;
    if (res.status==200)
      img = img_url;
    cover_img_url_set(img);
    loading_set(false);
  }), []);
  useEffect(()=>{
    img_check(`${config_ext.general.mediaAssetsUrl}/`
    +`${match.params.id}/${lesson_data.id}/cover.jpg`);
  }, [match, lesson_data, img_check]);
  const click_handle = useCallback(()=>{
    if (lesson_data.is_coming_soon && user_full.role!='admin')
      return;
    let real_lesson_num = parseInt(lesson_data.id.split(/_l|_s/)[1], 10);
    let href = `/class/${match.params.id}/lesson/${real_lesson_num}`;
    util.gtm_push({event: 'customPageview', pagePath: href});
    history.push(xurl.url(href, qs_o));
  }, [history, lesson_data, match, user_full]);
  const current_progress_get = useMemo(()=>{
    if (lesson_data.duration >= cur_progress)
      return 100 * cur_progress / lesson_data.duration;
    return 0;
  }, [lesson_data, cur_progress]);
  const duration2text = useMemo(()=>{
    let hours = Math.floor(lesson_data.duration / 3600);
    let duration_mod = lesson_data.duration % 3600;
    let minutes = Math.floor(duration_mod / 60);
    let sec = Math.round(duration_mod % 60);
    let duratio_as_text = hours ? `${hours}:` : '';
    duratio_as_text +=
    `${minutes < 10 ? '0' : ''}${minutes}:${sec < 10 ? '0' : ''}${sec}`;
    return duratio_as_text;
  }, [lesson_data]);
  return (
    <Row style={{marginBottom: 30,
      cursor: !lesson_data.is_coming_soon ? 'pointer' : 'auto'}}
    onClick={click_handle}>
      <Col span={24}>
        {!loading
          ?
          <div style={{width: '100%', marginBottom: '1.2rem'}}>
            <div style={{position: 'relative', paddingTop: '56.25%',
              overflow: 'hidden', borderRadius: 10}}>
              <img
                src={cover_img_url}
                alt="Lesson Cover"
                style={{position: 'absolute', objectFit: 'cover',
                  width: '100%', height: '100%', left: 0, top: 0,
                  opacity: lesson_data.is_coming_soon ? 0.8 : 1,
                  filter: 'none', borderRadius: 10}}
              />
              {current_progress_get >= 95 &&
                <Icon component={Check_icon}
                  style={{position: 'absolute', top: 10, right: 10,
                    fontSize: 15}} /> }
              {!lesson_data.is_coming_soon && current_progress_get != 0 &&
                <div style={{width: '100%', bottom: '-15px',
                  position: 'absolute'}}>
                  <Progress style={{borderRadius: 5}}
                    percent={current_progress_get} showInfo={false}
                    type="line" strokeColor="#CF6679" trailColor="#FFFFFF"
                    strokeLinecap="square"/>
                </div>
              }
            </div>
          </div>
          :
          <div style={{width: '100%', height: 100, marginRight: '1.2rem'}}>
            <Loader />
          </div>
        }
        {!lesson_data.is_coming_soon &&
          <div style={{position: 'absolute', bottom: '3rem', right: '1rem',
            backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '0.5rem'}}>
            {duration2text}
          </div>}
        {lesson_data.is_coming_soon &&
          <div style={{position: 'absolute', top: '1rem', left: '1rem'}}>
            <Button style={{borderRadius: '100px', borderColor: '#FEFEFE',
              backgroundColor: '#FEFEFE', color: '#000', fontSize: '12px',
              paddingLeft: '10px', height: 'unset', paddingRight: '10px',
              cursor: 'auto'}}>
              COMING SOON
            </Button>
          </div>
        }
      </Col>
      <Col span={24}>
        <Title level={5}
          style={{color: lesson_data.is_coming_soon ? '#cccccc' : '#ffffff'}}
        >{lesson_data.title}</Title>
      </Col>
    </Row>
  );
};

let tabs_data = [
  {label: 'CLASS', key: 0},
  {label: 'ABOUT', key: 1},
  {label: 'BEHIND THE SCENES', key: 2},
];

const E = props=>{
  let location = useLocation();
  let history = useHistory();
  let qs_o = xurl.qs_parse(location.search, true);
  let [loading, set_loading] = useState(true);
  let [chefs_data, set_chefs_data] = useState({
    lessons: [],
    skills: [],
    skill_n: 0,
    lesson_n: 0,
    social: {facebook: undefined, instagram: undefined, twitter: undefined},
  });
  let [coming_soon_lessons, set_coming_soon_lessons] = useState([]);
  let [lessons, set_lessons] = useState([]);
  let [behind_scene_content, set_behind_scene_content] = useState([]);
  let [value, set_value] = useState(0);
  let [total_duration, set_total_duration] = useState(0);
  let [cur_progresses, set_cur_progresses] = useState(undefined);
  let {user_full} = auth.use_auth();
  let {match: {params: {id: classId}}} = props;
  let [continue_watching, set_continue_watching] =
    useState(props.continueWatching);
  useEffect(()=>{
    window.scrollTo(0, 0);
    eserf(function* chef_home_page_init(){
      let _lesson_num = watch_history.get_last_lesson_num(classId);
      if (_lesson_num)
        set_continue_watching(_lesson_num);
      let bts_list = yield back_app.behind_the_scenes_images_list(classId);
      set_behind_scene_content(bts_list);
      if (qs_o.dbg_public)
        user_full.role = 'public';
      let user_perms = user_full.role && user_full.role !== '' ? user_full.role
        : 'public';
      let classes = yield back_app.class_data_get(classId, true);
      if (classes.err){
        history.push(xurl.url('/home', qs_o));
        window.location.reload();
        return
      }
      if (user_perms == 'public' && classes.permissions == 'admin')
        {
          history.push(xurl.url('/home', qs_o));
          window.location.reload();
          return
        }
      let all_lessons = classes.lessons;
      if (user_perms != 'admin')
        all_lessons = all_lessons.filter(lesson_obj=>!lesson_obj.isHidden);
      let available_lessons = all_lessons.filter(
        lesson=>lesson && !lesson.is_coming_soon
      );
      let _coming_soon_lessons = all_lessons.filter(
        lesson=>lesson && lesson.is_coming_soon
      );
      let _cur_progresses = [];
      available_lessons.sort(natural_sort);
      _coming_soon_lessons.sort(natural_sort);
      for (let i = 0; i < available_lessons.length; i++)
      {
        let lesson_data = available_lessons[i];
        let recorded_time = watch_history.get_last_recorded_time(
          lesson_data.id);
        _cur_progresses[i] = recorded_time;
        available_lessons[i].orderNum = parseInt(lesson_data.order, 10);
      }
      _coming_soon_lessons.forEach((lesson_data, i)=>{
        _coming_soon_lessons[i].orderNum = parseInt(lesson_data.order, 10);
      });
      set_chefs_data(classes);
      set_lessons(available_lessons);
      set_coming_soon_lessons(_coming_soon_lessons);
      set_cur_progresses(_cur_progresses);
      let _class_hours = classes.class_hours;
      if (!_class_hours)
      {
        // XXX colin: calc on back_app
        _class_hours = available_lessons.map(
          lesson_data=>lesson_data.duration).reduce((a, b)=>a + b, 0);
        _class_hours = Math.round(_class_hours * 2 / 3600) / 2;
      }
      set_total_duration(_class_hours);
      set_loading(false);
      get_continue_watching_progress(
        props.continueWatching,
        available_lessons,
        props.match
      );
      scroll_to_lesson();
    });
  }, []);
  useEffect(()=>{
    if (props.continueWatching !== continue_watching)
    {
      get_continue_watching_progress(
        props.continueWatching, lessons, props.match);
    }
  }, [props.continueWatching]);

  const get_continue_watching_progress = (continue_watching, _lessons,
    match)=>{
    if (!continue_watching?.userProgress || !_lessons)
      return;
    _lessons.forEach((lesson_data, i)=>{
      const real_lesson_num = parseInt(lesson_data.id.split(/_l|_s/)[1], 10);
      if (continue_watching.userProgress[
        `${match.params.id}_${real_lesson_num}`])
      {
        cur_progresses[i] = _continue_watching.userProgress[
          `${match.params.id}_${
            real_lesson_num > 0 ? real_lesson_num : `0${real_lesson_num}`
          }`
        ];
      }
    });
    set_cur_progresses(cur_progresses);
  };
  const natural_sort = (a, b)=>{
    let NUMBER_GROUPS = /(-?\d*\.?\d+)/g;
    let aa = String(a.order).split(NUMBER_GROUPS);
    let bb = String(b.order).split(NUMBER_GROUPS);
    let min = Math.min(aa.length, bb.length);
    for (let i = 0; i < min; i++)
    {
      let x = parseFloat(aa[i]) || aa[i].toLowerCase();
      let y = parseFloat(bb[i]) || bb[i].toLowerCase();
      if (x < y)
        return -1;
      if (x > y)
        return 1;
    }
    return 0;
  };
  const change_handle = _value=>{
    set_value(_value);
    if (_value == 1 || _value == 2)
      scroller.scrollTo({duration: 0, delay: 0, smooth: 'false', offset: 0});
  };
  const change_index_handle = i=>{
    set_value(i);
    if (i == 1 || i == 2)
      scroller.scrollTo({duration: 0, delay: 0, smooth: 'false', offset: 0});
  };
  const scroll_to_lesson = ()=>{
    if (props.location.state)
    {
      scroller.scrollTo(`lessonNum${props.location.state}`, {
        duration: 0,
        delay: 0,
        smooth: 'false',
        offset: -400,
      });
    }
  };
  const Class_tabs = ()=>{
    return (
      <Row>
        <Col span={24}>
          <Tabs
            className="class-tabs"
            centered
            moreIcon={null}
            items={tabs_data}
            activeKey={value}
            onChange={change_handle}
            tabBarStyle={{fontWeight: 600}}
          />
        </Col>
      </Row>
    );
  };

  if (loading)
    return <Loader />;
  return (
    <div className="yc-main">
      <Header go_back="/home" />
      <Row justify="center">
        <Col flex="auto" sm={24} md={22} lg={14}>
          <Class_info {...chefs_data}
            clickable={false}
          />
          <Class_tabs />
          <Row>
            <Swipeableviews
              style={{flex: 1}}
              index={value}
              onChangeIndex={change_index_handle}
            >
              <Row style={{display: value !== 0 && 'none'}}>
                <Col>
                  {!chefs_data.is_coming_soon && <Row justify="center">
                    <Col span={7}>
                      <Row justify="center">
                        <Space>
                          <ClockCircleFilled
                            style={{
                              fontSize: '20px',
                              color: 'rgba(255, 255, 255, 0.7)',
                            }}
                          />
                          <span style={{fontWeight: 300}}>
                            {total_duration} hours
                          </span>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={1}>
                      <Divider style={{height: '100%'}} type="vertical" />
                    </Col>
                    <Col
                      span={7}
                    >
                      <Row justify="center">
                        <Space>
                          <PlayCircleFilled
                            style={{
                              fontSize: '20px',
                              color: 'rgba(255, 255, 255, 0.7)',
                            }}
                          />
                          <span style={{fontWeight: 300}}>
                            {chefs_data.lesson_n||(lessons.length
                              + coming_soon_lessons.length)}{' '}
                              lessons
                          </span>
                        </Space>
                      </Row>
                    </Col>
                    <Col span={1}>
                      <Divider style={{height: '100%'}} type="vertical" />
                    </Col>
                    <Col
                      span={7}
                    >
                      <Row justify="center">
                        <Space>
                          <CheckCircleFilled
                            style={{
                              fontSize: '20px',
                              color: 'rgba(255, 255, 255, 0.7)',
                            }}
                          />
                          <span style={{fontWeight: 300}}>
                            {chefs_data.skill_n} skills
                          </span>
                        </Space>
                      </Row>
                    </Col>
                  </Row>
                  }
                  <Row justify="center" style={{margin: '2rem 0'}}>
                    <Col xs={21} md={23}>
                      <Paragraph ellipsis={{rows: 6, expandable: true,
                        symbol: 'Read More'}}>
                        {chefs_data.description}
                      </Paragraph>
                      {!!lessons.length && <Row>
                        <Col span={24}>
                          <Title style={{marginBottom: 20}} level={4}>
                            LESSONS
                          </Title>
                          <Suspense fallback={<Loader />}>
                            <Row gutter={16}>
                              {lessons.map((lesson_data, i)=><Col
                                key={`lessonNum${i}`} xs={24} sm={12}
                                className={lesson_data.isHidden
                                ? 'yc-admin-only-color' : ''}>
                                <Element key={`lessonNum${i}`}
                                  name={`lessonNum${i + 1}`}>
                                  <Lesson_info user_full={user_full}
                                    lesson_data={lesson_data}
                                    cur_progress={cur_progresses
                                      ? cur_progresses[i] : 0}
                                    history={history} match={props.match}/>
                                </Element>
                              </Col>)}
                            </Row>
                          </Suspense>
                        </Col>
                      </Row>}
                      {!!coming_soon_lessons.length && <Row>
                        <Col span={24}>
                          <Title style={{marginBottom: 20}} level={4}>
                            COMING SOON
                          </Title>
                          <Suspense fallback={<Loader />}>
                            <Row gutter={16}>
                              {coming_soon_lessons.map((lesson_data, i)=><Col
                                key={`lessonNum${i}`} xs={24} sm={12}
                                className={lesson_data.isHidden
                                  ? 'yc-admin-only-color' : ''}>
                                <Element key={`lessonNum${i}`}
                                  name={`lessonNum${i + 1}`}>
                                  <Lesson_info user_full={user_full}
                                    lesson_data={lesson_data}
                                    cur_progress={cur_progresses
                                      ? cur_progresses[i] : 0}
                                    history={history} match={props.match}/>
                                </Element>
                              </Col>)}
                            </Row>
                          </Suspense>
                        </Col>
                      </Row>}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{display: value !== 1 && 'none'}}>
                <Col>
                  <Row justify="center">
                    <Col xs={21} md={23}>
                      <Paragraph ellipsis={{rows: 6, expandable: true,
                        symbol: 'Read More'}}>
                        {chefs_data.about}
                      </Paragraph>
                    </Col>
                  </Row>
                  <Row justify="center" style={{height: 60}}>
                    {chefs_data.social.twitter &&
                      <Col xs={4} sm={2}>
                        <Button
                          block
                          type="link"
                          onClick={()=>{
                            window.open(chefs_data.social.twitter, '_blank');
                          }}
                        >
                          <Image height={45} width={45} alt="Twitter"
                            aria-label="Twitter" preview={false}
                            src={twitter_icon} />
                        </Button>
                      </Col>
                    }
                    {chefs_data?.social?.instagram &&
                      <Col xs={4} sm={2}>
                        <Button
                          block
                          type="link"
                          onClick={()=>{
                            window.open(chefs_data.social.instagram, '_blank');
                          }}
                        >
                          <Image height={45} width={45} alt="Instagram"
                            aria-label="Instagram" preview={false}
                            src={instagram_icon} />
                        </Button>
                      </Col>
                    }
                    {chefs_data.social.facebook &&
                      <Col xs={4} sm={2}>
                        <Button
                          block
                          type="link"
                          onClick={()=>{
                            window.open(chefs_data.social.facebook, '_blank');
                          }}
                        >
                          <Image height={45} width={45} alt="Facebook"
                            aria-label="Facebook" preview={false}
                            src={facebook_icon} />
                        </Button>
                      </Col>
                    }
                  </Row>
                </Col>
              </Row>
              <Row justify="center" style={{display: value !== 2 && 'none'}}>
                {chefs_data.is_coming_soon && <Col>
                  <Title style={{marginBottom: 20}}
                    level={4}>COMING SOON</Title>
                </Col>
                }
                {!chefs_data.is_coming_soon &&
                  <Col span={24} >
                    <Suspense fallback={<Loader />}>
                      <Behind_scene section={behind_scene_content} />
                    </Suspense>
                  </Col>
                }
              </Row>
            </Swipeableviews>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default auth.with_auth(E);
