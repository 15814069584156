export const AccordionStyles = {
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      marginBottom: '2.3rem',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: '2.3rem',
    },
  },
  expanded: {},
};

export const AccordionSummaryStyles = {
  root: {
    backgroundColor: '#262626',
    marginBottom: -1,
    minHeight: 56,
    borderRadius: 6,
    '&$expanded': {
      minHeight: 56,
      borderRadius: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
    },
  },
  content: {
    fontWeight: 600,
    fontSize: 16,
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
};

export const AccordionDetailsStyles = (theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#1E1E1E',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
}));

const suppliesStyles = {
  titleStyle: {
    fontSize: 12,
    color: '#ffffff',
    fontWeight: 600,
  },
  detailStyle: {
    fontSize: 16,
    color: '#ffffff',
    margin: 0,
  },
  separator: {
    width: '100%',
    height: '1px',
    borderTop: '1px solid #ffffffaa',
  },
};

export default suppliesStyles;
