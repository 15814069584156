// LICENSE_CODE MIT
let E = {};
export default E;

E.qs = (qs_o, is_no_percent)=>{
  let ret = '';
  for (let k in qs_o)
  {
    let v = qs_o[k];
    if (v===undefined)
      continue;
    if (v!==null && typeof v=='object')
      v = JSON.stringify(v);
    ret += '&'+k+'='+(v===null ? ''
      : is_no_percent ? encodeURIComponent(v).replaceAll('%2B',
        '+').replaceAll('%40', '@').replaceAll('%2C', ',')
        : encodeURIComponent(v));
  }
  return ret.substr(1);
};

const revive = (k, v)=>{
  if (typeof v != 'string')
    return v;
  let _v = v.toLowerCase();
  if (_v=='true')
    return true;
  if (_v=='false')
    return false;
  return date_revive(k, v);
};

let date_revive = (k, v)=>{
  if (typeof v != 'string')
    return v;
  // paypal return dates create_time 2018-19-12T22:20:32.000Z which is invalid
  if (!(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/u).test(v))
    return v;
  let ret = new Date(v);
  if (isNaN(ret))
    console.error('invalid date', v);
  return isNaN(ret) ? v : ret;
};

E.json_parse = json=>{
  try { return JSON.parse(json, revive); }
  catch (e){}; // eslint-disable-line
};

E.qs_parse = (qs_s, has_question, is_crash_any_err, is_no_n, is_to_null)=>{
  let ret = {};
  let i = qs_s.indexOf('?');
  if (has_question && i>-1)
  {
    qs_s = qs_s.substr(i+1);
    i = qs_s.indexOf('?');
  }
  if (i>-1)
    return {};
  qs_s = qs_s.split(/[&]/u);
  for (let k of qs_s)
  {
    let s = k.split('=');
    if (!s.length)
      continue;
    if (s.length==1)
    {
      if (!s[0])
        continue;
      ret[s[0]] = true;
      continue;
    }
    if (s[1]=='')
    {
      ret[s[0]] = is_to_null ? null : undefined;
      continue;
    }
    if (s[1].toLowerCase()=='true')
    {
      ret[s[0]] = true;
      continue;
    }
    if (s[1].toLowerCase()=='false')
    {
      ret[s[0]] = false;
      continue;
    }
    let _s;
    try {
      _s = decodeURIComponent(s[1]);
    } catch(e) {
      if (is_crash_any_err)
        throw e;
      if (e instanceof URIError)
      {
        _s = s[1];
        ret[s[0]] = _s;
        continue;
      }
      else
        throw e;
    }
    let d = date_revive(null, _s);
    if (d instanceof Date)
    {
      ret[s[0]] = d;
      continue;
    }
    let j = E.json_parse(_s);
    if (j && isNaN(j))
    {
      ret[s[0]] = j;
      continue;
    }
    let res = isNaN(_s)||is_no_n ? _s : parseInt(_s, 10);
    if (res.toString()!=_s)
      res = _s;
    ret[s[0]] = res;
  }
  return ret;
};

E.qs_o_parse = qs_o=>E.qs_parse(E.qs(qs_o));
E.url2o = (url, is_no_n, is_to_null)=>{
  let i_qs = url.indexOf('?');
  let uri = url.substring(0, i_qs);
  let i_hs = url.indexOf('#');
  let qs_s = url.substring(i_qs+1, i_hs>-1 ? i_hs : undefined);
  let hs_s = url.substring(i_hs+1);
  let qs_o = {}, hs_o = {};
  if (i_qs>-1)
    qs_o = E.qs_parse(qs_s, undefined, undefined, is_no_n, is_to_null);
  if (i_hs>-1)
    hs_o = E.qs_parse(hs_s, undefined, undefined, is_no_n, is_to_null);
  return {uri, qs_o, hs_o};
};

E.url2qs_o = (url, is_no_n, is_to_null)=>E.url2o(url, is_no_n,
  is_to_null).qs_o;
E.url2hs_o = url=>E.url2o(url).hs_o;
E.url2uri = url=>E.url2o(url).uri;

E.url = (uri, qs_o, hs_o, is_no_percent)=>{
  let ret = '';
  let qs = E.qs(qs_o, is_no_percent);
  let hs = E.qs(hs_o, is_no_percent);
  if (qs)
    ret += '?'+qs;
  if (hs)
    ret += '#'+hs;
  return uri+ret;
};
