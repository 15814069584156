
export default (minutes => {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const minutesMod = minutes % 60;
    if (minutesMod > 0) {
      return `${hours} hrs ${minutesMod} min`;
    }
    return `${hours} hrs`;
  }
  return `${minutes} min`;
});
