// LICENSE_CODE MIT
/* eslint-disable */
export default {
  "sentry": {
    "front": {
      "dsn": "https://3572d208d7ad4289a2a24ceb9976c014@o389412.ingest.sentry.io/5227591",
      "is_perf": true
    }
  },
  "auth0": {},
  "intercom": {
    "app_id": "j4tr2hf6"
  },
  "stripe": {
    "pub_key": "pk_live_Z4WHgrjKPHsts6WxcbD2pGAF00NtGsL6WV"
  },
  "back": {
    "app": {
      "url": "https://api.yeschef.me"
    }
  },
  "fbase": {
    "apiKey": "AIzaSyDqcRUVV4YIz_rav26PwIbwmdb5-JJ2aG0",
    "databaseURL": "https://yeschef-7b155.firebaseio.com",
    "projectId": "yeschef-7b155",
    "storageBucket": "yeschef-7b155.appspot.com",
    "messagingSenderId": "230662376972",
    "authDomain": "auth.yeschef.me"
  },
  "wp": {
    "host": "yeschef.me",
    "url": "https://yeschef.me"
  },
  "player": {
    "account_id": "6244892581001",
    "player_id": "AcT3UtLcE"
  },
  "announcekit": {
    "url": "https://announcekit.app/widgets/v2/3ipF5K"
  },
  "front": {
    "is_chromecast": true,
    "is_chromecast_beta": true,
    "is_login_any": false,
    "media": {
      "url": "https://media-assets.yeschef.me"
    },
    "app": "https://app.yeschef.me",
    "enable_new_tab": false
  },
  "fire_base": {
    "apiKey": "AIzaSyDqcRUVV4YIz_rav26PwIbwmdb5-JJ2aG0",
    "databaseURL": "https://yeschef-7b155.firebaseio.com",
    "projectId": "yeschef-7b155",
    "storageBucket": "yeschef-7b155.appspot.com",
    "messagingSenderId": "230662376972",
    "authDomain": "auth.yeschef.me"
  },
  "general": {
    "mediaAssetsUrl": "https://media-assets.yeschef.me"
  },
  "domain": "yeschef.me",
  "app": "https://app.yeschef.me",
  "is_prod": true,
  "ver": "1.0.364",
  "is_local": false,
  "stage": "prod"
}