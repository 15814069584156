// LICENSE_CODE YC
export const writeCookie = (cname, cvalue, days) => {
  const dt = new Date();
  dt.setTime(dt.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `; expires=${dt.toGMTString()}`;
  document.cookie = `${cname}=${cvalue}${expires}; domain=yeschef.me; path=/`;
};

export const getCookie = cname=>{
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = cname=>{
  document.cookie = `${cname}=; `
    +'expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=yeschef.me; path=/;';
};

