import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/RootReducer';

const Store = preloadedState => {
  let composed;

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    composed = compose(
      // applyMiddleware(thunk, createLogger()),
      applyMiddleware(thunk)
    );
  } else {
    composed = compose(
      // applyMiddleware(thunk, createLogger()),
      applyMiddleware(thunk)
    );
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composed,
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/RootReducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default Store;
