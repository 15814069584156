// LICENSE_CODE YC
import React, {useEffect} from 'react';
import auth from '../../auth.js';

const E = ()=>{
  let {signout} = auth.use_auth();
  useEffect(()=>{
    signout();
  }, []);
  return <div></div>;
};

export default auth.with_auth(E);
