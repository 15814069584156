const { createTheme } = require('@material-ui/core');

const theme = createTheme({
  overrides: {
    MuiFormHelperText: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'rgba(255, 255, 255, 0.8)',
        '&$error': {
          color: '#cf6679',
        },
      },
      contained: {
        margin: '5px 12px -19px',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: '#cf6679',
        },
      },
    },
    MuiSnackbar: {
      anchorOriginBottomCenter: {
        bottom: '60px',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#ffffff',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: '300',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: 'rgba(0, 0, 0, 0.8)',
        boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.5)',
      },
    },

    MuiButton: {
      root: {
        width: '100%',
        height: '5rem',
        borderRadius: '0.6rem',
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: 'none',
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'Open Sans',
        fontSize: '1.4rem',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#929292',

        '&$selected': {
          color: '#ffffff',
        },
      },
    },

  },

  typography: {
    useNextVariants: true,
    fontFamily: 'Open Sans',
    fontWeightBold: 600,
    htmlFontSize: 10,
  },
  palette: {
    type: 'dark',
    background: {
      default: '#000',
    },
    primary: {
      main: '#ff007f',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
      disabled: '#fff',
      hint: '#fff',
    },
  },
});

export default theme;
