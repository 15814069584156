// LICENSE_CODE YC
import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Watch_later_icon from '@material-ui/icons/WatchLaterRounded';
import {Button} from '@material-ui/core';
import Mui_accordion from '@material-ui/core/Accordion';
import Mui_accordion_summary from '@material-ui/core/AccordionSummary';
import Mui_accordion_details from '@material-ui/core/AccordionDetails';
import Expand_more_icon from '@material-ui/icons/ExpandMore';
import {capitalize} from 'lodash';
import auth from './auth.js';
import eserf from '../../../util/eserf.js';
import metric from './metric.js';
import back_app from './back_app.js';

const _styles = theme=>({
  feedbackTextarea: {
    marginTop: '1.1rem',
    marginBottom: '1.1rem',
    fontFamily: 'Open Sans',
    fontSize: '1.4rem',
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1,
    letterSpacing: 'normal',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    height: '9.4rem',
    borderRadius: 6,
    background: 'rgba(0,0,0,1)',
    color: '#fff',
    padding: '1.2rem',
    border: 'none',
    '&::-webkit-input-placeholder': {
      color: '#fff',
    },
    '&:-ms-input-placeholder': {
      color: '#fff',
    },
    '&::placeholder': {
      color: '#fff',
    },
  },
  sendFeedbackButton: {
    height: '3.6rem',
    float: 'right',
    width: 84,
    fontWeight: 600,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginRight: '1.1rem',
  },
  wrapperIcon: {
    backgroundColor: '#262626',
    borderRadius: '6px',
    minWidth: 84,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonWrappers: {
    display: 'flex', width: '100%', justifyContent: 'space-between',
  },
  separator: {
    width: '100%',
    height: '1px',
    borderTop: 'solid 1px rgba(255, 255, 255, 0.3)',
    margin: '2.4rem 0',
  },
});

let accordion_styles = {
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      marginBottom: '2.3rem',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: '2.3rem',
    },
  },
  expanded: {},
};

let accordion_summary_styles = {
  root: {
    backgroundColor: '#262626',
    marginBottom: -1,
    minHeight: 56,
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'uppercase',
    '&$expanded': {
      minHeight: 56,
      borderRadius: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
};

let accordion_details_styles = ()=>({
  root: {
    padding: 16,
    backgroundColor: '#1E1E1E',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
});

const Accordion = withStyles(accordion_styles)(Mui_accordion);
const Accordion_summary = withStyles(
  accordion_summary_styles)(Mui_accordion_summary);
const Accordion_details = withStyles(
  accordion_details_styles)(Mui_accordion_details);
const styles_use = makeStyles(_styles);

const Feedback = ({
  class_id,
  lesson_id,
  lesson_num,
  lesson_title,
  on_feedback_submit,
  player,
})=>{
  let styles = styles_use();
  let [feedbacks, feedbacks_set] = useState([]);
  let [feedback_text, feedback_text_set] = useState('');
  let [feed_open, feed_open_set] = useState(true);
  let [current_time, current_time_set] = useState(0);
  // XXX colin: remove and get data from token in back_app
  let {user: current_user} = auth.use_auth();
  const time_update_handle = useCallback(()=>{
    if (!player)
      return;
    current_time_set(player.currentTime());
  }, [player]);
  useEffect(()=>{
    if (!player)
      return;
    player.on('timeupdate', time_update_handle);
    return ()=>{
      player.off('timeupdate');
    };
  }, [player, time_update_handle]);
  useEffect(()=>{
    let es = eserf(function* use_effect_init(){
      let _feedbacks = yield back_app.feedback_get();
      if (_feedbacks.err)
        return;
      _feedbacks = _feedbacks
        .filter(el=>el.lessonId==lesson_num)
        .sort(compare);
      feedbacks_set(_feedbacks);
    });
    return ()=>es.return();
  }, [lesson_num, current_user]);
  useEffect(()=>{
    if (window.localStorage.getItem('feedTabs'))
    {
      const tabs = JSON.parse(window.localStorage.getItem('feedTabs'));
      accordion_sections_blur();
      tabs.forEach(item=>{
        if (item.id === 'feed-form-feed')
          feed_open_set(item.isOpen);
      });
    }
  }, []);
  const accordion_sections_blur = ()=>{
    document.querySelectorAll('.MuiAccordionSummary-root').forEach(el=>{
      el.addEventListener('focus', event=>{
        event.target.blur();
      });
    });
  };
  const panel_open = (e, expanded, name)=>{
    let tabs = [];
    if (window.localStorage.getItem('feedTabs'))
      tabs = JSON.parse(window.localStorage.getItem('feedTabs'));
    tabs = tabs.filter(item=>item.id !== `${name}-feed`);
    tabs.push({isOpen: expanded, id: `${name}-feed`});
    window.localStorage.setItem('feedTabs', JSON.stringify(tabs));
    if (name === 'feed-form')
      feed_open_set(expanded);
  };
  const time_format = timestamp=>{
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);
    return `${minutes < 10 ? '0' : ''}` +
    `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  const compare =(a, b)=>{
    return a.timestamp > b.timestamp ? 1 :
      a.timestamp < b.timestamp ? -1 :
        Date.parse(a.createdAt) - Date.parse(b.createdAt);
  };
  const feedback_submit = useCallback(()=>eserf(function* _feedback_submit(){
    if (!player || !feedback_text.trim())
      return;
    feedback_text_set('');
    let feedback_data = {userName: capitalize(current_user.displayName),
      email: current_user.email, lessonId: lesson_num, classId: class_id,
      userId: current_user.uid, createdAt: new Date().toISOString(),
      videoTime: time_format(current_time), timestamp: current_time,
      comment: feedback_text};
    feedbacks_set([...feedbacks, feedback_data].sort(compare));
    window.dataLayer.push({
      event: 'feedbackSent',
      videoTitle: lesson_title,
      videoTime: time_format(current_time),
      email: current_user.email,
    });
    let res = yield back_app.feedback_set(class_id, feedback_text,
      lesson_id, lesson_title, current_time,
      capitalize(current_user.displayName), time_format(current_time));
    if (res.err)
      metric.error('feedback_set', res.err);
    on_feedback_submit();
  }), [class_id, current_time, current_user.displayName, current_user.email,
    current_user.uid, feedback_text, feedbacks, lesson_id, lesson_num,
    lesson_title, on_feedback_submit, player]);
  const change_handle = e=>{
    feedback_text_set(e.target.value);
  };
  return (
    <Accordion
      defaultExpanded={feed_open}
      expanded={feed_open}
      onChange={(e, expanded)=>panel_open(e, expanded, 'feed-form')}
    >
      <Accordion_summary
        expandIcon={<Expand_more_icon />}
        aria-controls={'panel-feedback-content'}
        id={'panel-feedback-header'}
      >
        Your feedback
      </Accordion_summary>
      <Accordion_details>
        <textarea
          name="feedbackText"
          value={feedback_text}
          onChange={change_handle}
          className={styles.feedbackTextarea}
          placeholder={'Leave your comment here…'}
        />
        <div className={styles.buttonWrappers}>
          <div className={styles.wrapperIcon}>
            <Watch_later_icon
              className={styles.icon}
              style={{marginRight: '0.5rem'}}
            />
            <p className="Input-text" style={{margin: 0}}>
              {time_format(current_time)}
            </p>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.sendFeedbackButton}
            onClick={feedback_submit}
          >
            SEND
          </Button>
        </div>
        {feedbacks.length > 0
          ? <React.Fragment>
            <div className={styles.separator} />
            {feedbacks.map((entry, index)=><div
              key={index}
              className={styles.buttonWrappers}
              style={{marginBottom: '1.4rem'}}
            >
              <div
                className={styles.wrapperIcon}
                style={{
                  background: 'transparent',
                  alignItems: 'self-start',
                  lineHeight: 'normal',
                  width: '100%',
                  justifyContent: 'start',
                }}
              >
                <p
                  className="Input-text"
                  style={{
                    margin: '0 0.8rem 0rem 0',
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: 'rgba(255, 255, 255, 0.9)',
                    lineHeight: 'normal',
                  }}
                >
                  {entry.videoTime}
                </p>
                <p style={{margin: 0}}>
                  {entry.comment}
                </p>
              </div>
            </div>)}
          </React.Fragment>
          : <React.Fragment>
            <div className={styles.separator} />
            <div className={styles.buttonWrappers}>
              <div
                className={styles.wrapperIcon}
                style={{
                  background: 'transparent',
                  alignItems: 'self-start',
                  lineHeight: 'normal',
                }}
              >
                <p
                  className="Input-text"
                  style={{
                    margin: '0 0.8rem 0 0',
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: 'rgba(255, 255, 255, 0.9)',
                    lineHeight: 'normal',
                  }}
                >
                  00:00
                </p>
                <p style={{margin: 0}}>
                  Your feedback will appear here
                </p>
              </div>
            </div>
          </React.Fragment>}
      </Accordion_details>
    </Accordion>
  );
};

export default Feedback;
