// LICENSE_CODE YC
import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Row, Col, Space, Button, Modal, Image} from 'antd';
import Header from './header.js';
import {useDispatch} from 'react-redux';
import back_app from './back_app.js';
import config_ext from './config_ext.js';
import eserf from '../../../util/eserf.js';
import xurl from '../../../util/xurl.js';
import str from '../../../util/str.js';
import date from '../../../util/date.js';
import metric from './metric.js';
import auth from './auth.js';

// XXX colin: add global vars for color primary
let color_primary = '#ff007f';
let E = ()=>{
  let history = useHistory();
  let dispatch = useDispatch();
  let location = useLocation();
  let class_id = 'c01'; // XXX colin: set to edward
  let bg = `${config_ext.general.mediaAssetsUrl}/${class_id}/cover.jpg`;
  let qs_o = xurl.qs_parse(location.search, true);
  // XXX colin: get from plan
  let s_day = 'time';
  if (qs_o.end)
  {
    s_day = Math.round(date.diffdates(qs_o.end, new Date(), 'days'));
    s_day += s_day==1 ? ' day' : ' days';
    if (isNaN(s_day))
    {
      s_day = 'time';
      metric.error('refund_renewal_invalid_date_end', str.j2s(qs_o));
    }
  }
  let {user: user_profile, user_full_update} = auth.use_auth();
  let [open, open_set]= useState(qs_o.cancel);
  let rewewal_cancel = ()=>eserf(function* _refund_get(){
    const res = yield back_app.recur_set(false);
    if (res.err)
      return void metric.error('recur_err', str.j2s(res.err));
    open_set(true);
    dispatch(yield user_full_update());
  });
  let on_click = ()=>{
    history.push(xurl.url('/account', qs_o));
  };
  return (
    <div className="yc-main"><Header go_back="/account" />
      {user_profile && <>
        <Modal open={open} closable={false} footer={null}>
          <Space style={{width: '100%'}} size="large" direction="vertical">
            <Row justify="center">
              <strong>Auto-renewal was turned off</strong>
            </Row>
            <Row justify="center">
              <Col>
                <Row justify="center">
                  <Col style={{textAlign: 'center'}}>
                    Your membership will not renew automatically.
                  </Col>
                </Row>
                <Row justify="center">
                  <Col style={{textAlign: 'center'}}>
                    You can always turn it back on in your profile for a
                    seamless experience.
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Button className="refund_renewal__btn_done"
                type="primary" block onClick={on_click}>DONE</Button>
            </Row>
          </Space>
        </Modal>

        <Space size="large" direction="vertical">
          <Row justify="center">
            <Image src={bg} preview={false}/>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20} style={{textAlign: 'center'}}>
              <h2>
                You still have
                <span style={{color: color_primary}}> {s_day} </span>
                to learn from the
                world&#39;s best chefs and make the most delicious food at
                home!
              </h2>
            </Col>
          </Row>
          <Row justify="center">
            <Col span={16} style={{textAlign: 'center'}}>
              Instead of cancelling now, we can remind you 30 days before
              your membership renews.
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20}>
              <Button className="refund_renewal__btn_cancel_auto_renewal"
                size="large" type="primary" block
                onClick={rewewal_cancel}>
                TURN OFF AUTO-RENEWAL
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20}>
              <Button className="refund_renewal__btn_remind_me_to_cancel"
                size="large" block
                onClick={()=>history.push(xurl.url('/account', qs_o))}>
                REMIND ME TO CANCEL
              </Button>
            </Col>
          </Row>
          <Row justify="center">
            <Col sm={12} xs={20}>
              <Button className={{refund_renewal__btn__get_help: 1,
                refund_renewal_bot: 1}} size="large" block>
                GET HELP
              </Button>
            </Col>
          </Row>
        </Space>
      </>}
    </div>);
};

export default auth.with_auth(E);
