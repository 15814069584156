// LICENSE_CODE YC
import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Text_field from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {Button, Divider} from '@material-ui/core';
import google_logo from '../../assets/images/googleLogo.svg';
import fb_logo from '../../assets/images/fbLogo.svg';
import {getCookie} from '../../helpers/cookie';
import auth from '../../auth.js';
import back_app from '../../back_app.js';
import Creds_form_styles, {css_text_fieldStyles} from './index.styles';

const Css_text_field = withStyles(css_text_fieldStyles)(Text_field);
const styles_use = makeStyles(Creds_form_styles);

const Creds_form = ()=>{
  let classes = styles_use();
  let [pwd, pwd_set] = useState('');
  let [pwd_error, pwd_error_set] = useState('');
  let [disable_button, disable_button_set] = useState(false);
  let [show_only_pass, show_only_pass_set] = useState(false);
  let {user: current_user, login_with,
    signin_with_custom_token, user_full_update} = auth.use_auth();
  useEffect(()=>{
    const yc_pass_create = getCookie('ycpasscreate');
    if (yc_pass_create !== '')
      show_only_pass_set(true);
  }, []);
  const success_data_change = async ()=>{
    await back_app.yc_gen_pwd_set(current_user.email, false);
    user_full_update();
  };
  const pwd_validate = ()=>{
    let valid = true;
    if (!pwd)
    {
      valid = false;
      pwd_error_set('Password is required');
    }
    else if
    (pwd.length < 6)
    {
      valid = false;
      pwd_error_set('Password needs to be at least 6 characters long');
    }
    return valid;
  };
  const change_handle = event=>pwd_set(event.target.value);
  const pwd_update = async event=>{
    event.preventDefault();
    let valid = pwd_validate();
    if (!valid)
      return;
    try {
      pwd_error_set('');
      disable_button_set(true);
      let res = await back_app.user_get_token_from_uid(current_user.uid);
      if (!res.token)
        return;
      await signin_with_custom_token(res.token);
      try {
        await current_user.updatePassword(pwd);
        success_data_change();
        disable_button_set(false);
      } catch(error) {
        let err_msg = error.message;
        if (err_msg === 'Password should be at least 6 characters')
          pwd_error_set('Password needs to be at least 6 characters long');
        disable_button_set(false);
      }
    } catch(err) {
      disable_button_set(false);
    }
  };
  return (
    <React.Fragment>
      <h3 className={classes.subHeader}>
        CREATE YOUR PASSWORD
      </h3>
      <Box
        className={classes.container}
      >
        <form
          className={classes.loginWith}
          autoComplete="on"
          onSubmit={pwd_update}
        >
          <Css_text_field
            id="password"
            label="Password"
            helperText={pwd_error || 'At least 6 characters'}
            error={pwd_error !== ''}
            FormHelperTextProps={{error: pwd_error !== ''}}
            value={pwd}
            onBlur={pwd_validate}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            type="password"
            autoComplete="current-password"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth={true}
            onChange={change_handle}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={e=>pwd_update(e)}
            className={classes.signUpBtn}
            disabled={disable_button}
          >
            <Box fontWeight="600" fontSize="1.4rem">
              Create Password
            </Box>
          </Button>
        </form>
        {!show_only_pass && <React.Fragment>
          <Box
            flexDirection="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            fontWeight="600"
            mb="1rem"
            mt="-1rem"
          >
            <Divider width="40%" className={classes.boldDivider} />
            <span className="Button-text" style={{color: '#999999'}}>
              OR
            </span>
            <Divider width="40%" className={classes.boldDivider} />
          </Box>
          <Box className={classes.loginWith}>
            <Button
              className={classes.fbButton}
              size="large"
              onClick={()=>login_with('facebook', success_data_change, {})}
            >
              <Box pr="0.7rem" display="flex">
                <img src={fb_logo} alt="Facebook Logo" />
              </Box>
              <Box fontSize="1.4rem" fontWeight="600">
                Use FACEBOOK
              </Box>
            </Button>
            <Button
              className={classes.googleButton}
              size="large"
              onClick={()=>login_with('google', success_data_change, {})}
            >
              <Box pr="0.7rem" display="flex">
                <img src={google_logo} alt="Google Logo" />
              </Box>
              <Box fontSize="1.4rem" fontWeight="600">
                Use GOOGLE
              </Box>
            </Button>
          </Box>
        </React.Fragment>}
      </Box>
    </React.Fragment>
  );
};

export default Creds_form;
