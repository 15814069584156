// LICENSE_CODE YC
import React, {useState, useEffect, useCallback, useContext,
  useRef} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import config_ext from './config_ext.js';
import metric from './metric.js';
import back_app from './back_app.js';
import {is_plan_active} from './helpers/user.js';
import {deleteCookie, writeCookie, getCookie} from './helpers/cookie.js';
import {Redirect, useLocation} from 'react-router-dom';
import Loader from './loader.js';
import xurl from '../../../util/xurl.js';

let E = {};

let ctx = React.createContext();

E.fbase = firebase;
E.fbase.initializeApp(config_ext.fbase);

E.fbase_auth = firebase.auth();

E.token_get = ()=>eserf(function* _token_get(){
  if (!E.fbase_auth.currentUser)
    return {err: 'no current user'};
  this.continue(E.fbase_auth.currentUser.getIdToken());
  return yield this.wait();
});

let fbase_user_get_id_token = fbase_user=>eserf(function*
_fbase_user_get_id_token(){
  fbase_user.getIdToken().then(
    id_token_res=>this.continue(id_token_res)).catch(
    err=>this.continue({err}));
  let res = yield this.wait();
  return res;
});

let Auth_provider = ({children})=>{
  let [token, token_set] = useState(null);
  let [user, user_set] = useState(null);
  let [user_full, user_full_set] = useState(null);
  let [loading_auth, loading_auth_set] = useState(false);
  let [is_init, is_init_set] = useState(false);
  let is_sign_in_ref = useRef(false);
  useEffect(()=>{
    if (!user_full)
      return;
    let es = eserf(function* _history_get(){
      let res = yield back_app.history_get();
      if (res.err)
        return;
      je.set('continue_watching', res);
    });
    return ()=>es.return();
  }, [user_full]);
  let fbase_cb = useCallback(()=>{
    E.fbase_auth.onAuthStateChanged(fbase_user=>eserf(function* on_auth(){
      loading_auth_set(true);
      is_init_set(true);
      if (!fbase_user)
      {
        signout();
        user_set(null);
        loading_auth_set(false);
        return;
      }
      if (fbase_user.uid == user?.uid)
        return;
      metric.set_id(fbase_user.email);
      let first_sign_in = new Date(localStorage.getItem('signinTime'));
      let three_month = new Date();
      three_month.setDate(first_sign_in.getDate() + 90);
      if (three_month < first_sign_in)
      {
        signout();
        loading_auth_set(false);
        return;
      }
      let user_meta = yield back_app.user_meta();
      if (user_meta.err)
        user_meta = null;
      if (user_meta)
      {
        if (user_meta.isBeta || is_plan_active(user_meta)
        || config_ext.front.is_login_any)
        {
          user_full_set(user_meta);
          user_set(fbase_user);
          let _token = yield fbase_user_get_id_token(fbase_user);
          if (_token.err)
          {
            signout();
            user_set(null);
            loading_auth_set(false);
            metric.error('authProvider fbase_cb Error', _token.err);
            return;
          }
          token_set(_token);
          yield back_app.login_set();
        }
        else
        {
          signout();
          user_set(null);
        }
      }
      else if (!user_meta)
        signout();
      let auth_event_ga = window.localStorage.getItem('authEventGA');
      if (auth_event_ga === null)
      {
        window.dataLayer.push({
          event: 'appsignin',
          email: fbase_user.email,
          userId: user_meta ? user_meta.user_id : fbase_user.email,
          referrer: document.referrer,
        });
        window.localStorage.setItem('authEventGA', 1);
      }
      if (window.location.pathname === '/')
      {
        let auth_user = window.localStorage.getItem('authUser');
        if (auth_user === null)
          writeCookie('ycfuid', fbase_user.uid, 30);
        window.localStorage.setItem('authUser', JSON.stringify(fbase_user));
      }
      loading_auth_set(false);
    }));
  }, [user?.uid, user_set]);
  useEffect(()=>{
    if (!user)
      fbase_cb();
  }, [user, fbase_cb]);
  let login = provider=>eserf(function* _login(){
    E.fbase_auth.signInWithPopup(provider).then(
      res=>this.continue(res)).catch(err=>this.continue({err}));
    let res = yield this.wait();
    if (res.err)
    {
      if (res.err.code == 'auth/popup-blocked')
      {
        E.fbase_auth.signInWithRedirect(provider).then(
          __res=>this.continue(__res)).catch(err=>this.continue({err}));
        let _res = yield this.wait();
        if (_res.err)
        {
          metric.error('auth_popup_block_err', _res.err.message);
          throw _res.err;
        }
      }
      return res;
    }
    return res.user;
  });
  let login_fb = ()=>{
    return login(new E.fbase.auth.FacebookAuthProvider());
  };

  let login_google = ()=>{
    return login(new E.fbase.auth.GoogleAuthProvider());
  };
  let login_with = (provider, callback=()=>{}, social_login_ref={},
  )=>eserf(function* _login_with(){
    let res;
    if (provider=='google')
      res = yield login_google();
    if (provider=='facebook')
      res = yield login_fb();
    else
      metric.error_once('auth_invalid_provider_err', provider);
    if (res.err)
    {
      if (res.err.email && res.err.credential &&
        res.err.code === 'auth/account-exists-with-different-credential')
      {
        let linked_provider = null;
        metric.set_id(res.err.email);
        switch (provider)
        {
        case 'google':
          linked_provider = new E.fbase.auth.GoogleAuthProvider();
          break;
        case 'facebook':
          linked_provider = new E.fbase.auth.FacebookAuthProvider();
          break;
        default:
          throw new Error(`No provider implemented ${provider}`);
        }
        if (linked_provider)
        {
          linked_provider.setCustomParameters({login_hint: res.err.email});
          let _res = yield back_app.user_get_token(res.err.email);
          E.fbase_auth.signInWithCustomToken(_res.token).then(
            re=>this.continue(re)).catch(err=>this.continue({err}));
          _res = yield this.wait();
          if (_res.user && user)
          {
            linked_provider.setCustomParameters({login_hint: res.err.email});
            user.linkWithCredential(res.err.credential);
            social_login_ref.current = true;
            callback(_res.user);
          }
        }
      }
      return;
    }
    metric.set_id(res.email);
    localStorage.setItem('signinTime', new Date());
    localStorage.setItem('uid', res.uid);
    social_login_ref.current = true;
    callback(res);
  });

  let signout = ()=>{
    window.localStorage.removeItem('authUser');
    E.fbase_auth.signOut();
    localStorage.clear();
    deleteCookie('ycfuid');
    window.Intercom('shutdown');
    window.Intercom('update', {hide_default_launcher: false});
    user_set(null);
    user_full_set(null);
    token_set(null);
  };

  let user_full_update = ()=>eserf(function*
  _user_full_update(){
    let user_meta = yield back_app.user_meta(true);
    if (user_meta.err)
      return void metric.error('update user_meta err', user_meta.err);
    user_full_set(user_meta);
  });

  let signin_with_custom_token = ycfuid=>eserf(function*
  _signin_with_custom_token(){
    loading_auth_set(true);
    let _user_data = yield back_app.user_get_fbase(ycfuid);
    if (_user_data && (_user_data.isBeta || _user_data.is_admin)
    && _user_data.customAuthToken)
    {
      yield E.fbase_auth.signInWithCustomToken(_user_data.customAuthToken);
    }
    loading_auth_set(false);
  });

  let signin_with_email_link = hash_id=>eserf(function*
  _signin_with_email_link(){
    let email_for_sign_in;
    yield E.fbase_auth.setPersistence(E.fbase.auth.Auth.Persistence.LOCAL);
    let is_signin_with_email_link =
      yield E.fbase_auth.isSignInWithEmailLink(window.location.href);
    if (!is_signin_with_email_link)
      return;
    email_for_sign_in = window.localStorage.getItem('emailForSignIn')
          || decodeURIComponent(getCookie('emailForSignIn'));
    loading_auth_set(true);
    if (!email_for_sign_in)
    {
      let _user_data = yield back_app.user_get_hash_id(hash_id);
      email_for_sign_in = _user_data && _user_data.email
        ? _user_data.email
        : window.prompt('Please provide your email for confirmation')
          .toLowerCase();
      window.localStorage.setItem('emailForSignIn', email_for_sign_in);
    }
    if (!is_sign_in_ref.current)
    {
      yield E.fbase_auth
        .signInWithEmailLink(email_for_sign_in, window.location.href);
      is_sign_in_ref.current = true;
      window.localStorage.removeItem('emailForSignIn');
    }
    loading_auth_set(false);
    return email_for_sign_in;
  });

  let send_password_reset_email = (email, url)=>eserf(function*
  _send_password_reset_email(){
    yield E.fbase_auth.sendPasswordResetEmail(email, {url,
      handleCodeInApp: true});
  });

  let signin_with_email_and_password = (email, password)=>eserf(function*
  _signin_with_email_and_password(){
    yield E.fbase_auth.signInWithEmailAndPassword(email, password);
  });

  let send_signin_link_to_email = (email, action_code_setting)=>eserf(function*
  _send_signin_link_to_email(){
    yield E.fbase_auth.sendSignInLinkToEmail(email, action_code_setting);
  });

  let signup = email=>eserf(function* _signup_submit(){
    loading_auth_set(true);
    let res = yield back_app.signup_no_plan(email);
    if (res.err)
      return;

    let resp = yield back_app.user_get_token(email);
    if (resp.err)
      return;

    E.fbase_auth.signInWithCustomToken(resp.token).then(
      re=>this.continue(re)).catch(err=>this.continue({err}));
    let _res = yield this.wait();
    if (_res.user && user)
      console.log('_res.user', _res.user);
    loading_auth_set(false);
  });

  let value = {
    token,
    loading_auth,
    user,
    login_with,
    user_full,
    signout,
    user_full_update,
    signin_with_custom_token,
    signin_with_email_link,
    send_password_reset_email,
    signin_with_email_and_password,
    send_signin_link_to_email,
    is_init,
    signup
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

E.auth_provider = E.Auth_provider = Auth_provider;

E.use_auth = E.useAuth = ()=>useContext(ctx);

let with_auth = Wrapped_component=>{
  return props=>{
    const location = useLocation();
    let qs_o = xurl.qs_parse(location.search, true);
    const {user, is_init, loading_auth, user_full} = E.use_auth();
    if (!is_init || loading_auth)
      return <Loader />;
    if (!user)
    {
      return <Redirect
        to={{pathname: '/signin',
          search: location.search, state: {from: props.location}}} />;
    }
    if (qs_o.dbg_fbase_signup && !user_full.plan
      && !location.pathname.includes('account/activate'))
    {
      return <Redirect
        to={{pathname: '/account/activate', search: location.search,
          state: {from: props.location}}} />;
    }
    return <Wrapped_component {...props} />;
  };
};

E.with_auth = with_auth;

export default E;
