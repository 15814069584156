// LICENSE_CODE YC
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {format, addDays} from 'date-fns';
import {textCapitalize} from './helpers/TextHelper';
import auth from './auth.js';
import Header from './header.js';
import {gift_popup_set} from './actions/mainMenu';
import xurl from '../../../util/xurl.js';
import {Col, Row, Space, Avatar, Typography, Divider, Button} from 'antd';
import {ReactComponent as Profile_icon_svg} from './profile_icon.svg';
import Icon from '@ant-design/icons';

let {Title, Text, Paragraph} = Typography;
const E = props=>{
  let {user: user_profile, signout} = auth.use_auth();
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  useEffect(()=>{
    if (user_profile)
      return;
    let _qs_o = xurl.qs_parse(location.search, true);
    history.push(xurl.url('/', _qs_o));
    window.location.reload();
  }, [user_profile, history, location]);
  const chat_open = ()=>window.Intercom('show');
  const gift_popup_open = ()=>dispatch(gift_popup_set(true));
  let Chevron_right = ()=><svg viewBox="0 0 7 12" width="7" height="12"
    fill="currentColor">
    <path d="M0.292461 10.8825C0.682461 11.2725 1.31246 11.2725 1.70246
10.8825L6.29246 6.2925C6.68246 5.9025 6.68246 5.2725 6.29246 4.8825L1.70246
0.292503C1.31246 -0.097497 0.682461 -0.097497 0.292461 0.292503C-0.0975389
 0.682504 -0.0975389 1.3125 0.292461 1.7025L4.17246 5.5925L0.292461
 9.4725C-0.0975389 9.8625 -0.0875392 10.5025 0.292461 10.8825Z"/>
  </svg>;

  if (!user_profile)
    return null;
  return (
    <div className="yc-main">
      <Header />
      <Row justify="center">
        <Col flex="auto" sm={24} md={22} lg={14}>
          <Row gutter={[0, 20]} justify="center"
            style={{marginTop: 30, marginBottom: 30}}>
            <Col>
              <Space size="middle" align="center" direction="vertical">
                <Avatar size={100} style={{backgroundColor: '#FF007F',
                  color: '#C20A65', fontSize: 30}} icon={
                  <Icon style={{fontSize: 40}}
                    component={Profile_icon_svg} />} />
                <Title level={4}>
                  {textCapitalize(user_profile.displayName)}
                </Title>
              </Space>
            </Col>
            <Col span={24}>
              <Divider style={{margin: 0}} />
              <Button onClick={()=>history.push(xurl.url('/account', qs_o))}
                style={{padding: 15, height: 'auto'}} block type="text">
                <Row justify="space-between" align="middle">
                  <Col><span>Account</span></Col>
                  <Col><Icon component={Chevron_right} /></Col>
                </Row>
              </Button>
              <Divider style={{margin: 0}} />
              <Button onClick={chat_open}
                style={{padding: 15, height: 'auto'}} block type="text">
                <Row justify="space-between" align="middle">
                  <Col><span>Help</span></Col>
                  <Col><Icon component={Chevron_right} /></Col>
                </Row>
              </Button>
              <Divider style={{margin: 0}} />
              <Button onClick={signout}
                style={{padding: 15, height: 'auto'}} block type="text">
                <Row justify="space-between" align="middle">
                  <Col><span>Sign out</span></Col>
                </Row>
              </Button>
              <Divider style={{margin: 0}} />
            </Col>
            {qs_o.dbg_feature_gift && <Col lg={24} xs={22}>
              <div style={{backgroundColor: '#1E1E1E', padding: 24,
                borderRadius: 6}}>
                <Text style={{fontSize: 16}}>
                  GUEST PASSES
                </Text>
                <Title style={{marginTop: '0.5em'}} level={5}>
                Introduce your friends to YesChef.
              They will get a 14-day Guest Pass.
                </Title>
                <Paragraph style={{color: '#929292', fontSize: 14}}>
                Expires on {format(addDays(new Date(), 5), 'MMM dd, uuuu')}
                </Paragraph>
                <Button style={{backgroundColor: '#EEEEEE', color: '#0F0F0F'}}
                  size="large" block onClick={gift_popup_open} type="text">
                SEND GUEST PASSES
                </Button>
              </div>
            </Col>}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default auth.with_auth(E);
