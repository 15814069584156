// LICENSE_CODE YC
import React, {useEffect, useState} from
  'react';
import {useHistory, useLocation} from 'react-router-dom';
import Privacy_policy from './privacy.js';
import Tos from './terms.js';
import Loader from './loader';
import auth from './auth.js';
import metric from './metric.js';
import xurl from '../../../util/xurl.js';
import str from '../../../util/str.js';
import {Col, Row, Typography, Input, Button} from 'antd';

let {Paragraph, Title, Text} = Typography;

let E = ()=>{
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let [email, email_set] = useState('');
  let [email_error, email_error_set] = useState({isError: false, msg: ''});

  let [show_privacy, show_privacy_set] = useState(false);
  let [show_terms, show_terms_set] = useState(false);
  let {loading_auth, is_init, signup, user, user_full}
    = auth.use_auth();
  let history = useHistory();

  useEffect(()=>{
    if (user && user_full)
    {
      delete qs_o.hash;
      delete qs_o.mode;
      delete qs_o.apiKey;
      delete qs_o.oobCode;
      delete qs_o.lang;
      history.push(xurl.url('/home', qs_o));
      window.location.reload();
    }
    // else
    //   data_set();
  }, [history, user, user_full]);

  const email_validate = _email=>{
    return _email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/ui);
  };
  const signup_submit = event=>{
    event.preventDefault();
    if (!email_validate(email))
    {
      email_error_set({isError: true,
        msg: 'Please enter a valid email address'});
      return false;
    }
    metric.set_id(email);
    try {
      signup(email);
      // XXX: abdel add error handling when user already exists
    } catch(error) {
      metric.error('signing_err', email, str.j2s(error));
    }
  };

  const change_handle = event=>{
    email_set(event.target.value.toLowerCase());
  };
  const popup_terms_show = e=>{
    if (e.target.id == 'privacy')
      show_privacy_set(true);
    else
      show_terms_set(true);
  };
  const close_handle = ()=>{
    show_terms_set(false);
    show_privacy_set(false);
  };

  if (loading_auth || !is_init)
    return <Loader />;
  return (
    <>
      <Row
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'}}
      >
        <Col align="middle"justify="middle" >
          <Title level={2} style={{fontWeight: '300', fontSize: '30',
            margin: 0,
            fontFamily: 'Open Sans', color: '#FFF'}}>
            YES<span style={{fontWeight: '600'}}>CHEF2</span>
          </Title>
          <Title level={4} style={{
            textTransform: 'uppercase',
            fontSize: 14,
            margin: 0,
            color: '#FFF'}}>
            Learn from the World&apos;s Best Chefs
          </Title>
        </Col>
        <Col
          style={{
            backgroundColor: '#fff',
            borderRadius: '6px',
            padding: '4rem',

          }}
        >
          <Col>
            <form
              style={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              autoComplete="on"
              onSubmit={signup_submit}
            >
              <Col style={{width: '100%'}}>
                <Input
                  placeholder="Email"
                  label="Email"
                  type="email"
                  style={{
                    borderColor: '#999999 !important',
                    borderWidth: '1px !important',
                    color: '#444444',
                    backgroundColor: '#FFF',
                    fontSize: '14px',
                    height: 57,
                    fontWeight: '300',
                    placeholderColor: '#000'}
                  }
                  onChange={change_handle}
                  autoComplete="email"
                  value={email}
                  status={email_error.isError &&'error'}
                />
                {email_error.isError&&<>
                  <Row justify="center" align="center"
                    style={{padding: 0}} >
                    <Paragraph style={{
                      color: '#b00020',
                      fontSize: '1.2rem'
                    }}>{email_error.msg}
                    </Paragraph>
                    <Paragraph type="text"
                      style={{
                        fontWeight: 300,
                        color: '#b00020',
                        cursor: 'pointer',
                        fontSize: '1.2rem',
                        marginLeft: 4,
                        textDecoration: 'underline'
                      }}
                      onClick={()=>window.Intercom('show')}>
                      Need help?
                    </Paragraph>
                  </Row>
                </>
                }
              </Col>
              <Button
                type="primary"
                variant="contained"
                size={'large'}
                style={{
                  marginTop: email_error.isError?0:'1.6rem',
                  width: '100%',
                  fontWeight: '600',
                  fontSize: '1.4rem',
                  opacity: 1,
                  color: '#FFF',
                  textTransform: 'uppercase',
                  backgroundColor: 'rgb(255, 0, 127)',
                  ':hover': {
                    backgroundColor: '#4367b2',
                    opacity: 0.8
                  }
                }}

                onClick={signup_submit}
              >Sign Up With Email</Button>
            </form>
            <Col
              style={{
                maxWidth: 231,
                width: '100%',
                margin: 'auto',
                textAlign: 'center'}}
            >
              <Paragraph style={{color: '#333333',
                fontSize: '1.4rem',
                fontWeight: 300,
                textAlign: 'center',
                marginTop: 12,
              }}>
                By signing in, you agree to our <Text id="privacy"
                  onClick={popup_terms_show} style={{color: '#333333',
                    fontSize: '1.4rem',
                    fontWeight: 300,
                    textAlign: 'center',
                    cursor: 'pointer'}}>Privacy Policy</Text> and{' '}
                <Text
                  style={{color: '#333333',
                    fontSize: '1.4rem',
                    fontWeight: 300,
                    textAlign: 'center',
                    cursor: 'pointer'
                  }}
                  id="terms" onClick={popup_terms_show}> Terms of Service
                </Text>.
              </Paragraph>
            </Col>
          </Col>
        </Col>
      </Row>
      {show_privacy && <Row
        style={{width: '100%',
          position: 'absolute',
          left: '0',
          top: '0',
          margin: '0 auto',
          zIndex: '200',
          backgroundColor: '#000'
        }}>
        <Privacy_policy handleClose={close_handle} />
      </Row>}
      {show_terms && <Row
        style={{width: '100%',
          position: 'absolute',
          left: '0',
          top: '0',
          margin: '0 auto',
          zIndex: '200',
          backgroundColor: '#000'
        }}>
        <Tos handleClose={close_handle} />
      </Row>}
    </>
  );
};

export default E;
