// LICENSE_CODE YC
import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

let Loader = ()=>
  <Spin size="large" 
        style={{position: 'absolute', top: '50%',left: '50%', transform: 'translate(-50%, -50%)'}}
        indicator={<LoadingOutlined spin />} 
  />;

let InlineLoader = (props) => <Spin size={props.size ?? 'default'} style={props.style} indicator={<LoadingOutlined spin />} />;

export default Loader;

export {
  InlineLoader
};