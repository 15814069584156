// LICENSE_CODE MIT
let YEAR = 'YYYY';
let MONTH = 'MMM';
let MONTH_FULL = 'MMMM';
let DAY_NO_PAD = 'dd';
let DAY = 'DD';
let WEEK = 'www';
let SECOND = 'ss';
let MINUTE = 'mm';
let HOUR = 'hh';
let DATE_CASES = [YEAR, MONTH_FULL, MONTH, WEEK, DAY, DAY_NO_PAD, SECOND,
  MINUTE, HOUR];
let weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
let months = ['Jan', 'Febr', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
  'Sep', 'Oct', 'Nov', 'Dec'];
let month_names = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'];
// XXX colin: change filename to be xdate

let E = {};
export default E;
E.MS_SEC = 1000;
E.MS_MIN = E.MS_SEC*60;
E.MS_HOUR = E.MS_MIN*60;
E.MS_DAY = E.MS_HOUR*24;
E.MS_WEEK = E.MS_DAY*7;
E.MS_YEAR = E.MS_DAY*365;
E.SEC_MS = 1/E.MS_SEC;
E.SEC_MIN = E.MS_MIN/E.MS_SEC;
E.SEC_HOUR = E.MS_HOUR/E.MS_SEC;
E.SEC_DAY = E.MS_DAY/E.MS_SEC;
E.SEC_WEEK = E.MS_WEEK/E.MS_SEC;
E.SEC_YEAR = E.SEC_DAY*365;
E.MAX = new Date(8640000000000000);

E.delta2lbl = (delta_sec, t=s=>s)=>{
  delta_sec = Math.round(delta_sec);
  if (delta_sec<1)
    return '0 '+t('seconds');
  if (delta_sec<2)
    return '1 '+t('second');
  if (delta_sec<E.SEC_MIN)
    return delta_sec+' '+t('seconds');
  let delta_min = Math.round(delta_sec/E.SEC_MIN);
  if (delta_min<2)
    return '1 '+t('minute');
  if (delta_min<60)
    return delta_min+' '+t('minutes');
  let delta_hour = Math.round(delta_sec/E.SEC_HOUR);
  if (delta_hour<2)
    return '1 '+t('hour');
  if (delta_hour<24)
    return delta_hour+' '+t('hours');
  let delta_day = Math.round(delta_sec/E.SEC_DAY);
  if (delta_day<2)
    return '1 '+t('day');
  if (delta_day<7)
    return delta_day+' '+t('days');
  let delta_week = Math.round(delta_sec/E.SEC_WEEK);
  if (delta_week<2)
    return '1 '+t('week');
  return delta_week+' '+t('weeks');
};
E.now_plus_days = E.days_add = n=>{
  let now = new Date();
  now.setDate(now.getDate() + n);
  return now;
};
E.date_plus_days = (dt, n)=>{
  let dt_res = new Date(dt);
  let millisecondsToAdd = n * 24 * 60 * 60 * 1000; // Convert days to milliseconds
  dt_res.setTime(dt_res.getTime() + millisecondsToAdd); // Add milliseconds to the date
  return dt_res;
};
E.dt2date = dt=>{
  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
};
E.dt2date_str = dt=>{
  return E.dt2date(dt).toISOString().split('T')[0];
};
E.dt2end_of_month = dt=>{
  return new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
};
E.is_valid_date = dt=>dt instanceof Date && !isNaN(dt);
E.compare_dates = (dt1, dt2)=>{
  if (!dt1 && !dt2)
    return true;
  if (dt1 == 'Invalid Date' || dt2 == 'Invalid Date')
    return false;
  if (!dt1 && dt2 || !dt2 && dt1)
    return false;
  return dt1.getTime() === dt2.getTime();
};
E.current_year_2digit = ()=>parseInt(new Date().getFullYear()
  .toString().substr(-2), 10);
E.date_format = (date, format)=>{
  const digit2_convert = time=>{
    return ('0' + time).slice(-2);
  };
  let formated_date = format;
  let y = date.getFullYear();
  let MMM = months[date.getMonth()];
  let MMMM = month_names[date.getMonth()];
  let dd = digit2_convert(date.getDate());
  let d = date.getDate();
  let h = digit2_convert(date.getHours());
  let min = digit2_convert(date.getMinutes());
  let s = digit2_convert(date.getSeconds());
  let w = weekdays[date.getDay()];
  let cases_val = {
    YYYY: y,
    MMM: MMM,
    MMMM: MMMM,
    dd: d,
    DD: dd,
    www: w,
    hh: h,
    mm: min,
    ss: s
  };
  DATE_CASES.forEach(key=>{
    formated_date = formated_date.replace(key, cases_val[key]);
  });
  return formated_date;
};
E.last_day_of_last_month = dt=>{
  let last_day_of_last_month = dt ? new Date(dt) : new Date();
  last_day_of_last_month.setMonth(last_day_of_last_month.getMonth(), 0);
  return last_day_of_last_month;
};
E.diffdates = (d1, d2, by)=>{
  let unit = E.MS_SEC;
  switch (by)
  {
  case 'seconds':
    unit = E.MS_SEC;
    break;
  case 'minutes':
    unit = E.MS_MIN;
    break;
  case 'hours':
    unit = E.MS_HOUR;
    break;
  case 'days':
    unit = E.MS_DAY;
    break;
  default:
    unit = E.MS_SEC;
  }
  let diff = Math.abs(d2 - d1) / unit;
  return diff;
};
E.fmt_iso = d=>d.toJSON();
E.fmt_sql = d=>d.toJSON().split('.')[0];
