// LICENSE_CODE YC
import React from 'react';
import {textCapitalize} from './helpers/TextHelper';
import {Col, Modal, Row, Typography, Button, ConfigProvider, theme} from 'antd';

let {Title, Paragraph} = Typography;

const Popup = ({display_name, is_open, on_close_cb, on_submit_cb, title,
  message, button_text, form, closable = true, mask_closable = true,
  desktop_title})=>{
  let on_close = (event, reason)=>{
    if (reason=='backdropClick' && !closable)
      return;
    if (typeof on_close_cb === 'function')
      on_close_cb();
  };
  let on_submit = ()=>{
    if (typeof on_submit_cb === 'function')
      on_submit_cb();
  };
  let popup_heading =
  window.innerWidth >= 768 && desktop_title ? desktop_title :
    textCapitalize(display_name) ?
      `${textCapitalize(display_name.split(' ')[0])}, ${title}` : title;
  return (
    <ConfigProvider theme={{algorithm: theme.defaultAlgorithm,
      token: {fontWeightStrong: 700}}}>
      <Modal width={400} maskClosable={mask_closable} closable={closable}
        centered footer={null} open={is_open} onCancel={on_close}>
        <Row justify="center" style={{padding: 20}}>
          <Col span={24}>
            <Row justify="center">
              <Col span={24}>
                <Row>
                  <Col flex={1}>
                    <Title style={{textAlign: 'center',
                      whiteSpace: 'pre-wrap', margin: 20}} level={4}>
                      {popup_heading}
                    </Title>
                  </Col>
                  {!!message && <Col flex={1}>
                    <Paragraph style={{textAlign: 'center',
                      whiteSpace: 'pre-wrap'}}>
                      {message}
                    </Paragraph>
                  </Col>}
                </Row>
              </Col>
            </Row>
          </Col>
          {form && <Col span={24}>
            {form}
          </Col>}
          <Col span={24}>
            {button_text && on_submit &&
            <Button style={{textTransform: 'uppercase', height: 'auto',
              whiteSpace: 'normal'}} size="large" type="primary"
            block onClick={on_submit}>
              {button_text}
            </Button>}
          </Col>
        </Row>
      </Modal>
    </ConfigProvider>
  );
};

export default Popup;
