// LICENSE_CODE YC
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Expand_more_icon from '@material-ui/icons/ExpandMore';
import suppliesStyles, {AccordionDetailsStyles, AccordionStyles,
  AccordionSummaryStyles} from './index.styles';

let Accordion = withStyles(AccordionStyles)(MuiAccordion);
let AccordionSummary = withStyles(AccordionSummaryStyles)(MuiAccordionSummary);
let AccordionDetails = withStyles(AccordionDetailsStyles)(MuiAccordionDetails);

let {titleStyle} = suppliesStyles;
let {detailStyle} = suppliesStyles;
let separator = suppliesStyles.separatorStyle;

const E = ({id, supplies, isExpanded: expanded})=>{
  const initial_state_get = useCallback(()=>{
    if (window.localStorage.getItem('suppliesTabs'))
    {
      let tabs = JSON.parse(window.localStorage.getItem('suppliesTabs'));
      let find = tabs.find(item=>item.id === `${supplies.sectionName}-${id}`);
      if (find)
        return find.isOpen;
      return expanded;
    }
    return expanded;
  }, [supplies, id, expanded]);
  let [open, open_set] = useState(initial_state_get);
  useEffect(()=>{
    sections_accordions_blur();
  }, []);
  const sections_accordions_blur = ()=>{
    document.querySelectorAll('.MuiAccordionSummary-root').forEach(el=>{
      el.addEventListener('focus', event=>{
        event.target.blur();
      });
    });
  };
  const gcd = (a, b)=>{
    if (b < 0.0000001)
      return a;
    return gcd(b, Math.floor(a % b));
  };
  const decimal2fraction = amount=>{
    if (!amount)
      return 0;
    if (parseFloat(amount) === parseInt(amount, 10))
      return parseInt(amount, 10);
    let len = amount.toString().length - 2;
    let denominator = 10 ** len;
    let numerator = amount * denominator;
    let divisor = gcd(numerator, denominator);
    numerator /= divisor;
    denominator /= divisor;
    let base = 0;
    if (numerator > denominator)
    {
      base = Math.floor(numerator / denominator);
      numerator -= base * denominator;
    }
    let fraction = `${Math.floor(numerator)}/${Math.floor(denominator)}`;
    if (base)
      return `${base} ${fraction}`;
    return fraction;
  };
  const panel_open = (e, _expanded)=>{
    let supplies_tabs = [];
    if (window.localStorage.getItem('suppliesTabs'))
      supplies_tabs = JSON.parse(window.localStorage.getItem('suppliesTabs'));
    supplies_tabs = supplies_tabs.filter(item=>{
      return item.id !== `${supplies.sectionName}-${id}`;
    });
    supplies_tabs.push({isOpen: _expanded,
      id: `${supplies.sectionName}-${id}`});
    window.localStorage.setItem('suppliesTabs', JSON.stringify(supplies_tabs));
    open_set(_expanded);
  };
  const head = supplies.sectionName;
  const Ingredients = useMemo(()=><React.Fragment>
    <div>
      <h4 style={titleStyle}>INGREDIENTS</h4>
      {supplies.ingredients.map((ingredient, index)=>(
        <ul key={`${ingredient.id}-${index}`}>
          <li key={`li${ingredient.id}-${index}`}>
            <p style={detailStyle}>
              {(decimal2fraction(ingredient.quantity) > 0 || (typeof decimal2fraction(ingredient.quantity) === 'string' && decimal2fraction(ingredient.quantity) !== '0')) &&
                decimal2fraction(ingredient.quantity)} {ingredient.unit} {ingredient.name}
              <br />
              <span style={{fontStyle: 'italic'}}>
                {ingredient.details}
              </span>
            </p>
          </li>
        </ul>
      ))}
    </div>
    {supplies.gear.length > 0 && <div style={separator} />}
  </React.Fragment>, [supplies]);
  const Gear = useMemo(()=><div>
    <h4 style={titleStyle}>GEAR</h4>
    {supplies.gear.map((gear, index)=><ul key={`ulgear-${index}`}>
      <li key={`ligear-${index}`}>
        <span style={detailStyle} className={gear.unit}>
          {(decimal2fraction(gear.quantity) > 0 || (typeof decimal2fraction(gear.quantity) === 'string' && decimal2fraction(gear.quantity) !== '0')) &&
            decimal2fraction(gear.quantity)} {gear.unit} {gear.name}
          <br />
          <span style={{fontStyle: 'italic'}}>
            {gear.details}
          </span>
        </span>
      </li>
    </ul>)}
  </div>, [supplies]);
  return (
    <Accordion square={false} expanded={open} onChange={panel_open}>
      <AccordionSummary expandIcon={<Expand_more_icon />}>
        {head.toUpperCase()}
      </AccordionSummary>
      <AccordionDetails
      >
        {!!supplies.ingredients.length && Ingredients}
        {!!supplies.gear.length && Gear}
      </AccordionDetails>
    </Accordion>
  );
};

export default E;
