// LICENSE_CODE MIT
import React, {useEffect, createRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Announce_kit from 'announcekit-react';
import {useDispatch} from 'react-redux';
import {gift_popup_set} from './actions/mainMenu';
import util from './util.js';
import auth from './auth.js';
import config_ext from './config_ext.js';
import xurl from '../../../util/xurl.js';
import {Button, Layout, Row, Col, Space, Grid} from 'antd';
import Icon from '@ant-design/icons';
import {ReactComponent as Profile_icon_svg} from './profile_icon.svg';
import {ReactComponent as Bell_icon_svg} from './bell_icon.svg';

let styles_announce_kit = {position: 'absolute', top: '-5px', right: '5px',
  width: '16px'};

let Chevron_left = ()=><svg viewBox="0 0 24 24" width="24" height="24"
  fill="currentColor">
  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
</svg>;

let lesson_path_regex = /\/class\/c\d+?\/lesson\/\d+?/u;
const Header = ({refs_forward, go_back = true, transparent = false,
  handle_back})=>{
  let {user_full, user: user_profile} = auth.use_auth();
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let announce_kit_user = user_profile
    ? {id: user_profile.uid, email: user_profile.email,
      name: user_profile.displayName}
    : {};
  let scroll_element_refs = {header: createRef()};
  let click_handle = ()=>{
    history.push(xurl.url('/home', qs_o));
    window.location.reload();
  }
  useEffect(()=>{
    if (refs_forward)
      refs_forward(scroll_element_refs);
    util.gtm_push({event: 'customPageview'});
  }, []);
  const gift_popup_open = ()=>dispatch(gift_popup_set(true));
  const go_back_handle = ()=>{
    if (lesson_path_regex.test(location.pathname)
    && util.should_open_gift_dialog(user_full, qs_o))
    {
      return gift_popup_open();
    }
    if (handle_back){
      handle_back();
    }
    if (typeof go_back == 'string'){
      history.push(xurl.url(go_back, qs_o));
      window.location.reload();
    }
    else{
      history.goBack();
    }

  };
  const avatar_click_handle = ()=>{
    history.push(xurl.url('/profile', qs_o));
  };
  const screens = Grid.useBreakpoint();
  return (
    <Layout.Header style={{zIndex: 99,
      position: 'sticky',
      top: 0, width: '100%', height: 50, paddingInline: 0, lineHeight: 0,

      backgroundColor: 'transparent'}}>
      <Row style={{height: 50,
        backgroundColor: transparent ? 'transparent' : 'rgba(23, 23, 23)'}}
      align="middle" justify="space-between">
        <Col style={{textAlign: 'left', 'bottom': '2px'}} span={8}>
          {go_back && <Button
            onClick={go_back_handle}
            type="link"
            icon={<Icon component={Chevron_left} />}
          />}
        </Col>
        <Col style={{textAlign: 'center'}} span={8}>
          <Button type="link" onClick={click_handle}
            style={{fontSize: 18, verticalAlign: 'super', 'bottom': '4px'}}>
            <span style={{fontWeight: 300}}>YES</span>
            <span style={{fontWeight: 600}}>CHEF</span>
          </Button>
        </Col>
        <Col style={{textAlign: 'right'}} span={8}>
          {screens.xs != undefined && !screens.xs ? <Space size="small">
            {config_ext.front.enable_new_tab && <Announce_kit
              widgetStyle={styles_announce_kit}
              user={announce_kit_user}
              widget={config_ext.announcekit.url}
            >
              <Button
                type="link"
                size="large"
                icon={
                  <Icon component={Bell_icon_svg} />
                }
              />
            </Announce_kit>}
            <Button
              type="link"
              size="large"
              onClick={avatar_click_handle}
              icon={<Icon component={Profile_icon_svg} />}
            />
          </Space> : <div/>}
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
