// LICENSE_CODE YC
import React, {useCallback, useMemo, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
      marginBottom: '2.3rem',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginBottom: '2.3rem',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#262626',
    marginBottom: -1,
    minHeight: 56,
    borderRadius: 6,
    fontWeight: 600,
    fontSize: 16,
    '&$expanded': {
      minHeight: 56,
      borderRadius: 0,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 24,
    backgroundColor: '#1E1E1E',
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
}))(MuiAccordionDetails);

const titleStyle = {
  fontSize: 16,
  color: '#ffffffe6',
  fontStyle: 'italic',
  fontWeight: 400,
};

const detailStyle = {
  fontSize: 16,
  color: '#ffffffe6',
};

const separator = {
  width: '100%',
  height: '1px',
  borderTop: '1px solid #ffffffaa',
  margin: '24px 0',
};

const shortStyle = {
  ':before': {
    color: 'white',
  },
};

const E = ({
  section: {sectionName, title, items},
  isExpanded,
})=>{
  let id = useMemo(()=>sectionName.replace(/\s/ug, '_'), [sectionName]);
  const initial_stage_get = useCallback(()=>{
    if (window.localStorage.getItem('shortTabs'))
    {
      let tabs = JSON.parse(window.localStorage.getItem('shortTabs'));
      let find = tabs.find(item=>item.id === `${sectionName}-${id}`);
      if (find)
        return find.isOpen;
      return isExpanded;
    }
    return isExpanded;
  }, [sectionName, id, isExpanded]);
  let [open, open_set] = useState(initial_stage_get);
  const panel_open = (e, expanded)=>{
    let tabs = [];
    if (window.localStorage.getItem('shortTabs'))
      tabs = JSON.parse(window.localStorage.getItem('shortTabs'));
    tabs = tabs.filter(item=>item.id !== `${sectionName}-${id}`);
    tabs.push({isOpen: expanded, id: `${sectionName}-${id}`});
    window.localStorage.setItem('shortTabs', JSON.stringify(tabs));
    open_set(expanded);
  };
  return (
    <Accordion
      expanded={open}
      onChange={panel_open}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}-recipe-header`}
        id={`panel${id}-recipe-header`}
      >
        {sectionName.toUpperCase()}
      </AccordionSummary>
      <AccordionDetails
      >
        {title && <React.Fragment>
          <div style={titleStyle}>
            {title}
          </div>
          <div style={separator} />
        </React.Fragment>}
        <div>
          <ul className="numbering">
            {items.map(({step}, index)=>(
              <li key={`lishort-${index}`} style={shortStyle}>
                <span style={detailStyle}>
                  {step}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default E;
