// LICENSE_CODE MIT
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import os from 'os';
import assert from 'assert';
import eserf from '../../../util/eserf.js';
import date from '../../../util/date.js';
import config_ext from './config_ext.js';

let E = {};
export default E;
let app_name = 'front_app_'+config_ext.stage;
let inited;
let is_localhost = os.hostname() == 'localhost';
let is_no_metric = process.env.IS_TEST || config_ext.is_local
  || process.env.IS_LOCAL || is_localhost;
E.init = ()=>{
  if (inited)
    assert(0, 'sentry already inited');
  inited = true;
  if (is_no_metric)
    return;
  let is_perf = config_ext.sentry.front.is_perf;
  let ver = config_ext.ver;
  console.log('sentry init', app_name, ver);
  Sentry.init({
    dsn: config_ext.sentry.front.dsn,
    integrations: [new BrowserTracing()],
    release: ver,
    serverName: os.hostname(),
    environment: config_ext.stage,
    tracesSampleRate: is_perf ? 1.0 : undefined,
  });
  eserf.on_exit((msg, stack)=>{
    E.error(msg, stack);
    Sentry.flush(2000).then(function(){
      process.exit(1);
    });
  });
  let ts = new Date();
  let err_cache = {};
  if ('localStorage' in window)
    err_cache = JSON.parse(localStorage.getItem('err_cache')) || {};
  Object.entries(err_cache).forEach(([message, error_ts])=>{
    if (ts.getTime() - new Date(error_ts).getTime() > date.MS_DAY)
      delete err_cache[message];
  });
  if ('localStorage' in window)
    localStorage.setItem('err_cache', JSON.stringify(err_cache));
};

E.error_once = E.error = (id, ...args)=>{
  let message = id+'\n'+args.join('\n');
  let ts = new Date();
  // XXX colin: use xurl.json_parse
  let err_cache = JSON.parse(localStorage.getItem('err_cache')) || {};
  let prev_ts = new Date(err_cache[id]);
  let time_delta = ts.getTime() - prev_ts.getTime();
  if (err_cache[id] && time_delta <= date.MS_DAY)
    return;
  if (is_no_metric)
    return;
  Sentry.withScope(function(scope){
    Sentry.captureException(new Error(message));
  });
  err_cache[id] = ts.toISOString();
  if ('localStorage' in window)
    localStorage.setItem('err_cache', JSON.stringify(err_cache));
};

E.set_id = (user_id, org_id)=>{
  let clarity = window.clarity||(()=>{});
  let is_add_tag;
  clarity('identify', user_id);
  if (is_add_tag)
  {
    clarity('set', 'user_id', user_id);
    clarity('set', 'org_id', org_id);
  }
  Sentry.configureScope(scope=>scope.setUser({user_id}));
};

