// LICENSE_CODE YC
import React, {useEffect, useState, useRef} from 'react';
import auth from './auth.js';
import Header from './header.js';
import { createWidget } from '@typeform/embed';
import {useHistory} from 'react-router-dom';


const E = props=>{
  let history = useHistory();
  let {user_full, user: user_profile} = auth.use_auth();


  function categorizeSKU(sku) {
    let category;
  
    if (/monthly|month/.test(sku.toLowerCase())) {
      category = "Monthly";
    } else if (/annual|yearly/.test(sku.toLowerCase())) {
      category = "Annual";
    } else if (/lifetime/.test(sku.toLowerCase())) {
      category = "Lifetime";
    } else {
      category = "Unknown";
    }
  
    return category;
  }

useEffect(() => {
    let sku = user_full.plan.prod_sku;
    let category = categorizeSKU(sku);
    let tf_url = 'https://yeschef.typeform.com/to/pfRJYm3W';
    if(category == "Monthly"){
      tf_url = 'https://yeschef.typeform.com/to/N7gUbP6r';
    }
    else if(category == "Annual"){
      tf_url = 'https://yeschef.typeform.com/to/pfRJYm3W';
    }
   

    // Initialize Typeform widget
    const widget = createWidget(tf_url, {
        container: document.getElementById('typeform-container'),
        inlineOnMobile: true,
        hidden: {
          email: user_profile.email
        },
        onSubmit:({formId,responseId}) => {
            console.log(`Form ${formId} submitted with response id: ${responseId}`);
        },
    });

}, [user_profile.email]);

return (
  <div className="yc-main">
    <Header />
    <div id="typeform-container" className="formFrame" title="YesChef Personalization" />
  </div>
);
};

export default auth.with_auth(E);
