// LICENSE_CODE MIT
let E = {};
export default E;

E.path = function(path){
  if (Array.isArray(path))
    return path;
  path = ''+path;
  if (!path)
    return [];
  return path.split('.');
};

E.get = function(o, path, def){
  path = E.path(path);
  for (let i=0; i<path.length; i++)
  {
    if (!o || typeof o!='object'&&typeof o!='function' || !(path[i] in o))
      return def;
    o = o[path[i]];
  }
  return o;
};

E.set = function(o, path, value){
  let orig = o;
  path = E.path(path);
  for (let i=0; i<path.length-1; i++)
  {
    let p = path[i];
    o = o[p] || (o[p] = {});
  }
  o[path[path.length-1]] = value;
  return orig;
};

E.unset = function(o, path){
  path = E.path(path);
  for (let i=0; i<path.length-1; i++)
  {
    let p = path[i];
    if (!o[p])
      return;
    o = o[p];
  }
  delete o[path[path.length-1]];
};

E.semver = {};
E.semver.cmp = (v1, v2)=>{
  // 0 v1==v2
  // 1 v1>v2
  // -1 v1<v2
  return v1.localeCompare(v2, undefined, {numeric: true, sensitivity: 'base'});
};

E.is_empty = o=>!Object.keys(o||{}).length;
