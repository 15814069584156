// LICENSE_CODE YC
import auth from './auth.js';

let E = {};
E.should_open_gift_dialog = (user_full, qs_o)=>{
  if (!qs_o.dbg_feature_gift)
    return;
  let stop = user_full?.events?.gift_popup_x?.stop;
  if (stop)
    return false;
  let ts = user_full?.events?.gift_popup_x?.ts;
  if (!ts)
    return true;
  ts = new Date(ts);
  let day_ago = new Date();
  day_ago.setDate(day_ago.getDate() - 1);
  if (ts >= day_ago)
    return false;
  return true;
};

E.gtm_push = data=>{
  if (window.dataLayer)
  {
    let user = auth.fbase_auth.currentUser;
    if (user)
      data.userId = user.email;
    window.dataLayer.push(data);
  }
};

export default E;
