// LICENSE_CODE YC
import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Row, Col, Typography, Checkbox, Layout, Button, Space} from 'antd';
import xurl from '../../../util/xurl.js';

let {Header, Footer, Content} = Layout;
let {Title} = Typography;
let E = ()=>{
  let location = useLocation();
  let qs_o = xurl.qs_parse(location.search, true);
  let history = useHistory();
  return <>
    <Layout>
      <Header />
      <Content>
        <Space direction="vertical">
          <Row>
            <Col>
              <Title level={1}>Debug</Title>
            </Col>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg: e.target.checked}))}
            defaultChecked={!!qs_o.dbg}
            >dbg debug</Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_lesson_2: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_lesson_2}
            >dbg_lesson_2</Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_fbase_signup: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_fbase_signup}
            >dbg_fbase_signup</Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_feature_gift: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_feature_gift}
            >dbg_feature_gift</Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_inactive_users: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_inactive_users}
            >dbg_inactive_users</Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_pay_add: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_pay_add}>
              dbg_pay_add
            </Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_name_screen: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_name_screen}>
              dbg_name_screen show add name screen
            </Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_14d: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_14d}>
              dbg_14d show 14d free trial dialog
            </Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_pay_popup: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_pay_popup}>
              dbg_pay_popup show pay flow popup
            </Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, is_not_social_login: e.target.checked}))}
            defaultChecked={!!qs_o.is_not_social_login}>
              is_not_social_login dont show social logins for mobile webview
            </Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_signin: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_signin}>
              signin using antd
            </Checkbox>
          </Row>
          <Row>
            <Checkbox onChange={e=>history.replace(xurl.url('/dbg',
              {...qs_o, dbg_public: e.target.checked}))}
            defaultChecked={!!qs_o.dbg_public}>
              role public
            </Checkbox>
          </Row>
          <Row>
            <Button onClick={()=>history.replace(xurl.url('/home', qs_o))}>
              Goto home and use the features
            </Button>
          </Row>
          <Row>
            <Button onClick={()=>history.replace(xurl.url('/', qs_o))}>
              Goto signin and use the features
            </Button>
          </Row>
        </Space>
      </Content>
      <Footer>
      </Footer>
    </Layout>
  </>;
};

export default E;

